import React from 'react'
import { useTranslation } from 'react-i18next'
import { StudentOrMentor } from '../../../../apis/api.egg'
import { SessionData } from '../../../../apis/api.participation'
import { useParticipation } from '../../../../hooks/ApiHooks/useParticipation'
import { ActionsSelector } from './Actions/ActionsSelector'
import { MoreOptions } from './Actions/MoreOptions'
import { StudentInfo } from './Actions/StudentInfo'
import styles from './StudentItem.module.css'

export const StudentItem = (props: StudentItemProps) => {
    let userParticipation: SessionData | undefined = undefined
    const { participation } = useParticipation(Number(props.team?.number) || 0)

    if (participation && participation.session_data) {
        userParticipation = participation.session_data.find(
            (part) => part._profileId === props._profileId
        )
    }
    return (
        <div
            className={`${styles.studentItem} ${
                props?.className ? styles[`${props?.className}`] : ''
            }`}
        >
            <StudentInfo {...props} />
            <ActionsSelector {...{ ...props, sessionData: userParticipation }} />
            <MoreOptions {...props} />
        </div>
    )
}

export const TeamListHeader = ({
    teamNumber,
    className = '',
}: {
    teamNumber: number
    className?: string
}) => {
    const { t } = useTranslation('teamManager')
    const { participation } = useParticipation(teamNumber)
    const peopleParticipation = participation?.session_data.length

    return (
        <div className={`${styles.studentItem} ${className}`}>
            <div></div>
            <div className={styles.actionsBlock} style={{ justifyContent: 'flex-end' }}>
                <div className={styles.talkingQuality}>
                    <p className={styles.talkingQualityTitle}>
                        {peopleParticipation ? t('% time in session') : ''}
                    </p>
                    <p className={styles.talkingQualityTitle}>
                        {peopleParticipation ? t('% of time speaking') : ''}
                    </p>
                </div>
                <div className={styles.talkingQualityTitle} style={{ minWidth: '92.5px' }}></div>
            </div>
            <div style={{ minWidth: '40px' }}></div>
        </div>
    )
}

export const inputStyles: React.CSSProperties = {
    background: 'white',
    border: '1px solid #eaeaea',
    borderRadius: 5,
    color: '#073045',
    fontWeight: 'bold',
    height: 42,
    margin: 0,
    outline: 0,
    padding: 15,
}

// Interface
export type StudentItemProps = StudentOrMentor & {
    team?: {
        number?: number
        position?: number
        algorithmType?: string
    }
    sessionData?: SessionData
    tableNumber?: number
    cannotUnsubscribe?: boolean
    showTeamBadge?: boolean
    algorithmType?: 'default' | 'divideByGroup' | 'defaultByGroup' | 'hca4' | 'hca1'
    classId?: string
    className?: string
    onClickHandler?: () => void
    canDelete?: boolean
}
