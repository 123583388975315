import { ParticipationStatus } from '../../../apis/api.participation'
import { colors } from '../../../styles/globals/_variables'

export const getStatusColor = (status: ParticipationStatus) => {
    const statusColorMap: { [key in ParticipationStatus]: keyof typeof colors } = {
        high: 'positiveGreen',
        moderate: 'active',
        low: 'salmon',
        no_data: 'softGrey',
        empty: 'black',
    }

    return colors[statusColorMap[status]]
}
