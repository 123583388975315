import {
    addContent as addContentFn,
    getTeamContent,
    sendContent as sendContentFn,
} from '../apis/api.egg'
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useTeam } from '../pages/Team/Hooks/useTeam'
import { useUIContext } from '../context/UIContext'

export const useContent = () => {
    const { toastNotification } = useUIContext()
    const { courseID } = useTeam()
    const queryClient = useQueryClient()

    const queryKey = ['Team', 'Content', courseID]

    const { data: content = [], ...rest } = useQuery({
        queryKey,
        queryFn: () => getTeamContent(courseID!),
        enabled: !!courseID,
        staleTime: 1000 * 60 * 60 * 1, // 1 hour
    })

    const { mutateAsync: addContent, ...restAdd } = useMutation({
        mutationFn: addContentFn,
        onSuccess: (files) => {
            queryClient.setQueryData(queryKey, files)
            toastNotification('Archivo subido correctamente', 'success')
        },
        onError: () => toastNotification('Error al subir archivo', 'error'),
    })

    const { mutateAsync: sendContent, ...restSend } = useMutation({
        mutationFn: sendContentFn,
        onSuccess: () => toastNotification('Contenido enviado correctamente', 'success'),
        onError: () => toastNotification('Error al enviar contenido', 'error'),
    })

    const isLoading = rest.isLoading || restAdd.isLoading || restSend.isLoading

    return {
        content,
        addContent,
        sendContent,
        ...rest,
        isLoading,
    }
}
