import Axios from 'axios'
import Config from '../config'
const { API_PARTICIPATION } = Config

export const ApiParticipation = Axios.create({
    baseURL: API_PARTICIPATION,
})

export const getCourseLiveMonitorData = () =>
    ApiParticipation.get<MonitoringData>('participation/monitoring/get_course_information').then(
        (data) => data.data
    )

export const getMonitoringArrivalUsers = () =>
    ApiParticipation.get<(number | string)[][]>('participation/monitoring/get_users_arrival').then(
        (data) => data.data
    )

export const getParticipation = (teamNumber: number) =>
    ApiParticipation.get<ParticipationDTO>(
        `participation/monitoring/team/${teamNumber}/stats`
    ).then((data) => data.data)

export interface MonitoringData {
    lastRotation: string
    monitor: {
        attendance: {
            actives: number
            present: number
            presentPercentage: number
            absent: number
            absentPercentage: number
            attendeesNotInSession: AttendeesNotInSession[]
        }
        pulses: {
            pulsesTotal: number
            pulsesAvailable: number
            qaPulses: {
                mild: number
                moderate: number
                severe: number
            }
            qaConnections: {
                mild: number
                moderate: number
                severe: number
            }
        }
        teams: {
            totalStudents: number
            totalTeams: number
            averagePeoplePerTeam: number
            teamByPeople: {
                mild: string[]
                moderate: string[]
                severe: string[]
            }
        }
        helpRequests: {
            total: number
            resolved: number
            inProgress: number
            pending: number
            expired: number
        }
        talkingPeople: {
            total: number
            qualityTeams: {
                mild: string[]
                moderate: string[]
                severe: string[]
            }
        }
    }
}

export type AttendeesNotInSession = {
    displayName: string
    email: string
    team: number
}

export interface ParticipationDTO {
    team: number
    team_status: ParticipationStatus
    session_data: SessionData[]
}

export interface SessionData {
    _profileId: string
    time_in_meeting: string
    time_in_meeting_status: ParticipationStatus
    audio_percentage: string
    audio_percentage_status: ParticipationStatus
    sharing: string
}

export type ParticipationStatus = 'high' | 'moderate' | 'low' | 'no_data' | 'empty'
