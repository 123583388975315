import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateContentData } from '../../../../../../apis/api.content'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import { capitalize } from '../../../../../../helpers/formatter'
import { useProfileDayOfContent } from '../../../../../../hooks/ApiHooks/useProfileDayOfContent'
import { useTeam } from '../../../../Context/TeamContext'
import { ModalProps } from '../MoreOptions'
import styles from './ContentModal.module.css'

export const ContentModal = ({ props, isOpen, closeModal }: ModalProps) => {
    const { t } = useTranslation('teamManager')
    const { courseID } = useTeam()
    const { _profileId, displayName } = props
    const { content, contentStatus, mutateContent } = useProfileDayOfContent(_profileId)
    const { contentNumber, description } = content ?? {}
    const inputRef = useRef<HTMLInputElement>(null)
    const onBlurContent = ({
        target: { value, valueAsNumber, defaultValue },
    }: ChangeEvent<HTMLInputElement>) => {
        const data: UpdateContentData = {
            courseId: `${courseID}`,
            target: 'profiles',
            profileIds: [_profileId],
            content: {
                newContentNumber: valueAsNumber,
            },
        }
        value !== defaultValue &&
            valueAsNumber > 0 &&
            mutateContent(data, {
                onError: () => {
                    if (inputRef.current) {
                        inputRef.current.value = contentNumber?.toString() ?? ''
                    }
                },
            })
    }

    return (
        <ConfirmModal
            onConfirm={closeModal}
            open={isOpen}
            type={'warning'}
            title={t('actions.content.title-modal', { displayName })}
            confirmButtonText={t('user-options.confirm-btn-modal')}
            contentText={
                contentStatus === 'loading' ? (
                    <div className={styles.spinner}>
                        <FontAwesomeIcon icon="spinner" spin />
                    </div>
                ) : (
                    <>
                        {content ? (
                            <label className={styles.label}>
                                {t('actions.content.label-modal')}

                                <div className={styles.inputs}>
                                    <input
                                        type="number"
                                        name="contentDay"
                                        id="contentDay"
                                        min={1}
                                        ref={inputRef}
                                        defaultValue={contentNumber}
                                        onBlur={onBlurContent}
                                        className={`${styles.input} ${styles.inputDay}`}
                                    />
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={capitalize(`${description}`)}
                                        disabled={true}
                                        className={styles.input}
                                    />
                                </div>
                            </label>
                        ) : (
                            <p>{t('actions.content.no-content-modal')}</p>
                        )}
                    </>
                )
            }
        />
    )
}
