import { MeetButton } from './MeetButton'
import { useTeam } from '../Context/TeamContext'
import { TeamAccordionProps } from './TeamsList'
import styles from './TeamMeet.module.css'

export const TeamMeet = (props: TeamAccordionProps) => {
    const { algorithmID } = useTeam()

    if (!algorithmID) return null

    return (
        <div className={styles.teamHeader}>
            <MeetButton props={props} />
        </div>
    )
}

export default TeamMeet
