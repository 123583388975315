import { useTranslation } from 'react-i18next'
import { getStatusColor } from '../../../Helpers/getStatusColor'
import { StudentItemProps } from '../StudentItem'
import styles from '../StudentItem.module.css'

export const TalkQualityUser = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const session_data = props.sessionData

    const { time_in_meeting, audio_percentage, time_in_meeting_status, audio_percentage_status } =
        session_data || {}

    const values = [
        { value: time_in_meeting, status: time_in_meeting_status },
        { value: audio_percentage, status: audio_percentage_status },
    ].map(({ value, status }) => ({
        value,
        empty: status === 'empty',
        noData: status === 'no_data',
        color: status && getStatusColor(status),
    }))

    return (
        <div className={styles.talkingQuality}>
            {values.map(({ value, empty, noData, color }, index) => (
                <p key={index} style={{ color }}>
                    {value && (empty ? '' : noData ? t('Still no data') : `${value} %`)}
                </p>
            ))}
        </div>
    )
}
