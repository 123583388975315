import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTeam } from '../../Context/TeamContext'
import Styles from '../Overview/OverviewHeader.module.css'
import { useRotate } from '../../../../hooks/useRotate'
import { getCourseMonitorData, rotateTeam } from '../../../../apis/api.egg'
import { useUIContext } from '../../../../context/UIContext'
import { useQuery } from 'react-query'
import { Styles as ModalStyles } from 'react-modal'

const customStyles: ModalStyles = {
    content: {
        position: 'absolute',
        inset: '50%',
        background: 'rgb(255, 255, 255)',
        overflow: 'hidden',
        borderRadius: '8px',
        outline: 'none',
        padding: 40,
        width: 'min-content',
        height: 'min-content',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #eaeaea',
    },

    overlay: { backgroundColor: 'rgba(255, 255, 255, 0.75)', zIndex: 1 },
}

export const Rotate = () => {
    const { courseID } = useTeam()
    const { toastError, setModal } = useUIContext()
    const { t } = useTranslation('teamSettings')

    const { data: stats } = useQuery(
        [courseID, 'Team', 'Monitor', 'Get Stats'],
        () => getCourseMonitorData(`${courseID}`),
        {
            enabled: !!courseID,
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000,
            onError: toastError,
        }
    )

    return (
        <>
            <button
                className={`btn-primary-color ${Styles.headerBtn}`}
                disabled={!stats?.ableLaunchAlgorithm}
                onClick={() =>
                    setModal({
                        content: <RotateConfirmModal courseID={`${courseID}`} />,
                        customStyles,
                    })
                }
            >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={['fas', 'arrows-rotate']} />
                <span>{t(`RotateRestore.confirm.titleRotate`)}</span>
            </button>
        </>
    )
}

export const RotateConfirmModal = (params: Parameters<typeof rotateTeam>[0]) => {
    const { closeModal } = useUIContext()
    const { t } = useTranslation('teamSettings')
    const { rotate } = useRotate()

    const btnStyles: React.CSSProperties = {
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 700,
        border: '1px solid #eaeaea',
        height: 36,
        width: 100,
    }
    return (
        <div style={{ border: '0 solid #eaeaea' }}>
            <div className="modal-title">
                <h3>{t(`RotateRestore.confirm.titleRotate`)}</h3>
            </div>
            <hr />
            <div style={{ width: 570, marginBottom: 75 }}>
                <p>{t(`RotateRestore.confirm.contentTextRotate`)}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20 }}>
                <button
                    style={{ ...btnStyles, background: 'white', color: '#073045' }}
                    onClick={closeModal}
                >
                    {t('RotateRestore.confirm.cancelButtonText')}
                </button>
                <button
                    style={{ ...btnStyles, background: '#296efa', color: 'white' }}
                    onClick={() => {
                        closeModal()
                        rotate(params)
                    }}
                >
                    {t('RotateRestore.confirm.confirmButtonText')}
                </button>
            </div>
        </div>
    )
}
