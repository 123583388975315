import React, { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUIContext } from '../context/UIContext'
import Styles from './InfoModal.module.css'

const InfoModal = ({
    children,
    icon = 'exclamation-triangle',
    subtitle,
    title,
}: IInfoModalProps) => {
    const { setModal } = useUIContext()
    return (
        <div className={Styles.details}>
            <header>
                <FontAwesomeIcon icon={icon} className={Styles.videoIcon} />
                <div className={Styles.title}>
                    <h2>{title}</h2>
                    <h4>{subtitle}</h4>
                </div>
                <button className={Styles.btnClose} onClick={() => setModal({ content: null })}>
                    <FontAwesomeIcon icon={'times'} />
                </button>
            </header>
            <main className={Styles.list}>{children}</main>
        </div>
    )
}

export interface IInfoModalProps {
    children?: ReactNode
    icon?: IconProp
    subtitle?: string
    title?: string
}

export default InfoModal
