import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useUIContext } from '../../../../context/UIContext'
import DayJS from '../../../../helpers/DayJS'
import { useReports } from '../../Context/ReportsContext'

export const FilterHeader = () => {
    const { t } = useTranslation('teamReports')
    const { toastNotification } = useUIContext()

    const { reportQuery, setReportQueryFn, activePeriod, setActivePeriod } = useReports()
    const { cohorts, from, to } = reportQuery
    const [minValidDate, setMinValidDate] = useState<string>()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [selectDates, setSelectDates] = useState<SelectDates>({ from: from!, to: to! })

    const validateDates = () => {
        const fromDate = DayJS(selectDates.from)
        const toDate = DayJS(selectDates.to)

        const isValidDateRange = fromDate.isSameOrBefore(toDate) && toDate.isSameOrAfter(fromDate)
        if (isValidDateRange) {
            setReportQueryFn({ ...selectDates })
        } else {
            toastNotification(t('invalid date range'), 'error', 2)
        }
    }

    const setSelectDatesFn = (data: Partial<SelectDates>) => {
        setSelectDates({ ...selectDates, ...data })
    }

    const handleBookmarkDateRanges = async (from: string, to: string) => {
        const dates = {
            from,
            to,
        }
        setSelectDatesFn(dates)
        setReportQueryFn(dates)
    }

    const handleOnClick = () => {
        validateDates()
        setActivePeriod(-1)
    }

    useEffect(() => {
        const minValidDate = cohorts && DayJS(cohorts[cohorts.length - 1]).format('YYYY-MM-DD')
        setMinValidDate(minValidDate)
    }, [cohorts])

    return (
        <FilterHeaderStyles className="filter-dates">
            <BookmarkDateRanges
                handleBookmarkDateRanges={handleBookmarkDateRanges}
                period={activePeriod}
                setActivePeriod={setActivePeriod}
            />
            <span className="titles">{t('Or')}</span>
            <div className="range">
                <label htmlFor="from" className="titles">
                    <span className="upper-title">{t('from')}</span>
                    <input
                        type="date"
                        name="from"
                        id="from"
                        min={minValidDate}
                        value={selectDates.from}
                        className="rangeDateSelector"
                        onChange={({ target }) => setSelectDatesFn({ from: target.value })}
                    />
                </label>
                <label htmlFor="to" className="titles">
                    <span className="upper-title">{t('to')}</span>
                    <input
                        type="date"
                        name="to"
                        id="to"
                        min={minValidDate}
                        className="rangeDateSelector"
                        value={selectDates.to}
                        onChange={({ target }) => setSelectDatesFn({ to: target.value })}
                    />
                </label>
            </div>
            <button className="applyBtn" onClick={handleOnClick}>
                {t('apply')}
            </button>
        </FilterHeaderStyles>
    )
}

const BookmarkDateRanges = ({
    handleBookmarkDateRanges,
    period,
    setActivePeriod,
}: {
    handleBookmarkDateRanges: (from: string, to: string) => void
    period: number
    setActivePeriod: (period: number) => void
}) => {
    const { t } = useTranslation('teamReports')

    const options = [
        {
            label: `${t('today')}`,
            from: today.format(apiFormat),
            to: today.format(apiFormat),
        },
        {
            label: `${t('yesterday')}`,
            from: today.subtract(1, 'days').format(apiFormat),
            to: today.subtract(1, 'day').format(apiFormat),
        },
        {
            label: `${t('last 7 days')}`,
            from: today.subtract(8, 'days').format(apiFormat),
            to: today.subtract(1, 'day').format(apiFormat),
        },
    ]
    return (
        <div className="titles">
            <span className="upper-title">{t('select period')}</span>
            <div className="selector">
                {options.map(({ label, from, to }, index) => {
                    return (
                        <button
                            className={`buttonDates ${index === period ? 'selected' : ''}`}
                            onClick={() => {
                                handleBookmarkDateRanges(from, to)
                                setActivePeriod(index)
                            }}
                            key={label}
                        >
                            {label}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

type SelectDates = {
    from: string
    to: string
}

const apiFormat = 'YYYY-MM-DD'
const today = DayJS()

export const FilterHeaderStyles = styled.header`
    min-width: 400px;
    min-height: inherit;
    margin: 20px 0;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    background-color: white;
    border-radius: var(--radius);
    .date-select {
    }
    .titles {
        width: 100%;
        display: grid;
        font-weight: bold;
        font-size: 10px;
    }
    .titles > input {
        font-size: 14px;
    }
    .titles > button {
        display: block;
        font-size: 14px;
    }
    .upper-title {
        text-transform: uppercase;
        margin-left: 10px;
    }
    .range {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .rangeDateSelector {
        border: 1px solid #f2f2f2 !important;
        min-width: 130px;
        text-align: center;
        padding: 0px 10px 0px 10px;
        border-radius: 10px;
        min-height: 36px;
        height: 36px !important;
        margin: 8px 5px 5px;
        color: #073045;
        font-size: 14px;
        font-weight: 600;
    }

    .selector {
        display: inline-flex;
    }

    .buttonDates {
        width: 136px;
        border: 1px solid #f2f2f2;
        height: 36px;
        margin: 8px 0;
        font-size: 14px;
        font-weight: 700;
        color: #003750;
        background: #f8f8f8;
    }
    .buttonDates:first-of-type {
        border-radius: 8px 0px 0px 8px;
    }
    .buttonDates:last-of-type {
        border-radius: 0px 8px 8px 0px;
    }
    .buttonDates:only-child {
        border-radius: 8px;
    }
    .selected {
        background: #ffffff;
    }
    .applyBtn {
        border-radius: 8px;
        height: 36px;
        background-color: white;
        border: 2px solid #003750;
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #003750;
        font-weight: 900;
        font-size: 13px;
        min-width: 75px;
    }
    .applyBtn:hover {
        background-color: #eaeaea;
    }
`
