import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../../Other/LoadingPage'
import {
    getAttendanceCsv,
    getAudioParticipationCsv,
    getParticipationCsv,
} from '../../../api-reports'
import { useAttendance } from '../../../Hooks/useAttendance'
import { useCsvReport } from '../../../Hooks/useCsvReport'
import { useParticipation } from '../../../Hooks/useParticipation'
import { PropsReports } from '../../../Reports'
import { ChartComponent } from '../../../../../components/GoogleChart/ChartComponent'
import { ReportContainer } from '../ReportContainer'
import { calculateMaxHeightWithRatio } from '../../../../../helpers/calculateMaxHeightWithRatio'
import { colors } from '../../../../../styles/globals/_variables'

export const AttendancesReports = ({ pieChartOptions }: PropsReports) => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()

    const { attendances: graphAttendances, isLoading: isLoadingAttendances } = useAttendance()
    const { barChart: attendances, totalPieChart: totalAttendances } = graphAttendances ?? {}
    const { participation } = useParticipation()
    const { lineChart, audioLineChart } = participation ?? {}

    const { eggColor, salmon, baseGrey, active, positiveGreen } = colors

    const transformAttendances = () => {
        if (!attendances) return null
        const columnNames = ['instance', 'assistants', 'absents', 'churns', 'totals']

        const header = columnNames.flatMap((name, index) =>
            index === 0
                ? [name]
                : [
                      t(name),
                      {
                          role: 'annotation',
                          type: name === 'totals' ? 'number' : 'string',
                          calc: 'stringify',
                      },
                  ]
        )

        const rows = (attendances as DataEntry[]).slice(1).map((row: DataEntry) => {
            const [instance, actives, absents, churns] = row
            const numberRow = row.slice(1).map((value) => value as number)
            const totals = numberRow.reduce((acc, value) => acc + value, 0)
            const maxHeight = calculateMaxHeightWithRatio(attendances)

            return [
                instance,
                actives,
                actives,
                absents,
                absents,
                churns,
                churns,
                maxHeight - totals, // Alto de la columna de totales
                totals,
            ]
        })
        return [header, ...rows]
    }

    const attendancesData = transformAttendances()

    if (isLoadingAttendances) return <LoadingPage />

    return (
        <AttendanceReportsStyles>
            <ReportContainer
                header={{
                    title: 'Attendance',
                    onClick: () => getFilteredReport(getAttendanceCsv, 'attendances'),
                    disabled: !attendances,
                }}
                content={
                    <>
                        <ChartComponent
                            chartType="ColumnChart"
                            data={attendancesData}
                            height={'500px'}
                            options={{
                                legend: { position: 'top' },
                                isStacked: true,
                                colors: [eggColor, active, salmon, 'transparent'],
                                bar: { groupWidth: '50' },
                                chartArea: {
                                    left: 70,
                                    top: 70,
                                    right: 40,
                                    bottom: 70,
                                },
                                series: {
                                    3: {
                                        visibleInLegend: false,
                                        annotations: {
                                            textStyle: {
                                                fontSize: 16,
                                                bold: true,
                                                color: 'black',
                                            },
                                        },
                                    },
                                },
                                theme: {
                                    annotations: {
                                        textStyle: { fontSize: 14 },
                                    },
                                },
                                hAxis: {
                                    slantedText: true,
                                },
                                vAxis: {
                                    viewWindow: {
                                        max:
                                            attendances && calculateMaxHeightWithRatio(attendances),
                                    },
                                },
                            }}
                        />
                        <ChartComponent
                            chartType="PieChart"
                            data={totalAttendances ?? null}
                            width={pieChartOptions?.width ?? '70%'}
                            options={{
                                title: t('Percentage attendances over actives'),
                                titleTextStyle: {
                                    fontSize: 16,
                                },
                                colors: [eggColor, active, baseGrey],
                                chartArea: {
                                    left: 40,
                                    top: 70,
                                    right: 40,
                                    bottom: 20,
                                },
                                legend: { position: 'labeled' },
                            }}
                        />
                    </>
                }
            />
            <ReportContainer
                header={{
                    title: '% time in session',
                    onClick: () => getFilteredReport(getParticipationCsv, '%timeInSession'),
                    disabled: !lineChart,
                }}
                content={
                    <ChartComponent
                        chartType="AreaChart"
                        data={lineChart ?? null}
                        options={{
                            legend: { position: 'none' },
                            colors: [eggColor],
                            chartArea: {
                                left: 90,
                                top: 70,
                                right: 40,
                                bottom: 70,
                                width: '100%',
                                height: '100%',
                            },
                            vAxis: {
                                viewWindowMode: 'pretty',
                                viewWindow: {
                                    max: 100,
                                    min: 0,
                                },
                            },
                            hAxis: {
                                slantedText: true,
                            },
                        }}
                    />
                }
            />
            <ReportContainer
                header={{
                    title: '% of time speaking',
                    onClick: () => getFilteredReport(getAudioParticipationCsv, '%ofTimeSpeaking'),
                    disabled: !audioLineChart,
                }}
                content={
                    <ChartComponent
                        chartType="AreaChart"
                        data={audioLineChart ?? null}
                        options={{
                            legend: { position: 'none' },
                            colors: [positiveGreen],
                            chartArea: {
                                left: 90,
                                top: 70,
                                right: 40,
                                bottom: 70,
                                width: '100%',
                                height: '100%',
                            },
                            vAxis: {
                                viewWindowMode: 'pretty',
                                viewWindow: {
                                    max: 100,
                                    min: 0,
                                },
                            },
                            hAxis: {
                                slantedText: true,
                            },
                        }}
                    />
                }
            />
        </AttendanceReportsStyles>
    )
}

type DataEntry = [string, number, number, number]

const AttendanceReportsStyles = styled.section`
    & > .chart > .report-display {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
`
