import Axios from 'axios'
import { ComponentProps } from 'react'
import Config from '../config'
import { UsersRoles } from '../interfaces/users'
import { capitalize } from '../helpers/formatter'
const { API, API_ROTATE, TEAMS_BY_PAGE, UNASSIGNED_BY_PAGE } = Config

export const getTeam = (courseID: string, page = 1, limit = TEAMS_BY_PAGE) =>
    Axios.get<GetTeamResponse['data']>(
        `${API}/v2/course/${courseID}/?skip=${page * limit - limit}&limit=${limit}`
    ).then(({ data }) => data)

export const updateUnAssigned = (courseID: string, page = 1, limit = UNASSIGNED_BY_PAGE) =>
    Axios.get<GetUnAssignedResponse['data']>(
        `${API}/v2/course/${courseID}/unassigned?skip=${page * limit - limit}&limit=${limit}`
    ).then(({ data }) => data)

export const updateTeamSubjectName = (
    subjectID: string,
    _professorScheduleId: string,
    name: string
) =>
    Axios.patch(`${API}/subject/${subjectID}`, {
        _professorScheduleId,
        name,
    })

export const deleteTeam = (scheduleId: string, subjectID: string) =>
    Axios.delete(`${API}/professorSchedule/${scheduleId}/subject/${subjectID}`)

export const getHqStudentList = (hqId: string, page?: number) =>
    Axios.get<HQStudentListResponse['data']>(
        `${API}/hq/${hqId}/students?limit=10&skip=${!page ? 0 : page * 10}`
    ).then(({ data }) => data)

/** Get mentors list for a team */
export const updateMentors = (props: GetMentorsProps) =>
    Axios.get<GetMentorsResponse['data']>(
        `${API}/v2/algorithm/${props.algorithmID}/mentors/${props.team}`
    ).then(({ data }) => ({
        ...props,
        teamNumber: +props.team,
        mentors: data.tableMentors,
        integrations: data.integrationMentors,
        _helpRequestId: data._helpRequestId,
        action: data.action,
    }))

/** Set new student attendance */
export const updateAttendance = (body: UpdateAttendanceProps) =>
    Axios.put<UpdateAttendanceResponse>(`${API}/attendance`, body)

/** Set new student team number */
export const updateAssignation = ({
    algorithmID,
    _profileId,
    to,
}: {
    algorithmID: string
    _profileId: string
    to: number | 'unassigned'
}) => {
    const url = `${API}/algorithm/${algorithmID}/${to === 'unassigned' ? 'unassign' : 'assign'}`
    const data = { _profileId, ...(to !== 'unassigned' && { tableNumber: to }) }

    return to === 'unassigned' ? Axios.delete(url, { data }) : Axios.post(url, data)
}

/** Return Mentor to origin team */
export const mentorToOrigin = ({
    algorithmID,
    _profileId,
}: {
    algorithmID: string
    _profileId: string
}) =>
    Axios.post(`${API}/v3/algorithm/${algorithmID}/returnMentor`, { _profileId }).then(
        ({ data }) => data
    )

export const deleteStudent = (
    courseID: string,
    _profileId: string,
    quitReason = 'Perfil Inhabilitado desde el Plataforma'
) =>
    Axios.delete<DeleteStudentResponse>(`${API}/class/${courseID}/student/${_profileId}`, {
        data: { quitReason },
    }).then(({ data }) => data)

export const voteTeam = (value: boolean, table: number, _algorithmId: string) =>
    Axios({
        method: value ? 'POST' : 'DELETE',
        url: `${API}/vote/table`,
        data: { _algorithmId, table },
    })

export const saveExam = ({ teamId, exam }: SaveExamsProps) =>
    Axios.post<SaveExamsResponse>(`${API}/exam/courses/${teamId}`, { ...exam })

export const downloadFile = (url: string, fileName: string) => {
    Axios.get(url, {
        responseType: 'blob',
        baseURL: '/',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    })
}

export const getTeamSettings = (courseID: string) =>
    Axios.get<GetTeamSettingsResponse>(`${API}/v2/course/${courseID}/settings`).then(
        ({ data }) => data
    )

export const getTeamDetails = (courseID: string, teamNumber: number | string) =>
    Axios.get<GetTeamDetails['data']>(`${API}/v2/course/${courseID}/team/${teamNumber}`).then(
        ({ data }) => data.teams[0]
    )

export const getSearchProfileDetails = (courseID: string, profileID: string) =>
    getPeopleSearch(courseID, profileID).then(({ students }) => students[0] ?? null)

export const getPeopleSearch = (courseID: string, query: string): Promise<PeopleSearchResponse> =>
    Axios.get<SearchResponse['data']>(
        `${API}/v3/course/${courseID}/students?skip=0&limit=10&search=${query}`
    ).then(({ data }) => {
        return {
            students: data.results?.map((student) =>
                student.tableNumber
                    ? { ...student, team: { number: student.tableNumber } }
                    : student
            ),
        }
    })

export const updateTeamSettings = (courseID: string, body: Partial<TeamSettingsBody>) =>
    Axios.patch<UpdateTeamSettingsResponse>(`${API}/class/${courseID}`, body).then(
        ({ data }) => data
    )

export const getProfileDetails = (_profileId: string) =>
    Axios.get<GetProfileDetails>(`${API}/v3/profile/${_profileId}`).then(({ data }) => ({
        ...data.data,
        displayName: `${capitalize(`${data.data._userId.lastname}`)} ${capitalize(
            `${data.data?._userId.name}`
        )}`,
    }))

export const updateProfileData = (_profileId: string, data: Partial<UpdateProfileData>) =>
    Axios.put<GetProfileDetails>(`${API}/v3/profile/${_profileId}`, data).then(({ data }) => ({
        ...data.data,
        displayName: `${capitalize(`${data.data._userId.lastname}`)} ${capitalize(
            `${data.data?._userId.name}`
        )}`,
    }))

export const trackProfile = ({ _id, tracking }: { _id: string; tracking: ProfileTrackingDTO }) =>
    Axios.put<ProfileTrackingDTO[]>(`${API}/v3/profile/${_id}/track`, tracking).then(
        ({ data }) => data
    )

export const getEnrollTokens = (courseId: string): Promise<Links> =>
    Axios.get<GetEnrollTokenResponse['data']>(`${API}/class/${courseId}/enrollToken/`).then(
        ({ data: { short } }) => ({
            student: `${window.location.origin}/enroll/student/${short.token}`,
            collaborator: `${window.location.origin}/enroll/professor/${short.tutorToken}`,
        })
    )

export const rotateTeam = ({
    courseID,
    launchType = 'shuffle',
    absents = [],
}: {
    courseID: string
    launchType?: 'repeated' | 'shuffle'
    absents?: string[]
}) =>
    Axios.post<AlgorithmResultType>(`${API_ROTATE ?? API}/algorithm/start`, {
        _classId: courseID,
        absents,
        launchType,
    })

export const getCourseMonitorData = (courseID: string) =>
    Axios.get<CourseMonitorDTO>(`${API}/v3/course/${courseID}/lastStats`).then(({ data }) => data)

export const changeHelpRequestStatus = (
    helpRequestId: string,
    status: 'cancel' | 'ended' = 'ended'
) => Axios.patch(`${API}/v3/helpRequest/${helpRequestId}/close`, { status })

export const getZoomConnectionStatus = (courseID: string) =>
    Axios.get<ZoomConnectionsStatus['data']>(
        `${API}/v3/course/${courseID}/zoomConnectionStatus`
    ).then(({ data }) => data)

export const getZoomConnectionDetail = (courseID: string) =>
    Axios.get<ZoomConnectionDetail['data']>(
        `${API}/v3/course/${courseID}/teamsZoomConnectionStatus`
    ).then(({ data }) => data.teams)

export const getHelpRequestPendings = (courseID: string, utcOffset: string) =>
    Axios.get<HelpRequestPending['data']>(
        `${API}/v3/course/${courseID}/helpRequestPending?timezone=${utcOffset}`
    ).then(({ data }) => data)

export const getDistributionByContent = (courseID: string) =>
    Axios.get<GetDistributionByContent['data']>(`${API}/v3/course/${courseID}/distribution`).then(
        ({ data }) => data
    )
export const getTimeline = (courseId?: string) =>
    Axios.get<GetTimelineResponse>(`${API}/class/${courseId}/timeline`).then(({ data }) => data)

export const updateTimelineEvent = ({
    courseID,
    date,
    event,
}: {
    courseID: string
    date: string
    event: Partial<TimelineEventDTO>
}) => Axios.patch(`${API}/class/${courseID}/timeline?date=${date}`, event)

export const newTimelineEvent = ({ courseID, ...rest }: PostLastRoomData) =>
    Axios.post(`${API}/class/${courseID}/timeline`, rest)

export const addContent = async ({
    courseID,
    file,
    fileName,
}: {
    courseID: string
    file: Blob
    fileName: string
}) => {
    const data = new FormData()
    data.append('file', file, fileName)

    const { data: data_1 } = await Axios.post<GetTeamContentResponse>(
        `${API}/class/${courseID}/content`,
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
    return data_1.files
}

export const sendContent = (data: SendExternalContentProps) =>
    Axios.post(`${API}/class/send-content`, data)

export const getTeamContent = (courseID: string) =>
    Axios.get<GetTeamContentResponse>(`${API}/class/${courseID}/content`).then(
        ({ data }) => data.files
    )

// Interfaces
export interface GetTeamResponse {
    data: {
        _id: string
        teamName: string
        course: {
            _id: string
        }
        professorSchedule: {
            _id: string
        }
        algorithm: {
            _id: string
            algorithmType: TeamAlgorithmType
            isExpired: boolean
        } | null
        hq: {
            _id: string
            name: string
        }
        stats: TeamStats
        subject: {
            _id: string
            name: string
        }
        settings: TeamSettings
        teams: Team[]
        coaches: {
            displayName: string
            _profileId: string
            urlImage?: string
        }[]
        pagination: {
            totalTeams: number
        }
    }
}

export type TeamAlgorithmType = 'default' | 'divideByGroup' | 'defaultByGroup' | 'hca4' | 'hca1'

export type TeamStats = {
    allTeamNumbers: number[]
    students: {
        total: number
        assigned: number
        unassigned: number
    }
}

export type TeamSettings = {
    isProgressive: boolean
    hasContent: boolean
    meeting?: SettingsMeeting
    enabledManualAssign: boolean
    enabledHelpRequest: boolean | null
    enableRequestFeedback: boolean | null
    ableLaunchAlgorithm: {
        able: boolean
        hasScheduleConfigured: boolean
        hasMeetingConfigured: boolean
    }
}

type SettingsMeeting =
    | {
          type: ZoomHybrid['meetingType']
          url: {
              label: ZoomHybrid['data'][0]['label']
              zoomURL: ZoomHybrid['data'][0]['zoomUrl']
          }[]
          data: {
              label: string
              zoomUrl: string
              from: number
              to: number
          }[]
      }
    | {
          type: DefaultsMeets['meetingType']
          url?: string
      }
    | {
          type: 'egg'
          data: {
              general: boolean
              neighborhoods?: {
                  label: string
                  contents: number[]
                  maxSize: number
              }[]
          }
      }

export type Team = {
    number: number
    isExpertMentorTeam: boolean
    isMentorTeam: boolean
    isVoted: boolean
    meeting?: string
    guide?: number
    module?: number
    description?: string
}

export interface ZoomHybrid {
    meetingType: 'zoom-hybrid'
    method: 'automatic'
    data: {
        label: 'mentors' | string
        zoomUrl: string
        from: number
        to: number
    }[]
}

export interface DefaultsMeets {
    meetingType: 'zoom' | 'zoom-by-table' | 'zoom-sdk' | 'zoom-sdk-auto' | 'meet' | 'jitsi'
    method: 'automatic' | 'manual'
    data?: {
        generalMeet: string
        meetings?: string[]
    }
}

export type Meetings = DefaultsMeets | ZoomHybrid

export interface GetUnAssignedResponse {
    data: {
        results: StudentDTO[]
        pagination: {
            total: number
        }
    }
    message: string
}

export type StudentOrMentor = StudentDTO | MentorDTO

export interface StudentDTO extends UserData {
    isMentor: false
}

interface UserData {
    _profileId: string
    displayName: string
    hasVoted: boolean
    isAssigned: boolean
    isFacilitator: boolean
    isExpertMentor: boolean
    isInOrigin: boolean
    mentorType?: string
    originTeam: number
    urlImage?: string
    stats: Stats
    attendance?: {
        status?: AttendanceStatus
        comment?: string
    }
}

interface Stats {
    votes: Votes
    timesFacilitator: number
    timesMentor: number
}

interface Votes {
    received: number
    given?: number
}

export type AttendanceStatus = 'present' | 'delay' | 'absent'

interface MentorDTO extends UserData {
    isMentor: true
    isExpertMentor: boolean
    available: boolean
}

interface UpdateAttendanceProps {
    _professorScheduleId: string
    _subjectId: string
    _studentId: string
    date: string
    status?: AttendanceStatus
    comment?: string
}

interface HQStudentListResponse {
    data: {
        _id: string
        name: string
        totalCount: number
        students: {
            _id: string
            email: string
            name: string
            lastname: string
            urlImage: string
        }[]
    }
    message: string
}

export interface GetMentorsProps {
    algorithmID: string
    team: number | string
    guide?: number
    meeting?: string
}

export interface GetMentorsResponse {
    data: {
        tableMentors: {
            _profileId: string
            displayName: string
            tableNumber: number
            urlImage: string
        }[]
        integrationMentors: {
            _profileId: string
            displayName: string
            tableNumber: number
            urlImage: string
        }[]
        _helpRequestId?: string
        action?: 'close'
    }
    message: string
}

interface UpdateAttendanceResponse {
    data: {
        _id: string
        status: AttendanceStatus
    }
    message: string
}

interface DeleteStudentResponse {
    data: {
        user: {
            _id: string
        }
        profile: {
            _id: string
        }
    }
    message: string
}

export interface SaveExamsProps {
    teamId: string
    exam: {
        title: string
        examDate: string
        notes: {
            _profileId: string
            note: number
        }[]
    }
}

interface SaveExamsResponse {
    data: {
        exam: {
            active: boolean
            _id: string
            _classId: string
            title: string
            notes: {
                note: string
                _profileId: string
                _userId: string
                name: string
                lastname: string
                createdAt: string
                updatedAt: string
            }[]
            createdBy: string
            examDate: string
            createdAt: string
            updatedAt: string
        }
        studentsError: unknown[]
    }

    message: string
}

export interface GetTeamSettingsResponse {
    settings: {
        _id: string
        name: string
        _hqId: string
        tables?: unknown[]
        maxEnrollStudents?: number
        canEnrollFromLink?: boolean
        automaticAssignAbsents?: boolean
        initialNumberTable?: number
        enabledManualAssign?: boolean
        enabledClassSummary: boolean
        mandatoryVote?: boolean
        rubricContent: boolean
        uploadExamContent?: boolean
        defaultStudentsPerTable?: number
        attachmentContent: boolean
        repeatAlgorithm: boolean
        realTimeDocuments: boolean
        algorithmType: TeamAlgorithmType
        algorithmTypeSecondary?: string
        algorithmAssignmentSocketAlert: boolean
        maxStudentPerTable: number
        checkAssignation: boolean
        defaultShouldCheckPresents: boolean
        isVirtual: boolean
        isVisible: boolean
        countGivenVotes?: boolean
        attendancesRegistrationTime: {
            attendancesStartTime: number
            attendancesEndTime: number
            attendanceDelayTime: number
        }
        mandatoryDataFill?: boolean
        termsAndConditions?: boolean
        enableMeetingUnassigned: boolean
        mantainTableConfig: boolean
        displayStats: boolean
        external_idCourse?: string
        active?: boolean
        type: string
        createdAt?: Date
        updatedAt: Date
        contentDays: string[]
    }
    schedules: {
        label?: string
        active: boolean
        startDay: number
        schedules: {
            label: string
            start: {
                day: number
                hour: number
                minutes: number
            }
            end: {
                day: number
                hour: number
                minutes: number
            }
        }[]
    }[]
    meetings: Meetings
    ableLaunchAlgorithm: {
        able: boolean
        hasScheduleConfigured: boolean
        hasMeetingConfigured: boolean
    }
}

export interface GetTeamDetails {
    data: {
        teams: TeamDetails[]
    }
    message: string
}

export interface TeamDetails {
    number: number
    isMentorTeam: boolean
    isVoted: boolean
    students: TeamStudent[]
    description: string
    module: string
    guide: string
}

export interface TeamStudent {
    displayName: string
    _profileId: string
    urlImage: string
    isAssigned: boolean
    isMentor: boolean
    isExpertMentor: boolean
    isInOrigin: boolean
    originTeam: number
    hasVoted: boolean
    isFacilitator: boolean
    stats: Stats
    attendance: {
        status: AttendanceStatus
        comment: string
    }
}

// eslint-disable-next-line no-undef
export type GetTeamDetailResponse = Awaited<ReturnType<typeof getTeamDetails>>
// eslint-disable-next-line no-undef
export type GetStudentSearchResponse = Awaited<ReturnType<typeof getPeopleSearch>>

export interface SearchResponse {
    data: SearchUser
    message: string
}

export interface SearchUser {
    results: SearchResult[]
    pagination: {
        total: number
    }
}

export interface SearchResult {
    displayName: string
    _profileId: string
    urlImage: string
    searchAssigned: 'assigned' | 'unassigned'
    isMentor: boolean
    isFacilitator: boolean
    user: {
        _id: string
    }
    isAssigned: boolean
    stats: {
        votes: {
            received: number
        }
        timesFacilitator: number
        timesMentor: number
    }
    attendance: {
        status: AttendanceStatus
        comment: string
    }
    isInOrigin: boolean
    tableNumber: number
    originTeam: number
    hasVoted: boolean
    isMentorTeam: boolean
    team?: { number: number }
}

export interface PeopleSearchResponse {
    students: SearchResult[]
}

interface UpdateTeamSettingsResponse {
    data: {
        _courseId: string
    }
    message: string
}

export interface TeamSettingsBody {
    name?: string
    config?: Meetings | Record<string, never>
    automaticAssignAbsents?: boolean
    initialNumberTable?: number
    enabledManualAssign?: boolean
    mantainTableConfig?: boolean
    mandatoryDataFill?: boolean
    attachmentContent?: boolean
    realTimeDocuments?: boolean
    mandatoryVote?: boolean
    termsAndConditions?: boolean
    rubricContent?: boolean
    uploadExamContent?: boolean
    algorithmType?: GetTeamSettingsResponse['settings']['algorithmType']
    algorithmAssignmentSocketAlert?: boolean
    defaultStudentsPerTable?: number
    maxTables?: number
    defaultShouldCheckPresents?: boolean
    displayStats?: boolean
    attendancesRegistrationTime?: {
        attendancesStartTime: number
        attendancesEndTime: number
        attendanceDelayTime: number
    }
    courseDuration?: {
        startDateCourse?: string
        endDateCourse?: string
    }
    isVirtual?: boolean
    isVisible?: boolean
    repeatAlgorithm?: boolean
    enableMeetingUnassigned?: boolean
}

interface Links {
    student: string
    collaborator: string
}

interface GetProfileDetails {
    data: UserProfile
    message: string
}

export interface UpdateProfileData extends UserProfile {
    start?: string
    paidId?: string
}

export interface UserProfile {
    _id: string
    _professorId?: string
    _professorScheduleId?: string
    acquisitionType?: string
    active: boolean
    createdAt?: Date
    email?: string
    givenVotes?: number
    institution?: string
    isExpertMentor: boolean
    lastname?: string
    moodleId?: string
    name?: string
    progressiveSchedule?: { start: Date; end?: Date }
    quitReason?: string
    role: UsersRoles
    setToUnactiveDate?: Date
    timesFacilitator?: number
    totalVotes?: number
    updatedAt: Date
    urlImage?: string
    votes?: number
    _classId?: {
        _id: string
        algorithmType: AlgorithmType
        algorithmTypeSecondary?: AlgorithmTypeSecondary
        active: boolean
        name: string
        courseDuration: {
            startDateCourse?: string
            endDateCourse?: string
        }
    }
    _hqId?: {
        _id: string
        name: string
    }
    _userId: {
        _id: string
        name: string
        lastname: string
    }
    lastVotes: {
        _algorithmId: string
        votes?: number
    }[]
    stats?: {
        attendances: {
            totalPresent: number
            totalAbsent: number
            totalDelay: number
        }
        totalGivenVotes?: number
        totalReceivedVotes?: number
    }
    zoom?: {
        id: string
        email: string
    }[]
    zoomMeet?: {
        zoomUrl: string
        registrantId: string
    }
    trackings: {
        message: string
        type:
            | 'enroll'
            | 'promoted'
            | 'modified'
            | 'new-start-date'
            | 'other'
            | 'pause'
            | 'unenroll-auto'
            | 'unenroll'
        date: string
    }[]
    paidId?: string
}

export type ProfileTrackingDTO = UserProfile['trackings'][0]

type AlgorithmType = 'hca1' | 'hca4' | 'default' | 'repeated'
type AlgorithmTypeSecondary = 'maingrowing' | 'onetable'

interface GetEnrollTokenResponse {
    data: {
        professorToken?: string
        token: string
        tutorToken: string
        short: {
            professorToken?: string
            token: string
            tutorToken: string
        }
    }
    message: string
}

interface AlgorithmResultType {
    algorithm: {
        _id: string
        _classId: string
        results: {
            facilitator: string
            positions: {
                number: number
                _profileId: string
                _userId: string
            }[]
        }[]
        createdAt: string
    }
}

export interface CourseMonitorDTO {
    ableLaunchAlgorithm: boolean
    ableRequestFeedback: boolean
    ableDownloadClassSummary: boolean
    schedulesOk: boolean
    isInClass?: boolean
    nextClassStart?: Date
    attendances?: {
        present: number
        delay: number
        absent: number
    }
    haveAlgorithm: boolean
    zoomConnections?: number
    totalCastStats?: number
    lastAlgorithm?: {
        _id: string
        type: string
        lastTime: string
        count: number
        expires: string
    }
    algorithmStatistics?: {
        rotation: {
            count: number
            lastTime: string
        }
        reset: {
            count: number
            lastTime: string
        }
    }
    monitor: {
        attendance: {
            present: number
            presentPercentage: number
            delay: number
            delayPercentage: number
            absent: number
            absentPercentage: number
        }
        helpRequests?: {
            total: number
            resolved: number
            inProgress: number
            pending: number
            expired: number
        }
        pulses: {
            individuals: number
            individualsPercentage: number
            group: number
            groupPercentage: number
        }
        teams: {
            totalStudents: number
            totalAttendances: number
            totalTeams: number
            studentAverageByTeam: number
        }
    }
}

export interface ZoomConnectionsStatus {
    data: {
        totalConnection: number
        peopleConnected: number
        peopleConnectedPercentage: number
        ratioConnections: {
            lowConnection: number
            halfConnection: number
            highConnection: number
        }
    }
    message: string
}

export interface ZoomConnectionDetail {
    data: {
        teams: {
            status: 'hight' | 'medium' | 'low'
            team: number
            description: string
            count: number
            meet: string
        }[]
    }
    message: string
}

export interface HelpRequestPending {
    data: {
        _helpRequestId: string
        action?: string
        expirationTime: Date
        level: 1 | 2 | 3
        status: 'requested' | 'expired'
        teamNumber: number
    }[]
    message: string
}

export interface GetDistributionByContent {
    data: {
        content: string
        students: number
        mentors: number
        teams: number
        teamsRange: string
        guide: number
        module: number
        details?: {
            day: string
            teamsRange: string
        }[]
    }[]
    message: string
}

export interface GetTimelineResponse {
    timeline: TimelineEventDTO[]
    currentEvent?: TimelineEventDTO
    nextEvent?: TimelineEventDTO
}

export interface TimelineEventDTO {
    room: MeetingRoom | null
    date: string
    status: 'past' | 'current' | 'future'
    _algorithmId?: string
    settings: {
        enabledShareScreen: boolean
        enabledMic: boolean
        externalContent?: {
            url: string
            type: ContentType
            mute?: boolean
        }
    }
}

export type ContentType = 'video' | 'image' | 'link'

export interface PostLastRoomData {
    courseID: string
    room: MeetingRoom | null
    date: string
}

export type MeetingRoom = 'general' | 'teams'

interface SendExternalContentProps {
    filter: TeamsFilter | CourseFilter
    payload:
        | {
              type: 'text'
              message: string
          }
        | (ComponentProps<'video'> & {
              type: 'video'
              src: string
              mute?: boolean
          })
}

export interface TeamsFilter {
    type: 'teams'
    _courseId: string
    teamNumbers: number[]
}

export type CourseFilter = AnyFilter

export interface AnyFilter {
    type: 'any'
    to: string[]
}

export type Filter = SendExternalContentProps['filter']

interface GetTeamContentResponse {
    files: {
        label: string
        value: string
    }[]
}
