import { t } from 'i18next'
import { useMutation, useQueryClient } from 'react-query'
import { deleteStudent, GetTeamDetails } from '../../apis/api.egg'
import { useUIContext } from '../../context/UIContext'
import { useTeam } from '../../pages/Team/Context/TeamContext'
import { useGAEvents } from '../useGAEvents'

type Props = {
    _profileId?: string
    displayName?: string
    isAssigned?: boolean
    teamInfo?: { number?: number }
}

export const useDeletePerson = ({ _profileId, isAssigned, teamInfo, displayName }: Props) => {
    const queryClient = useQueryClient()
    const { sendGA } = useGAEvents()
    const { toastError, toastNotification } = useUIContext()
    const { unassigned, courseID, unassignedPage } = useTeam()
    const { mutate: onDelete } = useMutation(
        [courseID, 'Team', 'Delete student', _profileId],
        () => deleteStudent(`${courseID}`, `${_profileId}`),
        {
            onMutate: () => {
                sendGA({
                    category: 'Team Management',
                    action: 'DeleteStudentClicked',
                })

                if (!isAssigned) {
                    // Remove from unassigned list
                    const key = [courseID, 'Team', 'Unassigned', unassignedPage]
                    const oldUnassigned = queryClient.getQueryData<typeof unassigned>(key)

                    queryClient.setQueryData(key, {
                        ...oldUnassigned,
                        results:
                            !!oldUnassigned?.results?.length &&
                            oldUnassigned?.results.filter(
                                (student) => student._profileId !== _profileId
                            ),
                    })
                } else {
                    // Or remove from team if is assigned
                    const teamNumber = teamInfo?.number
                    if (!teamNumber) return
                    const key = [courseID, 'Team', 'Details', teamNumber]
                    const oldTeam = queryClient.getQueryData<TeamDetails>(key)

                    oldTeam &&
                        queryClient.setQueryData(key, {
                            ...oldTeam,
                            students:
                                !!oldTeam?.students?.length &&
                                oldTeam?.students.filter(
                                    (student) => student._profileId !== _profileId
                                ),
                        })
                }
            },
            onError: (error) => {
                const key = isAssigned
                    ? [courseID, 'Team', 'Details', teamInfo?.number]
                    : [courseID, 'Team', 'Unassigned', unassignedPage]

                queryClient.invalidateQueries(key)
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
                //  setModalDeleteUser(false)
                toastError(error)
            },
            onSuccess: () => {
                const message = t('teamManager:actions.delete.toast_message', { displayName })
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
                //  setModalDeleteUser(false)
                toastNotification(message, 'success')
            },
        }
    )

    return { onDelete }
}

type TeamDetails = GetTeamDetails['data']['teams'][0]
