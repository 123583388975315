import styled, { css, keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IDropdownProps {
    open: boolean
}

export const MenuItem = styled.div(({ active }: { active?: boolean }) => {
    return css`
        * {
            color: ${active ? '#00A3FF' : '#003750'};
        }
        cursor: pointer;
        color: rgb(0, 55, 80);
        cursor: pointer;
        display: grid;
        grid-template-columns: 10% max-content;
        gap: 1.5em;
        padding: 0px 1rem;
        justify-content: end;
        justify-items: center;
        align-items: center;
        padding: 0 1rem;
        img {
            width: 25px;
        }
        svg {
            font-size: 20px;
        }
        &:hover {
            color: var(--color-active);
        }
    `
})
export const MenuItemDropdown = styled.div(({ active }: { active: number }) => {
    return css`
        align-items: center;
        color: ${active ? '#00A3FF' : '#003750'};
        cursor: pointer;
        display: flex;
        justify-content: left;
        gap: 2em;
        padding: 14px;
        transition: color 0.3s;
        &:hover {
            color: ${active ? '#00A3FF' : 'inherit'};
        }
    `
})

export const Icon = styled(FontAwesomeIcon)(({ active }: { active: number }) => {
    return css`
        color: ${active ? '#00A3FF' : '#003750'};
        font-size: 20px;
    `
})

export const Menu = styled.nav`
    position: fixed;
    width: 50px;
    height: 100%;
    padding: 3.5em 0;
    background-color: white;
`

export const MenuItemContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content max-content;
    align-content: space-between;
    justify-items: center;
    * {
        width: 100%;
    }
`

export const Logo = styled.img`
    width: 26px;
    cursor: pointer;
    padding-bottom: 3.5em;
`

export const Items = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    height: calc(100vh - 110px);
`

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-10px);
  }
`

export const Dropdown = styled.div<IDropdownProps>(({ open }) => {
    return css`
        animation: ${open ? slideIn : slideOut} 0.5s forwards;
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        display: ${open ? 'block' : 'none'};
        height: 100%;
        left: 65px;
        min-width: 250px;
        opacity: ${open ? '1' : '0'};
        position: fixed;
        top: 0;
        transform: ${open ? 'translateX(0)' : 'translateX(-10px)'};
        z-index: 1;
        @media screen and (max-width: 768px) {
            left: 0;
            min-width: 100%;
        }
    `
})

export const DropdownClose = styled.div`
    color: #003750;
    cursor: pointer;
    padding: 14px 22px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    &:hover {
        color: #00a3ff;
    }
`

export const DropdownItem = styled.div`
    height: 100vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: #eaeaea;
        border-radius: 1px;
    }
    ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 1px;
        width: 2px;
    }
`
