import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Searchbar from '../../../components/DesignSystem/components/molecules/Searchbar/Searchbar.component'
import { useUIContext } from '../../../context/UIContext'
import useDebounce from '../../../hooks/useDebounce'

export const SearchBox = ({ options, isBusy, debounce }: Props) => {
    const teamFilter = new URLSearchParams(window.location.search).get('team')
    const { searchBox } = useUIContext()
    const [query, setQuery] = useState(teamFilter || '')
    const [debouncedQuery] = useDebounce(query, debounce || 700)
    const [searchOption, setSearchOption] = useState<OptionsSearchBox>(
        teamFilter ? options[1] : options[0]
    )
    const [inputIcon, setInputIcon] = useState<'search' | 'times' | 'spinner'>('search')

    /** Get helpers list  */
    const helpSearchList = options.filter((type) => type.type === searchOption.type)[0].list || []

    const handleInputIconClick = () => {
        if (inputIcon !== 'times' || !query) return
        const { type } = searchOption
        searchBox[1]({ type, query: '' })
        setQuery('')
    }

    /** Handle input changes */
    useEffect(() => {
        const { type } = searchOption
        const newQuery: SearchQuery | null = !query.trim() ? null : { type, query: query.trim() }
        searchBox[1](newQuery)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedQuery])

    const handleOptionChange = (option: OptionsSearchBox) => {
        setQuery('')
        setSearchOption(option)
    }

    /** Changing input icon */
    useEffect(() => {
        const icon: typeof inputIcon = isBusy ? 'spinner' : debouncedQuery ? 'times' : 'search'
        setInputIcon(icon)
    }, [isBusy, debouncedQuery])

    /** Clearing search input */
    useEffect(() => {
        if (searchBox[0] === null) return setQuery('')
        const option = options.find((option) => option.type === searchBox[0]?.type)
        setQuery(searchBox[0]?.query)
        option && setSearchOption(option)
    }, [options, searchBox])

    /** Get last option searched from localStorage */
    useEffect(() => {
        if (options?.length) {
            // Search from URL params if exists
            // Usage: .../manage/search?type=team&query=test
            const params = new URLSearchParams(window.location.search)
            const urlType = params.get('searchType')
            const urlQuery = params.get('searchQuery')
            let option = options.find((option) => option.type === urlType)
            if (urlQuery && option) {
                const newQuery: SearchQuery = { type: option.type, query: urlQuery }
                searchBox[1](newQuery)

                setQuery(urlQuery)
                setSearchOption(option)
                return
            }

            // Else try to search from localStorage
            const latestSearchType = localStorage.getItem('lastSearchOption')
            let type = options[0]['type']
            if (latestSearchType?.includes('type')) type = JSON.parse(latestSearchType)['type']
            option = options.find((option) => option.type === type)
            option && setSearchOption(option)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Saving current option, to localStorage */
    useEffect(() => {
        localStorage.setItem('lastSearchOption', JSON.stringify(searchOption))
    }, [searchOption])

    return (
        <form onSubmit={(event) => event.preventDefault()} style={{ width: '100%' }}>
            <Searchbar
                autoComplete="off"
                handleOptionChange={handleOptionChange}
                iconOnClick={handleInputIconClick}
                id="searchInput"
                list="list"
                name="searchInput"
                onChange={(event) => setQuery((event.target as HTMLInputElement).value)}
                onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
                options={options}
                placeholder={searchOption?.placeholder}
                searchOption={searchOption}
                type="text"
                value={query}
            />

            <datalist id="list">
                {helpSearchList.map((item, index) => (
                    <option value={item} key={`${index} + ${item}`} />
                ))}
            </datalist>
        </form>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputIconRight = ({ icon }: InputIconRight) => {
    const variant = icon === 'spinner' ? 'fas' : 'far'
    return <FontAwesomeIcon className="icon" icon={[variant, icon]} spin={icon === 'spinner'} />
}

// Interfaces
interface Props {
    options: OptionsSearchBox[]
    optionsTitle: string
    isBusy: boolean
    debounce?: number
}

export interface OptionsSearchBox {
    type: 'people' | 'teams'
    label: string
    placeholder: string
    list?: string[] | number[]
    icon?: IconProp
}

interface InputIconRight {
    icon: 'search' | 'times' | 'spinner'
}

export interface SearchQuery {
    type: OptionsSearchBox['type']
    query: string
}
