import { StudentItemProps } from '../StudentItem'
import { Attendance } from './Attendance'
import { TalkQualityUser } from './TalkQualityUser'
import styles from '../StudentItem.module.css'

export const ActionsSelector = (props: StudentItemProps) => {
    return (
        <div className={styles.actionsBlock} style={{ justifyContent: 'flex-end' }}>
            <TalkQualityUser {...props} />
            <Attendance {...props} />
        </div>
    )
}
