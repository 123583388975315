import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ToolTip from '../../../../components/Styled/ToolTip'
import { useGAEvents } from '../../../../hooks/useGAEvents'
import { useLiveMonitor } from '../../Hooks/useLiveMonitor'
import { UnAssignedLink } from '../Overview/UnAssignedLink'
import { LastRotation } from './Components/LastRotation'
import { AttendanceAreaCard } from './Components/NewComponentsMonitor/AttendanceAreaCard'
import { AttendanceLiveCard } from './Components/NewComponentsMonitor/AttendanceLiveCard'
import { AttendanceProgressCard } from './Components/NewComponentsMonitor/AttendanceProgressCard'
import { TalkingLiveCard } from './Components/NewComponentsMonitor/TalkingLiveCard'
import { TalkingQualityCard } from './Components/NewComponentsMonitor/TalkingQualityCard'
import { TeamsLiveCard } from './Components/NewComponentsMonitor/TeamsLiveCard'
import { TeamsQualityCard } from './Components/NewComponentsMonitor/TeamsQualityCard'
import { TimelineEvents } from './Components/TimelineEvents'
import styles from './LiveMonitor.module.css'

export const LiveMonitor = () => {
    const { t } = useTranslation('teamMonitor')
    const { sendGA } = useGAEvents()
    const { stats, refetch, isFetching } = useLiveMonitor()

    if (isFetching)
        return (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={['fas', 'spinner']} spin size="2x" />
            </div>
        )

    if (!stats) return <p>{t('The data could not be retrieved. Please contact support.')}</p>

    return (
        <div className={styles.container}>
            <section>
                <LastRotation lastRotation={stats?.lastRotation} />
                <TimelineEvents />
                <UnAssignedLink />
            </section>
            <header className={styles.updateData}>
                <ToolTip
                    placement="topEnd"
                    text={t('Updates in real time the data of the meeting monitor')}
                >
                    <button
                        className={styles.btnRefresh}
                        onClick={() =>
                            refetch().finally(() =>
                                sendGA({ category: 'Reporting', action: 'UpdateClassMonitor' })
                            )
                        }
                        disabled={isFetching}
                    >
                        <FontAwesomeIcon icon={'arrow-rotate-right'} spin={isFetching} />
                        <h6 className={styles.btnRefreshText}>{t('Update data')}</h6>
                    </button>
                </ToolTip>
            </header>
            <div className={styles.liveContainer}>
                <div className={styles.rowContainer}>
                    <AttendanceLiveCard />
                    <AttendanceProgressCard />
                    <AttendanceAreaCard />
                </div>
                <div className={styles.rowContainer}>
                    <TeamsLiveCard />
                    <TeamsQualityCard />
                </div>
                <div className={styles.rowContainer}>
                    <TalkingLiveCard />
                    <TalkingQualityCard />
                </div>
                {/* <div className={styles.rowContainer}>
                    <HelpRequestLiveCard />
                    <HelpRequestQualityCard />
                    <HelpRequestAreaCard />
                </div>
                <div className={styles.rowContainer}>
                    <PulsesLiveCard />
                    <PulsesQualityCard />
                    <ConnectionsQualityCard />
                </div> */}
            </div>
        </div>
    )
}
