import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    getProfileDayOfContent,
    UpdateContentData,
    updateProfileDayOfContent,
} from '../../apis/api.content'
import { useUIContext } from '../../context/UIContext'
import { useTracking } from './useTracking'
import { Error } from '../../interfaces/error'

export const useProfileDayOfContent = (profileId: string) => {
    const queryClient = useQueryClient()
    const { toastNotification } = useUIContext()
    const { addTracking } = useTracking()
    const contentKey = ['getProfileDayOfContent', profileId]
    const { data: content, status: contentStatus } = useQuery(
        contentKey,
        () => getProfileDayOfContent(`${profileId}`),
        {
            enabled: !!profileId,
            staleTime: 1000 * 60 * 5,
            retry: 2,
            onError: () =>
                toastNotification(
                    'No fué posible recuperar información sobre el contenido académico.',
                    'error',
                    5
                ),
        }
    )
    const { mutate: mutateContent } = useMutation(
        (params: UpdateContentData) => updateProfileDayOfContent(params),
        {
            onMutate: ({ content: { newContentNumber } }) => {
                const snapshot = queryClient.getQueryData<typeof content>(contentKey)
                snapshot &&
                    queryClient.setQueryData<typeof content>(contentKey, (prev) =>
                        prev ? { ...prev, day: newContentNumber, description: 'Cargando...' } : prev
                    )
                return snapshot
            },
            onSuccess: ({ newContent }, { content: { newContentNumber } }) => {
                const { number: contentNumber, description } = newContent
                const error = contentNumber !== newContentNumber
                if (!description)
                    newContent.description = 'Descripción del contenido no disponible.'
                queryClient.setQueryData<typeof content>(contentKey, (prev) =>
                    prev ? { ...prev, contentNumber, description } : prev
                )
                addTracking({
                    _id: profileId,
                    tracking: {
                        date: new Date().toISOString(),
                        type: 'modified',
                        message: !error
                            ? `Contenido modificado al día ${contentNumber}`
                            : `Error al modificar el contenido al día ${newContentNumber}`,
                    },
                })
            },
            onError: (error: Error, data, snapshot) => {
                snapshot && queryClient.setQueryData(contentKey, snapshot)
                toastNotification(
                    error.response.data.message ||
                        'No fué posible modificar el contenido académico.',
                    'error',
                    5
                )
                {
                    data.profileIds &&
                        addTracking({
                            _id: data.profileIds[0],
                            tracking: {
                                date: new Date().toISOString(),
                                type: 'modified',
                                message: `Error al modificar el contenido al día ${data.content.newContentNumber}`,
                            },
                        })
                }
            },
        }
    )
    return { content, contentStatus, mutateContent }
}
