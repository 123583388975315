import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import { useArrange } from '../../../../../../hooks/ApiHooks/useRearrange'
import { useTeam } from '../../../../Context/TeamContext'
import { inputStyles } from '../../StudentItem'
import { ModalProps } from '../MoreOptions'

export const RearrangeModal = ({ props, isOpen, closeModal }: ModalProps) => {
    const { t } = useTranslation('teamManager')
    const { displayName, _profileId, isMentor, originTeam } = props
    const { team } = useTeam()
    const [newTeamNumber, setNewTeamNumber] = useState(props.team?.number + '')
    const { rearrange, isLoading } = useArrange(+newTeamNumber)
    const onRearrange = () => {
        isMentorNotInOrigin
            ? teamNumber && rearrange({ ...props, isMentor: false }, teamNumber, 'origin')
            : rearrange({ ...props, isMentor: false }, teamNumber ?? 'unassigned', +newTeamNumber)
    }
    const isInOrigin = props.team?.number === originTeam
    const teamNumber = props.team?.number
    const allTeamNumbers = team?.stats.allTeamNumbers
    const availableTeams = allTeamNumbers && [
        ...allTeamNumbers.filter((number) => number !== teamNumber),
    ]
    const isValidTeam = availableTeams?.includes(+newTeamNumber) || teamNumber !== +newTeamNumber

    const isMentorNotInOrigin = isMentor && !isInOrigin
    return (
        <ConfirmModal
            onConfirm={onRearrange}
            onClose={closeModal}
            open={isOpen}
            type={'warning'}
            title={t('actions.reassign.title-modal', { displayName })}
            contentText={
                <div className="user-action-reassign">
                    <label htmlFor="teamNumber">{t('actions.reassign.input-label')}</label>
                    <input
                        type="number"
                        name="teamNumber"
                        id="teamNumber"
                        list={_profileId}
                        value={newTeamNumber === '0' ? '' : newTeamNumber}
                        onChange={({ target: { value } }) => setNewTeamNumber(value)}
                        min={allTeamNumbers?.[0]}
                        max={allTeamNumbers?.[allTeamNumbers.length - 1]}
                        className="form-input-no-card"
                        placeholder={t('actions.reassign.input-label')}
                        style={{
                            ...inputStyles,
                        }}
                    />
                </div>
            }
            confirmButtonText={
                isMentorNotInOrigin
                    ? t('actions.reassign.returnMentor')
                    : t('actions.reassign.assign-btn')
            }
            cancelButtonText={t('user-options.cancel-btn-modal')}
            disabled={isMentorNotInOrigin ? isLoading : isLoading || !isValidTeam}
        />
    )
}
