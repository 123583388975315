import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { ReactNode } from 'react'
import {
    AccordionHeaderActions,
    AccordionHeaderCollapseButton,
    AccordionHeaderContainer,
    AccordionHeaderContent,
    AccordionHeaderContentContainer,
    AccordionHeaderContentIcon,
    AccordionHeaderContentTitle,
    AccordionHeaderIcon,
    AccordionHeaderSubtitle,
    AccordionHeaderTitle,
} from './AccordionHeader.styles'

const AccordionHeader = ({
    children,
    contentTitle = '',
    headerTitleStyles = {},
    icon,
    isCollapsed = false,
    onMouseEnter,
    subtitle = '',
    title = '',
    toggleCollapsed,
    warning,
    onIconClick,
}: IAccordionHeaderProps) => {
    return (
        <AccordionHeaderContainer isCollapsed={isCollapsed}>
            <AccordionHeaderContentContainer>
                {icon && <AccordionHeaderIcon icon={icon} />}

                {title.trim() && (
                    <AccordionHeaderTitle style={headerTitleStyles}>
                        {title.toLowerCase()}
                    </AccordionHeaderTitle>
                )}

                {warning && warning}

                {subtitle.trim() && (
                    <AccordionHeaderSubtitle>{subtitle?.toLowerCase()}</AccordionHeaderSubtitle>
                )}

                <AccordionHeaderContent>
                    {onIconClick && (
                        <button className="icon" onClick={onIconClick}>
                            <AccordionHeaderContentIcon icon="folder" />
                        </button>
                    )}
                    {contentTitle.trim() && (
                        <AccordionHeaderContentTitle>
                            {contentTitle.toLowerCase()}
                        </AccordionHeaderContentTitle>
                    )}
                </AccordionHeaderContent>
            </AccordionHeaderContentContainer>
            <AccordionHeaderActions>
                {children && <>{children}</>}
                <AccordionHeaderCollapseButton
                    icon={isCollapsed ? 'chevron-down' : 'chevron-up'}
                    isCollapsed={isCollapsed}
                    onClick={toggleCollapsed}
                    onMouseEnter={onMouseEnter}
                />
            </AccordionHeaderActions>
        </AccordionHeaderContainer>
    )
}

export default AccordionHeader

export interface IAccordionHeaderProps {
    children?: ReactNode
    contentTitle?: string
    headerTitleStyles?: React.CSSProperties
    icon?: IconProp
    isCollapsed?: boolean
    onMouseEnter?: () => void
    subtitle?: string
    title?: string
    toggleCollapsed: () => void
    warning?: ReactNode
    onIconClick?: () => void
}
