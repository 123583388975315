import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../context/UserContext'
import { capitalize } from '../../helpers/formatter'
import { isSentryEnable, sendUserFeedback, SentryUserData } from '../../lib/sentry'
import Tooltip from '../DesignSystem/components/atoms/Tooltip/Tooltip.component'
import styles from './SentryFeedbackButton.module.css'

export const SentryFeedbackButton = () => {
    const { t } = useTranslation('sentry')
    const { user } = useUser()
    if (!isSentryEnable) return null

    const { _id, email, name, lastname } = user || {}
    const username = capitalize(`${name ?? ''} ${lastname ?? ''}`)

    const userData: SentryUserData = {
        id: _id,
        email,
        username: username.trim(),
    }

    return (
        <Tooltip placement="fixedButtonRight" text={t('Report an error')}>
            <button
                type="button"
                className={styles.button}
                onClick={() => sendUserFeedback(userData)}
                data-tooltip-id="sentry-feedback-action"
            >
                <FontAwesomeIcon icon={['far', 'bug']} size="lg" color="#073045" />
            </button>
        </Tooltip>
    )
}
