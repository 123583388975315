import React from 'react'
import Axios from 'axios'
import Config from '../../config'
import { useGAEvents } from '../useGAEvents'
import { GetTeamResponse } from '../../apis/api.egg'

export type VoteTablesFunction = (
    table: number,
    value?: boolean
) => Promise<{
    table: number
    _algorithmId: string
    quantityVotes: number
}>

export type VoteTableFunction = (value?: boolean) => ReturnType<VoteTablesFunction>

export function useTablesVote(
    /** INPUT */
    algorithmId: string | undefined,
    // eslint-disable-next-line no-console
    onError = console.error,
    trackingData?: GetTeamResponse['data']
): [
    /** OUTPUT */
    { [table: number]: boolean } | undefined | null,
    VoteTablesFunction
] {
    const [votes, setVotes] = React.useState<{ [table: number]: boolean } | undefined | null>()
    const { sendGA } = useGAEvents()

    React.useEffect(
        function getCurrentVotes() {
            if (!algorithmId) return
            Axios.get(`${Config.API}/algorithm/${algorithmId}/votes`)
                .then((response) => {
                    setVotes(
                        response.data.voteTables?.reduce(
                            (result: Record<string, boolean>, { table }: { table: number }) => {
                                result[table] = true
                                return result
                            },
                            {}
                        )
                    )
                })
                .catch((error) => {
                    setVotes(null)
                    onError(error)
                })
        },
        [algorithmId, onError]
    )

    const vote: VoteTablesFunction = async (table, value) => {
        if (!votes) throw Error('Voted array not started')
        if (!value) {
            value = !votes[table]
        }
        const response = await Axios({
            method: value ? 'POST' : 'DELETE',
            url: `${Config.API}/vote/table`,
            data: { _algorithmId: algorithmId, table },
        })
        setVotes({ ...votes, [table]: value ?? false })

        // Only send to analytics when the table has been voted
        // TODO: Should we send it when the vote were removed?
        trackingData &&
            sendGA({
                category: 'Engagement',
                action: 'Vote-Table',
                team: trackingData,
            })

        return response.data
    }

    return [votes, vote]
}

export function useVoteTable(
    /** INPUT */
    algorithmId: string | undefined,
    table: number | undefined,
    // eslint-disable-next-line no-console
    onError = console.error,
    trackingData?: GetTeamResponse['data'] | undefined
): [
    /** OUTPUT */
    boolean | undefined | null,
    VoteTableFunction
] {
    const [voted, setVoted] = React.useState<boolean | undefined | null>()
    const { sendGA } = useGAEvents()

    React.useEffect(
        function getCurrentVotes() {
            if (!algorithmId || table === undefined) return
            Axios.get(`${Config.API}/algorithm/${algorithmId}/votes`)
                .then((response) => {
                    setVoted(response.data.hasVoted)
                })
                .catch((error) => {
                    setVoted(null)
                    onError(error)
                })
        },
        [algorithmId, onError, table]
    )

    const vote: VoteTableFunction = async (value) => {
        //if (voted===undefined) throw 'Voted array not started'
        if (value === undefined) value = !voted

        const response = await Axios({
            method: value ? 'POST' : 'DELETE',
            url: `${Config.API}/vote/table`,
            data: { _algorithmId: algorithmId, table },
        })
        setVoted(value)

        // Only send to analytics when the table has been voted
        // TODO: Should we send it when the vote were removed?

        trackingData &&
            sendGA({
                category: 'Engagement',
                action: 'Vote-Table',
                team: trackingData,
            })

        return response.data
    }

    return [voted, vote]
}
