import React from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../components/Pagination'
import { StudentItem } from './StudentItem/StudentItem'
import { useTeam } from '../Context/TeamContext'
import Config from '../../../config'
import { useQuery, useQueryClient } from 'react-query'
import { updateUnAssigned } from '../../../apis/api.egg'
import Accordion from '../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
const { UNASSIGNED_BY_PAGE } = Config

export const UnassignedList = () => {
    const { courseID, unassignedPage } = useTeam()
    const queryClient = useQueryClient()
    const { t } = useTranslation('teamManager')

    const preFetchUnassigned = () =>
        queryClient.prefetchQuery([courseID, 'Team', 'Unassigned', unassignedPage], () =>
            updateUnAssigned(`${courseID}`, unassignedPage)
        )

    return (
        <Accordion
            header={{
                icon: ['fas', 'users'],
                title: t('unassigned.title'),
                onMouseEnter: preFetchUnassigned,
            }}
        >
            <List />
        </Accordion>
    )
}

const List = () => {
    const { courseID, unassignedPage, setPagination, team } = useTeam()

    /** Get unassigned list */
    const { data: unassigned } = useQuery(
        [courseID, 'Team', 'Unassigned', unassignedPage],
        () => updateUnAssigned(`${courseID}`, unassignedPage),
        {
            enabled: !!courseID,
            keepPreviousData: true,
        }
    )

    const totalPages = unassigned ? Math.ceil(unassigned.pagination.total / UNASSIGNED_BY_PAGE) : 0

    return (
        <div className="container">
            <div>
                <div className="user-list">
                    {unassigned?.results.map((student) => (
                        <StudentItem
                            key={student._profileId}
                            {...student}
                            canDelete={true}
                            algorithmType={team?.algorithm?.algorithmType}
                        />
                    ))}
                </div>
            </div>

            {totalPages > 1 && (
                <div className="pagination-container" style={{ marginBottom: 0 }}>
                    <Pagination
                        onChange={(page) =>
                            setPagination((prev) => ({ ...prev, unassigned: page }))
                        }
                        count={totalPages}
                        boundary={1}
                        middles={5}
                    />
                </div>
            )}
        </div>
    )
}
