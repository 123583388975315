import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Pagination from '../../../components/Pagination'
import ToolTip from '../../../components/Styled/ToolTip'
import Config from '../../../config'
import { useUIContext } from '../../../context/UIContext'
import { useTeam } from '../Context/TeamContext'
import { Rotate } from './Overview/Rotate'
import { TeamAccordion } from './TeamAccordion'
import { StudentList } from './TeamItem'
import styles from './TeamsList.module.css'

const { TEAMS_BY_PAGE } = Config

export const TeamsList = () => {
    const queryClient = useQueryClient()
    const { t } = useTranslation(['teamManager', 'teamMonitor'])
    const { toastNotification } = useUIContext()
    const { team, setPagination } = useTeam()
    const [isFetching, setIsFetching] = useState(false)

    if (!team) return null

    const {
        pagination,
        teams,
        stats: { students },
    } = team

    const totalPages = Math.ceil(pagination.totalTeams / TEAMS_BY_PAGE)

    const refreshTeamsAndParticipation = async () => {
        setIsFetching(true)
        try {
            await queryClient.invalidateQueries([team.course._id, 'Team', 'Teams list'], {
                exact: false,
            })
            await queryClient.invalidateQueries([team.course._id, 'Team', 'Details'], {
                exact: false,
            })
            await queryClient.invalidateQueries([team.course._id, 'Users', 'Participation'], {
                exact: false,
            })
        } catch (error) {
            toastNotification(t('teamsList.dataNotUpdated'), 'error')
        } finally {
            setIsFetching(false)
        }
    }

    if (!students.total)
        return (
            <div className="content empty">
                <p className="global-text">{t('There are no users loaded.')}</p>
            </div>
        )
    if (!students.assigned)
        return (
            <div className="content empty">
                <p className="global-text">{t('empty.no-tables')}</p>
                <Rotate />
            </div>
        )
    // When have student enrolled & assigned
    return (
        <div className={styles.teamsList}>
            <header className={styles.updateData}>
                <ToolTip placement="topEnd" text={t('teamsList.updateDataTooltip')}>
                    <button className={styles.btnRefresh} onClick={refreshTeamsAndParticipation}>
                        <FontAwesomeIcon icon={'arrow-rotate-right'} spin={isFetching} />
                        <h6 className={styles.btnRefreshText}>{t('teamsList.updateData')}</h6>
                    </button>
                </ToolTip>
            </header>
            <div className={`${styles.container} ${isFetching ? styles.isFetching : ''}`}>
                {teams.map((team) => (
                    <div key={team.number} className={styles.team}>
                        <TeamAccordion
                            {...{
                                number: team.number,
                                guide: team.guide,
                                module: team.module,
                                description: team.description,
                                meeting: team.meeting,
                            }}
                        >
                            <StudentList teamNumber={team.number} />
                        </TeamAccordion>
                    </div>
                ))}
                {totalPages > 1 && (
                    <div className="pagination-container">
                        <Pagination
                            onChange={(page) => setPagination((prev) => ({ ...prev, teams: page }))}
                            count={totalPages}
                            boundary={2}
                            middles={5}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export type TeamAccordionProps = {
    children?: React.ReactNode
    number: number
    guide?: number
    module?: number
    description?: string
    meeting?: string
    notCollapsed?: boolean
    isExpanded?: boolean
    toggleExpand?: () => void
}

export const TeamSpinner = () => (
    <div className={styles.spinner}>
        <FontAwesomeIcon icon="spinner" spin />
    </div>
)
