import Axios from 'axios'
import Config from '../config'
import { API_RESPONSE } from '../pages/Team/api-reports'

const { API_CONTENT } = Config

export const ApiContent = Axios.create({
    baseURL: API_CONTENT,
})

export const getProfileDayOfContent = (profileId: string) =>
    ApiContent.get<ProfileDayOfContentResponse>(
        `v1/contents/profiles/progression/${profileId}`
    ).then(({ data }) => data.data)

export const updateProfileDayOfContent = (data: UpdateContentData) =>
    ApiContent.patch<UpdateContentResponse>(`v1/contents/profiles/progression/bulk/set`, data).then(
        ({ data }) => {
            const response = data.data
            return {
                ...response,
                message: data.message,
            }
        }
    )

// Get the content of a profile Response
type ProfileDayOfContentResponse = API_RESPONSE<ProfileDayOfContentDTO>

export interface ProfileDayOfContentDTO {
    contentNumber: number
    description: string
}

// Update the content of a profile Response
export type UpdateContentResponse = API_RESPONSE<UpdateContentDTO>

export interface UpdateContentData {
    courseId: string
    target: 'course' | 'cohorts' | 'users' | 'emails' | 'profiles' | 'startDates'
    content: {
        newContentNumber: number
    }
    emails?: string[]
    userIds?: string[]
    profileIds?: string[]
    cohortIds?: string[]
    startDates?: string[]
}

export interface UpdateContentDTO {
    totalProfilesUpdated: number
    totalProfilesRequested: number
    totalProfilesFound: number
    newContent: {
        number: number
        description: string
    }
}
