import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useLocation } from 'wouter'
import { defaultModalStyles } from '../../../../../../constants'
import { colors } from '../../../../../../styles/globals/_variables'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTeam } from '../../../../Hooks/useTeam'
import { QualityData, QualityDataProps } from '../../Cards/QualityData'
import styles from './../../LiveMonitor.module.css'

export const TalkingQualityCard = () => {
    const { t } = useTranslation('teamMonitor')
    const [currentQualityLevel, setCurrentQualityLevel] = useState<'hight' | 'moderate' | 'low'>()
    const [teams, setTeams] = useState<string[] | null>(null)
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { mild, moderate, severe } = stats.monitor.talkingPeople.qualityTeams

    const ModalButton = React.memo(
        ({
            label,
            teams,
            quality,
        }: {
            label: string | number
            teams: string[]
            quality: 'hight' | 'moderate' | 'low'
        }) => {
            const stringLabel = typeof label === 'string'
            return (
                <button
                    onClick={() => {
                        setCurrentQualityLevel(quality)
                        setTeams(teams)
                    }}
                    className={stringLabel ? styles.link : styles.titleLink}
                    disabled={!teams.length}
                >
                    {stringLabel ? t(label) : label}
                </button>
            )
        },
        (prevProps, nextProps) => prevProps.teams === nextProps.teams
    )

    const helpRequestInfo: QualityDataProps = {
        title: t('Team speaking quality'),
        status: ([
            {
                icon: ['fas', 'check-circle'] as IconProp,
                label: 'More than 50% spoke',
                value: mild,
                quality: 'hight',
            },
            {
                icon: ['fas', 'play-circle'] as IconProp,
                label: 'Between 10% and 50% spoke',
                value: moderate,
                quality: 'moderate',
            },
            {
                icon: ['fas', 'circle-exclamation'] as IconProp,
                label: 'Less than 10% spoke',
                value: severe,
                quality: 'low',
            },
        ] as const).map<QualityDataProps['status'][number]>(({ icon, label, value, quality }) => ({
            icon,
            label: <ModalButton label={label} teams={value} quality={quality} />,
            value: <ModalButton label={value.length} teams={value} quality={quality} />,
        })),
        info: t(
            'This graph shows the percentage of teams where a certain percentage of team members spoke during the video call. Click on any of the numbers below to view the associated teams. Within the modal, you can select a team, and upon doing so, you will be redirected to the Teams screen with the team already filtered.'
        ),
    }
    return (
        <>
            <QualityData {...helpRequestInfo} />
            {currentQualityLevel && (
                <TeamsModal
                    teams={teams}
                    currentModal={currentQualityLevel}
                    closeModal={() => setCurrentQualityLevel(undefined)}
                />
            )}
        </>
    )
}

export const TeamsModal = ({
    teams,
    currentModal,
    closeModal,
}: {
    teams: string[] | null
    currentModal: string
    closeModal: () => void
}) => {
    const { t } = useTranslation('teamMonitor')
    const [, setLocation] = useLocation()
    const { courseID } = useTeam()

    const iconToUse =
        currentModal === 'hight'
            ? 'check-circle'
            : currentModal === 'moderate'
            ? 'play-circle'
            : 'circle-exclamation'

    const colorToUse =
        currentModal === 'hight'
            ? colors.positiveGreen
            : currentModal === 'moderate'
            ? colors.eggColor
            : colors.salmon

    return (
        <Modal isOpen={!!currentModal} style={defaultModalStyles}>
            <FontAwesomeIcon
                icon={['fas', 'times']}
                onClick={closeModal}
                className={styles.closeIcon}
            />
            <div className={styles.modal}>
                <h4>
                    <FontAwesomeIcon
                        icon={['fas', iconToUse]}
                        color={colorToUse}
                        style={{ marginRight: 10 }}
                    />
                    {t(`Teams with ${currentModal} quality`)}
                </h4>
                <div className={styles.table}>
                    {teams?.map((team) => (
                        <div
                            key={team}
                            className={styles.talkingQualityRow}
                            onClick={() =>
                                setLocation(
                                    `/admin/team/${courseID}?searchType=teams&searchQuery=${team}`
                                )
                            }
                        >
                            <div className={styles.talkingQualityItem}>
                                <p className={styles.buttonTitle}>
                                    {t('Team')} {team}
                                </p>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    )
}
