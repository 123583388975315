import { deleteTeam, getTeamSettings, updateTeamSettings } from '../../apis/api.egg'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import ConfirmModal from '../../components/ConfirmModal'
import FullLayout from '../../layouts/FullLayout'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import LoadingPage from '../Other/LoadingPage'
import { MeetingSupport } from './Components/Settings/MeetingSupport'
import { ROLES } from '../../constants'
import React from 'react'
import { Schedule } from './Components/Settings/Schedule'
import TeamInfo from './Components/Settings/TeamInfo'
import WarningBlock from '../../components/WarningBlock'
import { capitalize } from '../../helpers/formatter'
import { colors } from '../../styles/globals/_variables'
import styled from 'styled-components'
import { useGAEvents } from '../../hooks/useGAEvents'
import { useLocation } from 'wouter'
import { useTeam } from './Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../context/UIContext'
import { useUser } from '../../context/UserContext'

export const Settings = () => {
    const { role } = useUser()

    const { settings, schedules, onTeamDelete, status } = useSettings()
    const [, setLocation] = useLocation()
    const { t } = useTranslation(['teamSettings'])

    const { team, courseID, mainLinks } = useTeam()
    const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false)

    useEffect(() => {
        if (role === ROLES.PROFESSOR) setLocation(`/admin/overview/${courseID}`)
    }, [role, setLocation, courseID])

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    if (status === 'loading') return <LoadingPage />
    if (!team || !settings) return null

    return (
        <FullLayout
            links={mainLinks}
            ruta={`${capitalize(team?.hq.name)} > ${capitalize(team.teamName)}`}
        >
            <WarningBlock />
            <div className="container" style={{ padding: '14px 0 0' }}>
                <div className="team-settings-container">
                    <HeaderBanner title={team?.teamName} subtitle={t('heading.title')} />
                    <div className="settings-title">{t('heading.config')}</div>
                    <div className="content">
                        <TeamInfo />
                        <Schedule schedule={schedules} />
                        <MeetingSupport />
                        {/* <Meetings isVirtual={settings.isVirtual} meetings={meetings} /> */}
                        {/* <div className="settings-title">{t('heading.adjustments')}</div> */}
                        {/* <AdvanceSettings /> */}
                    </div>
                </div>
            </div>

            <BottomBlock>
                <div className="container">
                    <div className="actions-container">
                        <button
                            className="btn-outline btn-danger"
                            style={{ padding: '10px 15px', fontSize: '14px' }}
                            onClick={() => setShowDeleteTeamModal(true)}
                        >
                            {t('teamSettings:content.delete')}
                        </button>
                    </div>
                </div>
                <ConfirmModal
                    open={showDeleteTeamModal}
                    type="danger"
                    title={t('teamSettings:content.delete')}
                    contentText={
                        <div>
                            <p>{t('content.delete-confirm')}</p>
                        </div>
                    }
                    confirmButtonText={t('RotateRestore.confirm.confirmButtonTextAccept')}
                    cancelButtonText={t('RotateRestore.confirm.cancelButtonText')}
                    onClose={() => setShowDeleteTeamModal(false)}
                    onConfirm={() => onTeamDelete()}
                />
            </BottomBlock>
        </FullLayout>
    )
}

const BottomBlock = styled.div`
    background-color: ${colors.lightGreyBg};
    .container {
        padding: 40px 0;
        .actions-container {
            display: flex;
            justify-content: flex-end;
        }
    }
`

// Custom Hook
export const useSettings = () => {
    const queryClient = useQueryClient()
    const { toastError } = useUIContext()
    const [, setLocation] = useLocation()
    const { team, courseID, teamPage } = useTeam()
    const { sendGA } = useGAEvents()

    const subjectID = team?.subject._id
    const scheduleId = team?.professorSchedule._id

    const keyTeam = [courseID, 'Team', 'Teams list', teamPage]
    const keyGetter = [courseID, 'Team', 'Settings', 'Get Settings']
    const keySetter = [courseID, 'Team', 'Setting', 'Advanced Settings']

    const { data: config, ...rest } = useQuery(keyGetter, () => getTeamSettings(`${courseID}`), {
        enabled: !!courseID,
        onError: (error) => toastError(error),
    })

    const { mutate: updateSetting } = useMutation(
        keySetter,
        (conf: Parameters<typeof updateTeamSettings>[1]) => updateTeamSettings(`${courseID}`, conf),
        {
            onMutate: async (conf) => {
                await queryClient.cancelQueries(keySetter)
                const snapshot = queryClient.getQueryData<typeof config>(keyGetter)
                queryClient.setQueryData<typeof config>(keyGetter, (prev) =>
                    prev ? { ...prev, settings: { ...prev.settings, ...conf } } : prev
                )

                return snapshot
            },
            onError: (error, conf, snapshot) => {
                queryClient.setQueryData(keyGetter, snapshot)
                toastError(error)
            },
            onSettled: () => queryClient.invalidateQueries(keyTeam),
        }
    )

    const { mutate: onTeamDelete } = useMutation(
        [courseID, 'Team', 'Settings', 'Delete Team'],
        () => deleteTeam(`${scheduleId}`, `${subjectID}`),
        {
            onMutate: () => {
                sendGA({ category: 'Team Settings', action: 'DeleteTeam' })
                setLocation('/')
            },

            onError: (error) => toastError(error),
        }
    )

    return { ...config, ...rest, updateSetting, onTeamDelete }
}
