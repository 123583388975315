import React from 'react'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { OneData, OneDataProps } from '../../Cards/OneData'
import { useTranslation } from 'react-i18next'
import styles from './../../LiveMonitor.module.css'

export const TalkingLiveCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { total } = stats.monitor.talkingPeople

    const teamsInfo: OneDataProps = {
        title: (
            <>
                {t('People speaking')} <span className={styles.boldRed}>LIVE</span>
            </>
        ),
        info: t('People who have spoken in the last 10 minutes'),
        caption: '',
        data: total,
        footerIcon: undefined,
        footerText: undefined,
        footerData: undefined,
    }
    return <OneData {...teamsInfo} />
}
