import { useTranslation } from 'react-i18next'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import { useDeletePerson } from '../../../../../../hooks/ApiHooks/useDeletePerson'
import { ModalProps } from '../MoreOptions'

export const DeleteUserModal = ({ props, isOpen, closeModal }: ModalProps) => {
    const { t } = useTranslation('teamManager')
    const { displayName, _profileId, team: teamInfo, isAssigned } = props
    const { onDelete } = useDeletePerson({ ...{ _profileId, displayName, isAssigned, teamInfo } })

    return (
        <ConfirmModal
            onConfirm={onDelete}
            onClose={closeModal}
            open={isOpen}
            type={'danger'}
            title={t('user-options.title-modal-delete', { displayName })}
            contentText={t('user-options.content-modal-delete')}
            confirmButtonText={t('user-options.confirm-btn-modal')}
            cancelButtonText={t('user-options.cancel-btn-modal')}
        />
    )
}
