import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { CardInfo, CardsHeader, CardsHeaderProps } from './CardsHeader'
import Styles from './OneData.module.css'

export const OneData = (props: OneDataProps) => {
    const { data, caption, footerIcon, footerText, footerData } = props
    return (
        <section className={Styles.card}>
            <CardsHeader {...props} />
            <div className={`${Styles.data} one-data-value`}>{data}</div>
            <div className={Styles.desc}>{caption}</div>
            <div className={Styles.footer}>
                <div>
                    {footerIcon && (
                        <FontAwesomeIcon icon={footerIcon} className={Styles.footerIcon} />
                    )}
                    {footerText && <small>{footerText}</small>}
                </div>
                {footerData && <div className={Styles.footerData}>{footerData}</div>}
            </div>
            <CardInfo {...props} />
        </section>
    )
}

// Interface
export interface OneDataProps extends CardsHeaderProps {
    data: ReactNode
    caption: ReactNode
    footerIcon: IconProp | undefined
    footerText: ReactNode | undefined
    footerData: ReactNode | undefined
}
