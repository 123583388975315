import DayJS from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18n from '../i18n'

DayJS.extend(timezone)
DayJS.extend(utc)
DayJS.extend(duration)
DayJS.extend(customParseFormat)
DayJS.extend(isSameOrBefore)
DayJS.extend(isSameOrAfter)
DayJS.extend(isYesterday)
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)

export const localeUtcDate = (date: DayJS.Dayjs) => date.utc().locale(i18n.language)

export default DayJS
