import { useQuery } from 'react-query'
import { getParticipation } from '../../apis/api.participation'
import { useTeam } from '../../pages/Team/Hooks/useTeam'

export const useParticipation = (teamNumber: number) => {
    const { courseID } = useTeam()
    const contentKey = [courseID, 'Users', 'Participation', teamNumber]
    const { data: participation } = useQuery(contentKey, () => getParticipation(teamNumber), {
        enabled: !!courseID && !!teamNumber,
        retry: false,
    })
    return { participation }
}
