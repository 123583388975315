import Accordion from '../../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
import AccordionFooter from '../../../../components/DesignSystem/components/molecules/AccordionFooter/AccordionFooter.component'
import Axios from 'axios'
import Config from '../../../../config'
import DayJS from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React, { useEffect, useState } from 'react'
import Styles from './Schedules.module.css'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { BtnCircular } from '../../../../components/Buttons/BtnCircular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isProduction } from '../../../../constants'
import { useGAEvents } from '../../../../hooks/useGAEvents'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTeam } from '../../Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../context/UIContext'

DayJS.extend(utc)
DayJS.extend(weekday)
DayJS().weekday(-1)
DayJS.extend(isBetween)

import {
    getTeamSettings,
    GetTeamSettingsResponse,
    updateTeamSettings,
} from '../../../../apis/api.egg'

export const Schedule = ({
    schedule,
    isOpen = false,
}: {
    schedule?: ScheduleDay[]
    isOpen?: boolean
}) => {
    const queryClient = useQueryClient()
    const { toastError, toastNotification } = useUIContext()
    const { t } = useTranslation('teamSettings')
    const { team, courseID, teamPage } = useTeam()
    const { sendGA } = useGAEvents()

    const [, setShow] = useState(isOpen)
    const [controlledCollapsed, setControlledCollapsed] = useState(false)

    const { mutate, isLoading: isSaving } = useMutation(
        [courseID, 'Team', 'Setting', 'Schedule'],
        (params: Parameters<typeof updateSchedule>) => updateSchedule(...params),
        {
            onMutate: () =>
                sendGA({
                    category: 'Schedule Settings',
                    action: 'TimetableSaved',
                }),
            onError: (error) => toastError(error),
            onSuccess: () => toastNotification(t('Schedules saved correctly.')),
            onSettled: () => {
                queryClient.invalidateQueries([courseID, 'Team', 'Settings', 'Get Settings'])
                queryClient.invalidateQueries([courseID, 'Team', 'Monitor', 'Get Stats'])
            },
        }
    )

    const { mutate: settingsMutate } = useMutation(
        [courseID, 'Team', 'Setting', 'Advanced Settings'],
        (params: Parameters<typeof updateTeamSettings>) => updateTeamSettings(...params),
        {
            onMutate: async (params) => {
                const [, newSetting] = params
                await queryClient.cancelQueries([courseID, 'Team', 'Setting', 'Advanced Settings'])
                const snapshot = queryClient.getQueryData<GetTeamSettingsResponse>(key)
                queryClient.setQueryData<GetTeamSettingsResponse | undefined>(key, (prev) =>
                    prev ? { ...prev, settings: { ...prev.settings, ...newSetting } } : prev
                )
                return snapshot
            },
            onError: (error, params, snapshot) => {
                queryClient.setQueryData(key, snapshot)
                toastError(error)
            },
            onSettled: () => {
                queryClient.invalidateQueries([courseID, 'Team', 'Teams list', teamPage])
            },
        }
    )

    const key = [courseID, 'Team', 'Settings', 'Get Settings']

    const { data: response } = useQuery(key, () => getTeamSettings(`${courseID}`))
    const options = response?.settings

    const updateSetting = (newSettings: Partial<GetTeamSettingsResponse['settings']>) => {
        sendGA({
            category: 'Schedule Settings',
            action: 'ClassOpeningTimeSaved',
        })
        settingsMutate([`${courseID}`, newSettings])
    }

    const [attendancesRegistrationTime, setAttendancesRegistrationTime] = useState(
        options?.attendancesRegistrationTime
    )

    const { weekDaysNames } = useSchedule()
    const [week, setWeek] = useState(schedule || [])

    if (!team || !schedule) return null
    const { hasScheduleConfigured } = team.settings.ableLaunchAlgorithm

    const updateDay = (day: ScheduleDay) =>
        setWeek([...week?.filter(({ startDay }) => startDay !== day.startDay), day])

    const saveSchedule = (newSchedule: ScheduleDay[] | null) => {
        if (isSaving) return
        if (!newSchedule) return setShow(false)
        const sortSchedule = [
            ...newSchedule.sort(({ startDay: a }, { startDay: b }) => (a > b ? 1 : b > a ? -1 : 0)),
        ]
        mutate([`${courseID}`, sortSchedule])
    }

    const scheduleFooter = {
        primaryButtonOnClick: () => {
            saveSchedule(week)
            updateSetting({
                attendancesRegistrationTime,
            })
        },
        primaryButtonText: t('content.save'),
        secondaryButtonOnClick: () => {
            setControlledCollapsed(() => true)
            setWeek(schedule ?? [])
            setAttendancesRegistrationTime(options?.attendancesRegistrationTime)
        },
        secondaryButtonText: t('content.cancel'),
    }

    return (
        <Accordion
            header={{
                title: t('content.schedule.header'),
                headerTitleStyles: {
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: 17,
                    letterSpacing: 0,
                    color: '#003750',
                },
                warning: !hasScheduleConfigured && (
                    <BtnCircular
                        icon={['fas', 'exclamation-triangle']}
                        toolTips={t('teamDashboard:warning-block.tooltip')}
                        style={{
                            border: 0,
                            fontSize: 23,
                            color: '#ff8b00',
                        }}
                    />
                ),
            }}
            footer={AccordionFooter(scheduleFooter)}
            controlledCollapsed={controlledCollapsed}
            setControlledCollapsed={setControlledCollapsed}
        >
            <section className={Styles.container}>
                <div className={Styles.content}>
                    <p className="settings-inside-title">{t('content.schedule.title')}</p>
                    <p className="settings-inside-subtitle">{t('content.schedule.desc')}</p>
                    {weekDaysNames.map((dayName, index) => {
                        const dayIndex = index === 6 ? 0 : index + 1 // Sunday startDay = 0
                        const data: ScheduleDay | undefined = week?.find(
                            ({ startDay }) => startDay === dayIndex
                        )
                        if (data) data.label = dayName

                        return (
                            <Day
                                startDay={dayIndex}
                                data={data}
                                label={dayName}
                                key={`${dayIndex} ${dayName}`}
                                updateDay={updateDay}
                            />
                        )
                    })}
                </div>
                <ToleranceMargins
                    attendancesRegistrationTime={attendancesRegistrationTime}
                    setAttendancesRegistrationTime={setAttendancesRegistrationTime}
                />
            </section>
        </Accordion>
    )
}

const Day = ({
    startDay,
    data,
    label,
    updateDay,
}: {
    startDay: number
    data?: ScheduleDay
    label: string
    updateDay: (data: ScheduleDay) => void
}) => {
    const { newUTCRange } = useSchedule()
    const [day, setDay] = useState(data)

    useEffect(() => {
        day && updateDay(day)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day])

    const schedLength = day?.schedules.length || 0

    const updateRange = (
        dayNumber: number,
        newRange?: ScheduleRange,
        action?: 'add' | 'delete'
    ) => {
        if (!day) return

        const newDaySchedules: ScheduleRange[] = [...day.schedules]
        const add = action === 'add' ? 0 : 1
        newRange
            ? newDaySchedules.splice(dayNumber, add, newRange)
            : newDaySchedules.splice(dayNumber, 1)

        setDay(
            (prev) =>
                prev && {
                    ...prev,
                    schedules: [...newDaySchedules],
                }
        )
    }

    const handleToggleEnabled = () => {
        if (!day || !day.schedules.length) {
            // Add initial day schedule
            const newRange = newUTCRange({ day: startDay, hour: 9, minutes: 0 }, '', 0, 180)
            const initialDaySchedule: ScheduleDay = {
                active: true,
                startDay: startDay,
                schedules: [newRange],
            }
            setDay(initialDaySchedule)
        }
        // Toggle enable/disable
        else setDay((prev) => prev && { ...prev, active: !prev.active })
    }

    return (
        <div className={Styles.itemDay}>
            <header>
                <b>{label}</b>
                <button onClick={handleToggleEnabled}>
                    {day?.active ? (
                        <FontAwesomeIcon
                            className="icon checked"
                            style={{ fontSize: 36, color: '#00A3FF' }}
                            icon={['far', 'toggle-large-on']}
                        />
                    ) : (
                        <FontAwesomeIcon
                            className="icon unchecked"
                            style={{ fontSize: 36, transform: 'rotate(180deg)', color: '#003750' }}
                            icon={['far', 'toggle-large-on']}
                        />
                    )}
                </button>
            </header>

            {day?.active &&
                day?.schedules.map((item, index) => (
                    <Range
                        key={`${index} ${label} ${item.label}}`}
                        rangeIndex={index}
                        dayIndex={startDay}
                        data={item}
                        updateRange={updateRange}
                        canAdd={schedLength < 5}
                        canRemove={schedLength > 1}
                        isActive={!!day.active}
                        disabled={!day.active}
                    />
                ))}
        </div>
    )
}

const Range = ({
    rangeIndex,
    dayIndex,
    data,
    updateRange,
    isActive,
    canAdd,
    canRemove,
}: {
    rangeIndex: number
    dayIndex: number
    data: NewScheduleRange | ScheduleRange
    updateRange: (index: number, scheduleItem?: ScheduleRange, action?: 'add' | 'delete') => void
    disabled: boolean
    isActive: boolean
    canAdd: boolean
    canRemove: boolean
}) => {
    const { t } = useTranslation('teamSettings')
    const { sendGA } = useGAEvents()

    const {
        dayOfWeek,
        rangeUTCtoLocal,
        timeToDateUTC,
        timeToDateLOCAL,
        timeToMilitary,
        timeToInput,
        newUTCRange,
    } = useSchedule()

    const [range, setRange] = useState(rangeUTCtoLocal(data))
    const [warning, setWarning] = useState<string | null>(null)

    /** Validations & Utilities */
    const endBeforeStart = timeToMilitary(range.end) <= timeToMilitary(range.start)
    const enableAddBtn = isActive && canAdd && !endBeforeStart
    const enableRemoveBtn = isActive && canRemove
    const startDayName = dayOfWeek(range.start.day)
    const endDayName = dayOfWeek(range.start.day + (endBeforeStart ? 1 : 0))

    const isRunningNow =
        isProduction &&
        !('newRange' in data) &&
        DayJS.utc().isBetween(timeToDateUTC(data.start).local(), timeToDateUTC(data.end).local())

    /** Set range warning */
    useEffect(() => {
        if (endBeforeStart)
            setWarning(
                t('content.schedule.endNextDay', {
                    startDayName,
                    endDayName,
                })
            )
        else setWarning(null)
        if (isRunningNow) setWarning(t('content.schedule.isRunningNow'))
    }, [endDayName, endBeforeStart, isRunningNow, startDayName, t])

    const handleRangeChange = () => {
        const startDate = timeToDateLOCAL(range.start).set('day', dayIndex).utc()
        let endDate = timeToDateLOCAL(range.end).set('day', dayIndex).utc()

        if (endBeforeStart) endDate = endDate.clone().add(1, 'day')

        const start = {
            ...range.start,
            day: startDate.utc().day(),
            hour: startDate.hour(),
            minutes: startDate.minute(),
        }

        const end = {
            ...range.end,
            day: endDate.utc().day(),
            hour: endDate.utc().hour(),
            minutes: endDate.utc().minute(),
        }

        updateRange(rangeIndex, { ...range, start, end })
    }

    const handleAddRange = () => {
        sendGA({
            category: 'Schedule Settings',
            action: 'MultipleTimetableClicked',
        })
        updateRange(rangeIndex + 1, newUTCRange(range.end, ''), 'add')
    }

    return (
        <>
            <div className={`${Styles.range} ${isActive ? 'active' : 'disabled'}`}>
                <div>
                    <input
                        type="text"
                        name="label"
                        title={warning ?? ''}
                        placeholder={t('content.schedule.label-placeholder')}
                        value={range.label}
                        onChange={({ target: { value } }) =>
                            setRange((prev) => prev && { ...prev, label: value })
                        }
                        onBlur={handleRangeChange}
                        disabled={!isActive || isRunningNow}
                    />
                </div>

                <div className={Styles.rangeTimes}>
                    <span className={Styles.timeFrom}>{t('content.schedule.from')}</span>

                    <input
                        type="time"
                        name="start"
                        title={warning ?? ''}
                        value={timeToInput(range.start)}
                        onChange={({ target: { value } }) =>
                            setRange((prev) => ({
                                ...prev,
                                start: {
                                    ...prev.start,
                                    hour: +value.split(':')[0] || 0,
                                    minutes: +value.split(':')[1] || 0,
                                },
                            }))
                        }
                        onBlur={handleRangeChange}
                        disabled={!isActive || isRunningNow}
                        required
                    />

                    <span className={Styles.timeTo}>{t('content.schedule.to')}</span>

                    <input
                        type="time"
                        name="end"
                        title={warning ?? ''}
                        value={timeToInput(range.end)}
                        onChange={({ target: { value } }) =>
                            setRange((prev) => ({
                                ...prev,
                                end: {
                                    ...prev.end,
                                    hour: +value.split(':')[0] || 0,
                                    minutes: +value.split(':')[1] || 0,
                                },
                            }))
                        }
                        onBlur={handleRangeChange}
                        disabled={!isActive || isRunningNow}
                        required
                    />
                </div>

                <div className={Styles.rangeButtons}>
                    <div>
                        {enableRemoveBtn && !isRunningNow && (
                            <FontAwesomeIcon
                                onClick={() => isActive && updateRange(rangeIndex)}
                                tabIndex={10}
                                icon={['far', 'minus-circle']}
                                className={Styles.btnRemoveNewSchedule}
                                style={{ cursor: isActive ? 'pointer' : 'not-allowed' }}
                                title={warning ?? ''}
                            />
                        )}
                    </div>
                    <div>
                        {enableAddBtn && (
                            <button onClick={handleAddRange} title={warning ?? ''}>
                                <FontAwesomeIcon
                                    icon={['far', 'plus-circle']}
                                    className={Styles.btnAddNewSchedule}
                                    style={{ cursor: isActive ? 'pointer' : 'not-allowed' }}
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {warning && <p className={Styles.startNextDay}>{warning}</p>}
        </>
    )
}

const useSchedule = () => {
    const { t } = useTranslation('teamSettings')

    /** Week days names */
    const weekDaysNames = [
        t('content.days.monday'),
        t('content.days.tuesday'),
        t('content.days.wednesday'),
        t('content.days.thursday'),
        t('content.days.friday'),
        t('content.days.saturday'),
        t('content.days.sunday'),
    ]

    /** Get name of week from 1 to 7 */
    const dayOfWeek = (dayNumber: number) =>
        weekDaysNames[dayNumber === 0 ? 6 : dayNumber - 1] ?? `${dayNumber}`

    /** Convert a time range from UTC to Local */
    const rangeUTCtoLocal = (range: ScheduleRange): ScheduleRange => {
        const { start, end } = range
        const startDate = timeToDateUTC(start).local()
        const endDate = timeToDateUTC(end).local()

        return {
            ...range,
            start: {
                day: startDate.day(),
                hour: startDate.hour(),
                minutes: startDate.minute(),
            },
            end: {
                day: endDate.day(),
                hour: endDate.hour(),
                minutes: endDate.minute(),
            },
        }
    }

    /** Convert a time to UTC Date */
    const timeToDateUTC = (time: ScheduleTime): DayJS.Dayjs =>
        DayJS.utc().day(time.day).hour(time.hour).minute(time.minutes)

    /** Convert a time to LOCAL Date */
    const timeToDateLOCAL = (time: ScheduleTime): DayJS.Dayjs =>
        DayJS().local().day(time.day).hour(time.hour).minute(time.minutes)

    /** Convert a time to input format (HH:MM) */
    const timeToInput = (time: ScheduleTime): string =>
        `${String(time.hour).padStart(2, '0')}:${String(time.minutes).padStart(2, '0')}`

    /** Return a new range from start time */
    const newUTCRange = (
        from: ScheduleTime,
        label = '',
        delay = 60,
        duration = 120
    ): NewScheduleRange => {
        const fromDate = timeToDateLOCAL(from).add(delay, 'minutes')
        const toDate = fromDate.clone().add(duration, 'minutes')

        return {
            label,
            start: {
                day: fromDate.utc().day(),
                hour: fromDate.utc().hour(),
                minutes: fromDate.utc().minute(),
            },
            end: {
                day: toDate.utc().day(),
                hour: toDate.utc().hour(),
                minutes: toDate.utc().minute(),
            },
            newRange: true,
        }
    }

    /** Convert a time to military format without day (1200) */
    const timeToMilitary = (time: ScheduleTime): number =>
        +`${String(time.hour).padStart(2, '0')}${String(time.minutes).padStart(2, '0')}`

    return {
        weekDaysNames,
        dayOfWeek,
        rangeUTCtoLocal,
        timeToDateUTC,
        timeToDateLOCAL,
        timeToMilitary,
        timeToInput,
        newUTCRange,
    }
}

// Interfaces

type ScheduleDay = GetTeamSettingsResponse['schedules'][0]
type ScheduleRange = ScheduleDay['schedules'][0]
type NewScheduleRange = ScheduleRange & { newRange: boolean }
// interface NewScheduleRange extends ScheduleRange {
//     isNew?: boolean
// }
type ScheduleTime = ScheduleRange['start']

interface PatchScheduleResponse {
    data: ScheduleDay[]
    message: string
}

interface IAttendancesRegistrationTime {
    attendancesStartTime: number
    attendancesEndTime: number
    attendanceDelayTime: number
}

const updateSchedule = (courseID: string, schedules: ScheduleDay[]) =>
    Axios.put<PatchScheduleResponse>(`${Config.API}/v3/course/${courseID}/schedules`, {
        schedules,
    }).then(({ data }) => data)

const ToleranceMargins = ({
    attendancesRegistrationTime = {
        attendancesStartTime: 0,
        attendancesEndTime: 0,
        attendanceDelayTime: 0,
    },
    setAttendancesRegistrationTime,
}: {
    attendancesRegistrationTime: IAttendancesRegistrationTime | undefined
    setAttendancesRegistrationTime: React.Dispatch<
        React.SetStateAction<IAttendancesRegistrationTime | undefined>
    >
}) => {
    const { t } = useTranslation(['teamSettings'])
    return (
        <div className="schedule-tolerance">
            <div className="tolerance-margin">
                <div className="tolerance-margin-header">
                    <div className="settings-inside-title">
                        {t('content.advanced-config.class-start.title')}
                    </div>
                    <div className="settings-inside-subtitle">
                        {t('content.advanced-config.class-start.subtitle')}
                    </div>
                </div>
                {/*rango presente antes*/}
                <ToleanceBlock
                    value={attendancesRegistrationTime.attendancesStartTime}
                    suffix={t('content.advanced-config.attendace-tolerance-delay.sufix')}
                    onChange={(event) =>
                        setAttendancesRegistrationTime({
                            ...attendancesRegistrationTime,
                            attendancesStartTime: +event.target.value,
                        })
                    }
                />
            </div>

            <div className="tolerance-margin">
                <div className="tolerance-margin-header">
                    <div className="settings-inside-title">
                        {t('content.advanced-config.tolerance-margins.title')}
                    </div>
                    <div className="settings-inside-subtitle">
                        {t('content.advanced-config.tolerance-margins.subtitle')}
                    </div>
                </div>
                <div className="tolerance-margin-fields">
                    {/*rango presente después*/}
                    <ToleanceBlock
                        value={attendancesRegistrationTime.attendancesEndTime}
                        suffix={t('content.advanced-config.attendace-tolerance-after.sufix')}
                        onChange={(event) =>
                            setAttendancesRegistrationTime({
                                ...attendancesRegistrationTime,
                                attendancesEndTime: +event.target.value,
                            })
                        }
                    />

                    {/*rango tardanza tiempo después del end time para dar tardanza, finalizado este tiempo va ausente*/}
                    <ToleanceBlock
                        value={attendancesRegistrationTime.attendanceDelayTime}
                        suffix={t('content.advanced-config.attendace-tolerance-delay.sufix')}
                        onChange={(event) =>
                            setAttendancesRegistrationTime({
                                ...attendancesRegistrationTime,
                                attendanceDelayTime: +event.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}

const ToleanceBlock = ({
    onChange,
    value,
    suffix,
    label,
}: {
    suffix: string
    onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
    value: number
    label?: string
}) => {
    return (
        <div className="tolerance-block-input">
            {label && <label htmlFor="tolerance-input">{label}</label>}
            <div className="tolerance-input">
                <input
                    onChange={onChange}
                    required
                    type="number"
                    name="tolerance-input"
                    min={0}
                    max={720}
                    value={value}
                />
                <div className="tolerance-sufix">{suffix}</div>
            </div>
        </div>
    )
}
