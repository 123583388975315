export const colors = {
    borders: '#EEEEEE',
    danger: '#ff6673',
    darkGreyEgg: '#073045',
    darkGreyEggHover: '#05202e',
    eggColor: '#FFCD00',
    lightGreyBg: '#F8F8F8',
    mediumGreyBg: '#D2D2D2',
    paragraphColor: '#37474F',
    primaryColor: '#296efa',
    primaryColorAlt: '#427CA6',
    primaryColorHover: '#105df9',
    active: '#00A3FF',
    salmon: '#FF647C',
    white: '#ffffff',
    black: '#000000',
    separadorLineas: '#EAEAEA',

    // Reports Colors
    positiveGreen: '#15C0AF',
    baseGrey: '#383A40',
    softGrey: '#D8DADB',
    // lightBlue: '#00A3FF', active
    accentPurple: '#BF03E2',
    // red: '#FF647C', salmon
    // yellow: '#ffcd00', eggColor
    baseBlue: '#1A4B62',
    eggBlue: '#003750',
}
