// API
import React from 'react'
// Componentes
import InfoBlock from '../components/InfoBlockTopPage'

import styled from 'styled-components'
import NavBarDesktop from './NavBar/NavBarDesktop'
import { NavBarMobile } from './NavBar/NavBarMobile'
import { SentryFeedbackButton } from '../components/Buttons/SentryFeedbackButton'

export interface LinkItem {
    active?: boolean
    url: string
    name: string
}

export interface Props {
    children: React.ReactNode
    ruta?: string
    links?: LinkItem[]
    page?: string
    bgColor?: string
}
const MainLayout = ({ children, page, bgColor }: Props) => {
    return (
        <StyledMainLayout bgColor={bgColor}>
            {/* Error / success message top page */}
            <InfoBlock />
            <NavBarDesktop />
            <NavBarMobile />
            <main className={`main-container ${page === 'dashboard' ? 'full-width' : ''}`}>
                {children}
            </main>
            <SentryFeedbackButton />
        </StyledMainLayout>
    )
}

export default MainLayout

const StyledMainLayout = styled.div<{ bgColor?: string }>`
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#f8f8f8')};
`
