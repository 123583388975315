import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BtnCircular, BtnCircularProps } from '../../../../../components/Buttons/BtnCircular'
import { colors } from '../../../../../styles/globals/_variables'
import { useTeam } from '../../../Context/TeamContext'
import { StudentItemProps } from '../StudentItem'
import { RearrangeModal } from './MoreOptionsModals/RearrangeModal'
import { DeleteUserModal } from './MoreOptionsModals/DeleteUserModal'
import { ContentModal } from './MoreOptionsModals/ContentModal'

export const MoreOptions = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const { _profileId, cannotUnsubscribe, algorithmType } = props
    const { studentItemMoreOptionState } = useTeam()
    const [state, setState] = studentItemMoreOptionState
    const [currentModal, setCurrentModal] = useState<Modals | undefined>()

    const isOptionsOpen = state === _profileId
    const handleClick = () => {
        if (!isOptionsOpen || null) setState(_profileId || null)
        else setState(null)
    }

    const selectModalOption = (modal: Modals) => () => setCurrentModal(modal)
    const closeModal = () => setCurrentModal(undefined)
    const modalProps: ModalProps = { props, closeModal, isOpen: true }

    const isHCA4 = algorithmType === 'hca4'

    const options: BtnCircularProps['options'] = [
        {
            label: t('user-options.rearrange'),
            icon: 'people-arrows',
            onClick: selectModalOption(Modals.REARRANGE),
        },
        {
            label: t('user-options.content-label'),
            icon: 'folder',
            onClick: selectModalOption(Modals.CONTENT),
            unavailable: !isHCA4,
        },
        {
            label: t('user-options.delete'),
            icon: 'trash-alt',
            onClick: selectModalOption(Modals.DELETE),
            props: {
                style: { color: colors.danger, fontWeight: 'bold' },
            },
            unavailable: cannotUnsubscribe,
        },
    ]

    return (
        <div className="moreOptions">
            <BtnCircular
                icon="ellipsis-h"
                style={{
                    marginLeft: '0.5em',
                    width: 32,
                    height: 32,
                    fontSize: 15,
                    borderColor: colors.mediumGreyBg,
                    background: 'white',
                }}
                isOpen={isOptionsOpen}
                onClick={() => handleClick()}
                options={options}
            />

            {currentModal === Modals.REARRANGE && <RearrangeModal {...modalProps} />}
            {currentModal === Modals.CONTENT && <ContentModal {...modalProps} />}
            {currentModal === Modals.DELETE && <DeleteUserModal {...modalProps} />}
        </div>
    )
}

export type ModalProps = {
    props: StudentItemProps
    isOpen: boolean
    closeModal: () => void
}

export enum Modals {
    REARRANGE,
    CONTENT,
    DELETE,
}
