import { useTranslation } from 'react-i18next'
import DayJS from '../../../../../helpers/DayJS'
import Styles from './LastRotation.module.css'

export const LastRotation = ({ lastRotation }: { lastRotation?: string }) => {
    const { t } = useTranslation('teamMonitor')

    const lastRotationDayJS = DayJS(lastRotation)
    const lastRotationAgo = lastRotationDayJS.isToday()
        ? '0'
        : lastRotationDayJS.isYesterday()
        ? '1'
        : lastRotationDayJS.fromNow(true)

    const lastRotationDate = lastRotationDayJS.local().format('L')
    const lastRotationTime = lastRotationDayJS.local().format('LT')

    return (
        <div className={Styles.container}>
            <div className={Styles.infoText}>
                {lastRotation ? (
                    <>
                        {t('Last rotation n day ago, on DD at TT', {
                            count: Number(lastRotationAgo),
                            lastRotationAgo,
                            lastRotationDate,
                            lastRotationTime,
                        })}
                        .
                    </>
                ) : (
                    `${t("There hasn't executed a rotation yet")}.`
                )}
            </div>
        </div>
    )
}
