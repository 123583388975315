import { useEffect } from 'react'
import { setNewAccessToken } from '../../apis/apis'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import WarningBlock from '../../components/WarningBlock'
import { useUser } from '../../context/UserContext'
import { useCourses } from '../../hooks/ApiHooks/useCourses'
import FullLayout from '../../layouts/FullLayout'
import { MeetButton } from './Components/MeetButton'
import { LiveMonitor } from './Components/Monitor/LiveMonitor'
import { ManageMeetingButton } from './Components/Overview/ManageMeeting/ManageMeeting.button'
import { SpreadMessageButton } from './Components/Overview/SpreadMessage/SpreadMessageButton'
import { useTeam } from './Context/TeamContext'

export const Overview = () => {
    const { team, courseID, mainLinks } = useTeam()
    const { getCourseData } = useCourses()
    const { setToken } = useUser()

    useEffect(() => {
        if (!courseID) return

        sessionStorage.setItem('last-courseId', courseID)

        const course = getCourseData(courseID)
        if (!course) return

        setNewAccessToken(course['access-token'])
        setToken(course['access-token'])
    }, [courseID, getCourseData, setToken])

    if (!team) return null

    return (
        <FullLayout links={mainLinks}>
            <WarningBlock />
            <div
                className="team-detail-container"
                style={{
                    backgroundColor: '#F8F8F8',
                    minHeight: 'inherit',
                }}
            >
                <div className="container overview">
                    <HeaderBanner
                        actions={
                            <>
                                <SpreadMessageButton />
                                <ManageMeetingButton />
                                <MeetButton />
                            </>
                        }
                        title={team?.teamName}
                    />
                    <LiveMonitor />
                </div>
            </div>
        </FullLayout>
    )
}
