import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PresentStatus } from '../../../../../apis/api.present'
import { BtnCircular } from '../../../../../components/Buttons/BtnCircular'
import { usePresent } from '../../../../../hooks/ApiHooks/usePresent'
import { useTeam } from '../../../Context/TeamContext'
import { StudentItemProps } from '../StudentItem'
import styles from './Attendance.module.css'

export const Attendance = ({ _profileId, attendance, team }: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const { courseID } = useTeam()
    const { mutatePresent } = usePresent({ profileId: _profileId, teamNumber: team?.number || 0 })
    const [status, setStatus] = useState(attendance?.status)

    const changeAttendance = (status: PresentStatus) => {
        if (!_profileId || !status) return
        setStatus(status)
        mutatePresent({
            courseId: `${courseID}`,
            profileId: _profileId,
            status,
        })
    }
    useEffect(() => {
        setStatus(attendance?.status)
    }, [attendance?.status])

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <BtnCircular
                    icon={['fal', 'user-check']}
                    iconOver={['fas', 'user-check']}
                    isActive={status === 'present'}
                    toolTips={t('actions.attendance.setAttendance')}
                    onClick={() => changeAttendance('present')}
                    disabled={status === 'present'}
                    className={`${styles.actionButton} ${styles.present}`}
                />
                <BtnCircular
                    icon={['fal', 'user-slash']}
                    iconOver={['fas', 'user-slash']}
                    isActive={status === 'absent'}
                    toolTips={t('actions.attendance.setAsAbsent')}
                    onClick={() => changeAttendance('absent')}
                    disabled={status === 'absent'}
                    className={`${styles.actionButton} ${styles.absent}`}
                />
            </div>
        </div>
    )
}
