import React from 'react'
import { useTranslation } from 'react-i18next'
import { AreaChart, ChartInteractionInfo } from '../../../../../../components/GoogleChart/AreaChart'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'

export const AttendanceAreaCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { arrivalUsers: data } = useLiveMonitor()
    if (!data) return null

    const rowValues = data.slice(1).map((row) => row[0]) as number[]

    return (
        <AreaChart
            {...{
                chartData: data,
                title: t('Attendees per minute'),
                subtitle: t('People who entered the room (from -10min)'),
                info: (
                    <ChartInteractionInfo
                        cardDescription={t(
                            'This graph shows the number of attendees per minute during the video call. The x-axis represents time in minutes, while the y-axis displays the cumulative number of people.'
                        )}
                    />
                ),
                rowValues,
            }}
        />
    )
}
