import { Countdown } from '../../Countdown'
import DayJS from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styles from './TimelineEvents.module.css'
import { useQueryClient } from 'react-query'
import { useTeam } from '../../../Hooks/useTeam'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'

export const TimelineEvents = () => (
    <div className={Styles.events}>
        <CurrentEvent />
        <NextEvent />
    </div>
)

const CurrentEvent = () => {
    const { t } = useTranslation('teamMonitor')
    const { currentEvent } = useTimeline()

    if (!currentEvent) return null

    const { room } = currentEvent
    const roomName = room ? t(room) : null

    return (
        <section className={`${Styles.section} ${Styles.currentEvent}`}>
            <FontAwesomeIcon icon={['fas', 'location-dot']} />
            {roomName
                ? t('There is an active {{ roomName }} meeting.', { roomName })
                : t('All meetings have ended.')}
        </section>
    )
}

const NextEvent = () => {
    const { t } = useTranslation('teamMonitor')
    const { nextEvent } = useTimeline()
    const { courseID } = useTeam()
    const queryClient = useQueryClient()

    if (!nextEvent) return null

    const { room, date } = nextEvent
    const roomName = room ? t(room) : null

    return (
        <section className={`${Styles.section} ${Styles.nextEvent}`}>
            <FontAwesomeIcon icon={['fas', 'circle-arrow-right']} />
            <p>
                {roomName
                    ? t('The participants will be sent to {{ roomName }} in', { roomName })
                    : t('The meetings will end in')}{' '}
                <Countdown
                    key={`${room}-${date}`}
                    duration={DayJS(date).diff(DayJS())}
                    onFinish={() => queryClient.invalidateQueries(['Team', 'Timeline', courseID])}
                />
            </p>
        </section>
    )
}
