import React, { useCallback, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import UIContext from '../../../../context/UIContext'

import { SubjectContext } from '../../../../context/SubjectContext'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../../context/UserContext'
import { ROLES } from '../../../../constants'
import { CourseOptions } from '../../../../hooks/ApiHooks/useCourse'
import ToggleBtn from '../../../../components/Buttons/Toggle'
import Config from '../../../../config'
import { errorHandler } from '../../../../helpers/errorHandler'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

const AdvanceConfiguration = () => {
    const { role } = useContext(UserContext)
    const [show, toggle] = React.useReducer((old: boolean) => !old, role === ROLES.ADMIN)
    const { t } = useTranslation(['teamSettings'])
    const { team } = useTeam()
    const { toastNotification } = useContext(UIContext)
    const { course, options, setOptions, subject, hq } = useContext(SubjectContext)

    const sendInitialTable = useCallback(
        async (initial: number) => {
            try {
                ReactGA.event({
                    category: 'Team Settings',
                    action: 'InitialTeamSaved',
                    label: initial.toString(),
                    dimension1: 'platform',
                    dimension2: hq?.name.toLowerCase(),
                    dimension3: course?.name.toLowerCase(),
                    dimension4: subject?.name.toLowerCase(),
                    dimension7: role,
                    dimension9: team?.algorithm
                        ? team?.algorithm?.algorithmType
                        : 'no algorithm available',
                })
                await setOptions?.(CourseOptions.InitialTable, initial)
                if (!course?.id) throw Error('No se encontró el ID del curso')
                toastNotification(t('content.advanced-config.success'))
            } catch (error) {
                const message = errorHandler(error, '¡Ups! Ocurrió un error. Intenta nuevamente.')
                toastNotification(message, 'error')
            }
        },
        [
            hq?.name,
            course?.name,
            course?.id,
            subject?.name,
            role,
            team?.algorithm,
            setOptions,
            toastNotification,
            t,
        ]
    )

    const sendTimeLapse = useCallback(
        (key: 'attendancesStartTime' | 'attendancesEndTime' | 'attendanceDelayTime') => async (
            range: number
        ) => {
            if (!options) return
            try {
                switch (key) {
                    case 'attendancesStartTime':
                        ReactGA.event({
                            category: 'Schedule Settings',
                            action: 'ClassOpeningTimeSaved',
                            dimension1: 'platform',
                            dimension2: hq?.name.toLowerCase(),
                            dimension3: course?.name.toLowerCase(),
                            dimension4: subject?.name.toLowerCase(),
                            dimension7: role,
                            dimension9: team?.algorithm
                                ? team?.algorithm?.algorithmType
                                : 'no algorithm available',
                        })
                        break
                    case 'attendancesEndTime':
                        ReactGA.event({
                            category: 'Schedule Settings',
                            action: 'TolerancePresentSaved',
                            dimension1: 'platform',
                            dimension2: hq?.name.toLowerCase(),
                            dimension3: course?.name.toLowerCase(),
                            dimension4: subject?.name.toLowerCase(),
                            dimension7: role,
                            dimension9: team?.algorithm
                                ? team?.algorithm?.algorithmType
                                : 'no algorithm available',
                        })
                        break
                    case 'attendanceDelayTime':
                        ReactGA.event({
                            category: 'Schedule Settings',
                            action: 'ToleranceTardinessSaved',
                            dimension1: 'platform',
                            dimension2: hq?.name.toLowerCase(),
                            dimension3: course?.name.toLowerCase(),
                            dimension4: subject?.name.toLowerCase(),
                            dimension7: role,
                            dimension9: team?.algorithm
                                ? team?.algorithm?.algorithmType
                                : 'no algorithm available',
                        })

                        break
                }
                await setOptions?.(CourseOptions.AttendanceInterval, {
                    ...options[CourseOptions.AttendanceInterval],
                    [key]: range,
                })
                if (!course?.id) throw Error('No se encontró el ID del curso')
                toastNotification(t('content.advanced-config.success'))
            } catch (error) {
                const message = errorHandler(error, '¡Ups! Ocurrió un error. Intenta nuevamente.')
                toastNotification(message, 'error')
            }
        },
        [
            options,
            setOptions,
            course?.id,
            course?.name,
            toastNotification,
            t,
            hq?.name,
            subject?.name,
            role,
            team?.algorithm,
        ]
    )

    return (
        <div className="advance-configuration">
            <div className="header">
                <h3 className="block-title">{t('content.advanced-config.title')}</h3>
                <ToggleBtn onClick={toggle} on={show} />
                {/* <button className="toggle-btn" onClick={toggle}>
                {show ?
                    <FontAwesomeIcon className='box checked' icon={['fas', 'toggle-on']} /> :
                    <FontAwesomeIcon className='box unchecked' icon={['fal', 'toggle-off']} />}
            </button> */}
            </div>

            {show && (
                <>
                    {/*tabla inicial*/}
                    <NumberField
                        min={0}
                        className="section initial-table"
                        defaultValue={options?.[CourseOptions.InitialTable] ?? 1}
                        title={t('content.advanced-config.initial-table')}
                        label={t('content.advanced-config.initial-table-label')}
                        prefix={t('content.advanced-config.table')}
                        buttonLabel={t('content.advanced-config.save')}
                        onSubmit={sendInitialTable}
                    />
                    {/*rango presente antes*/}
                    <NumberField
                        min={0}
                        max={720}
                        className="section initial-table"
                        defaultValue={
                            options?.[CourseOptions.AttendanceInterval]?.attendancesStartTime ?? 15
                        }
                        title={t('content.advanced-config.attendace-tolerance-before.title')}
                        label={t('content.advanced-config.attendace-tolerance-before.label')}
                        suffix={t('content.advanced-config.attendace-tolerance-before.sufix')}
                        buttonLabel={t('content.advanced-config.save')}
                        onSubmit={sendTimeLapse('attendancesStartTime')}
                    />
                    {/*rango presente despues*/}
                    <NumberField
                        min={0}
                        max={720}
                        className="section initial-table"
                        defaultValue={
                            options?.[CourseOptions.AttendanceInterval]?.attendancesEndTime ?? 15
                        }
                        title={t('content.advanced-config.attendace-tolerance-after.title')}
                        label={t('content.advanced-config.attendace-tolerance-after.label')}
                        suffix={t('content.advanced-config.attendace-tolerance-after.sufix')}
                        buttonLabel={t('content.advanced-config.save')}
                        onSubmit={sendTimeLapse('attendancesEndTime')}
                    />
                    {/*rango tardanza tiempo despues del end time para dar tardanza, finalizado este tiempo va ausente*/}
                    <NumberField
                        min={0}
                        max={720}
                        className="section initial-table"
                        defaultValue={
                            options?.[CourseOptions.AttendanceInterval]?.attendanceDelayTime ?? 15
                        }
                        title={t('content.advanced-config.attendace-tolerance-delay.title')}
                        label={t('content.advanced-config.attendace-tolerance-delay.label')}
                        suffix={t('content.advanced-config.attendace-tolerance-delay.sufix')}
                        buttonLabel={t('content.advanced-config.save')}
                        onSubmit={sendTimeLapse('attendanceDelayTime')}
                    />
                    {/* reasignar entre presentes*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.ReassignPresents]}
                        onChange={(value) => setOptions?.(CourseOptions.ReassignPresents, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.reassign-presents.title')}
                        label={t('content.advanced-config.reassign-presents.label')}
                        gaEvent={() => {
                            ReactGA.event({
                                category: 'Team Settings',
                                action: 'AssignTeamBetweenPresent',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }}
                    />
                    {/*reasignar mesas manualmente*/}
                    <CheckBoxField
                        className="section reassign"
                        checked={options?.[CourseOptions.CanReassign]}
                        onChange={(value) => setOptions?.(CourseOptions.CanReassign, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.reassign-tables.title')}
                        label={t('content.advanced-config.reassign-tables.label')}
                        gaEvent={() => {
                            ReactGA.event({
                                category: 'Team Settings',
                                action: 'AssignedTeamsManualChange',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }}
                    />
                    {/*voto obligatorio*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.MandatoryVote]}
                        onChange={(value) => setOptions?.(CourseOptions.MandatoryVote, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.mandatory-recognition.title')}
                        label={t('content.advanced-config.mandatory-recognition.label')}
                    />
                    {/*seccion de contenido*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.CanContent]}
                        onChange={(value) => setOptions?.(CourseOptions.CanContent, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.content-section.title')}
                        label={t('content.advanced-config.content-section.label')}
                        gaEvent={() => {
                            ReactGA.event({
                                category: 'Team Settings',
                                action: 'ContentSection',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }}
                    />

                    {/*mostrar estadísticas*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.DisplayStats]}
                        onChange={(value) => setOptions?.(CourseOptions.DisplayStats, value)}
                        disabled={role === ROLES.STUDENT || !Config.API_CONTENT}
                        title={`${t('content.advanced-config.enable-stats.title')}   ${
                            !Config.API_CONTENT
                                ? t('content.advanced-config.enable-stats.no-url')
                                : ''
                        }`}
                        label={t('content.advanced-config.enable-stats.label')}
                    />
                    {/*enables / disables present / virtual attendance*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.IsVirtual]}
                        onChange={(value) => setOptions?.(CourseOptions.IsVirtual, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.attendance-type.title')}
                        label={t('content.advanced-config.attendance-type.label')}
                    />
                    {/*enables / disables asking students for personal information after signup */}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.MandatoryDataFill]}
                        onChange={(value) => setOptions?.(CourseOptions.MandatoryDataFill, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.mandatory-user-info.title')}
                        label={t('content.advanced-config.mandatory-user-info.label')}
                    />
                    {/*enables / disables meeting button when student is unassigned. Acceso previo al encuentro virtual*/}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.EnableMeetingUnassigned]}
                        onChange={(value) =>
                            setOptions?.(CourseOptions.EnableMeetingUnassigned, value)
                        }
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.enable-meeting-unassigned.title')}
                        label={t('content.advanced-config.enable-meeting-unassigned.label')}
                        gaEvent={() => {
                            ReactGA.event({
                                category: 'Team Settings',
                                action: 'AccessPriorVirtualMeeting',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }}
                    />
                    {/*enables / disables team */}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.IsVisible]}
                        onChange={(value) => setOptions?.(CourseOptions.IsVisible, value)}
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.allow-team.title')}
                        label={t('content.advanced-config.allow-team.label')}
                        gaEvent={() => {
                            ReactGA.event({
                                category: 'Team Settings',
                                action: 'TeamDeactivation',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }}
                    />

                    {/*enables / disables term and conditions */}
                    <CheckBoxField
                        className="section"
                        checked={options?.[CourseOptions.AskForTermsAndConditions]}
                        onChange={(value) =>
                            setOptions?.(CourseOptions.AskForTermsAndConditions, value)
                        }
                        disabled={role === ROLES.STUDENT}
                        title={t('content.advanced-config.ask-for-terms-and-conditions.title')}
                        label={t('content.advanced-config.ask-for-terms-and-conditions.label')}
                        description={t(
                            'content.advanced-config.ask-for-terms-and-conditions.description'
                        )}
                    />
                </>
            )}
        </div>
    )
}

interface NumberFieldProps {
    className: string
    title?: string
    label?: string
    description?: string
    prefix?: string
    suffix?: string
    buttonLabel?: string
    onSubmit?: (value: number) => unknown
    defaultValue?: number
    max?: number
    min?: number
}
const NumberField = (props: NumberFieldProps) => {
    const {
        className,
        title,
        label,
        description,
        defaultValue,
        onSubmit,
        prefix,
        suffix,
        buttonLabel,
        max,
        min,
    } = props

    const { handleSubmit, formState, register, setValue, trigger } = useForm<{ value: string }>({
        mode: 'onChange',
    })

    useEffect(() => {
        if (defaultValue === undefined) return
        setValue('value', defaultValue.toString())
        trigger()
    }, [defaultValue, setValue, trigger])

    return (
        <div className={className}>
            <div className="description">
                {title && <h4 className="block-title">{title}</h4>}
                {label && <span className="block-label">{label}</span>}
                {description && <span className="block-desc">{description}</span>}
            </div>
            <div className="actions">
                <form onSubmit={handleSubmit(({ value }) => onSubmit?.(Number(value)))}>
                    <span className="form-input">
                        {prefix && <span className="prefix">{prefix}</span>}
                        <input
                            required
                            type="number"
                            name="value"
                            max={max}
                            min={min}
                            ref={register({
                                required: 'Campo requerido',
                                validate: (value) =>
                                    Number.isInteger(Number(value)) || 'El número es inválido',
                                max:
                                    max !== undefined
                                        ? {
                                              value: max,
                                              message: `Debe ser menor a ${max}`,
                                          }
                                        : undefined,
                                min:
                                    min !== undefined
                                        ? {
                                              value: min,
                                              message: `Debe ser mayor a ${min}`,
                                          }
                                        : undefined,
                            })}
                        />
                        {suffix && <span className="suffix">{suffix}</span>}
                    </span>
                    <button type="submit" className="btn-yellow" disabled={!formState.isValid}>
                        {!formState.isSubmitting ? (
                            buttonLabel
                        ) : (
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                        )}
                    </button>
                </form>
            </div>
        </div>
    )
}

interface CheckBoxFieldProps {
    title?: string
    label?: string
    description?: string
    className?: string
    onChange?: (value: boolean) => unknown
    checked?: boolean
    default?: boolean
    disabled?: boolean
    gaEvent?: () => unknown
}

const CheckBoxField = (props: CheckBoxFieldProps) => {
    const {
        className,
        title,
        label,
        description,
        checked,
        onChange,
        default: initial,
        disabled,
        gaEvent,
    } = props
    const [state, setState] = React.useState(checked ?? initial ?? false)
    const [submitting, setSubmitting] = React.useState(false)

    useEffect(() => {
        checked && setState(checked)
    }, [checked])

    const onClick = useCallback(async () => {
        try {
            if (gaEvent != undefined) gaEvent()
            setSubmitting(true)
            await onChange?.(!state)
            setState(!state)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        } finally {
            setSubmitting(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, onChange])

    return (
        <div className={className}>
            <div className="description">
                <h4 className="block-title">{title}</h4>
                <span className="block-label">{label}</span>
                <span className="block-desc">{description}</span>
            </div>
            <div className="actions">
                <ToggleBtn
                    on={checked !== undefined ? checked : state}
                    onClick={onClick}
                    inactive={disabled}
                    submitting={submitting}
                />
            </div>
        </div>
    )
}

export default AdvanceConfiguration
