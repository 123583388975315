import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { AttendeesNotInSession } from '../../../../../../apis/api.participation'
import { defaultModalStyles } from '../../../../../../constants'
import { useUIContext } from '../../../../../../context/UIContext'
import { capitalizeAll } from '../../../../../../helpers/formatter'
import { copyToClipboard } from '../../../../../../helpers/utilities'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { OneData, OneDataProps } from '../../Cards/OneData'
import styles from './../../LiveMonitor.module.css'

export const AttendanceLiveCard = () => {
    const { t } = useTranslation('teamMonitor')
    const [isOpen, setIsOpen] = useState(false)

    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { totalStudents } = stats.monitor.teams
    const { attendeesNotInSession } = stats.monitor.attendance

    const attendanceInfo: OneDataProps = {
        title: (
            <>
                {t('People')} <span className={styles.boldRed}>LIVE</span>
            </>
        ),
        info: t(
            'People connected and attendees pending entry to the video call. Click the link below to see the list of people who are not part of the team.'
        ),
        caption: '',
        data: totalStudents,
        footerIcon: ['fas', 'video-slash'],
        footerText: (
            <button
                className={styles.link}
                onClick={() => setIsOpen(() => true)}
                disabled={!attendeesNotInSession.length}
            >
                {t('People')} <span className={styles.bold}>{t('without entering the team')}</span>
            </button>
        ),
        footerData: <span className={styles.bold}>{attendeesNotInSession.length}</span>,
    }

    return (
        <>
            <OneData {...attendanceInfo} />
            {isOpen && (
                <AttendanceModal
                    {...{
                        isOpen,
                        closeModal: () => setIsOpen(() => false),
                        attendeesNotInSession,
                    }}
                />
            )}
        </>
    )
}

const AttendanceModal = ({
    isOpen,
    closeModal,
    attendeesNotInSession,
}: {
    isOpen: boolean
    closeModal: () => void
    attendeesNotInSession: AttendeesNotInSession[]
}) => {
    const tableRef = useRef<HTMLTableElement>(null)
    const { t } = useTranslation('teamMonitor')
    const { toastNotification } = useUIContext()

    const handleCopyEntireTable = async () => {
        if (tableRef.current) {
            try {
                const tableText = tableRef.current.innerText

                await navigator.clipboard.writeText(tableText)

                toastNotification(t('Table copied to clipboard.'))
            } catch (err) {
                toastNotification(t('An error occurred while copying the table.'), 'error')
            }
        }
    }

    const onCopy = (email: string) => {
        copyToClipboard(email)
        toastNotification(t('Email copied to clipboard.'))
    }

    return (
        <Modal isOpen={isOpen} style={defaultModalStyles}>
            <FontAwesomeIcon
                icon={['fas', 'times']}
                onClick={closeModal}
                className={styles.closeIcon}
            />
            <div className={styles.modal}>
                <div className={styles.actions}>
                    <div className={styles.title}>
                        <FontAwesomeIcon icon={['fas', 'users']} />
                        <h4>{t('People without entering')}</h4>
                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <button onClick={handleCopyEntireTable} className="icon">
                            <FontAwesomeIcon icon={['fas', 'copy']} />
                            {t('Copy Table')}
                        </button>
                    </div>
                    <table ref={tableRef} className={styles.attendanceTable}>
                        <thead>
                            <tr>
                                <th>{t('Name')}</th>
                                <th>Email</th>
                                <th className={styles.itemCenter}>{t('Team')}</th>
                            </tr>
                        </thead>
                        <tbody className={styles.attendanceBody}>
                            {attendeesNotInSession.map(({ displayName, email, team }, index) => (
                                <tr key={index} className={styles.attendanceRow}>
                                    <td>{capitalizeAll(displayName)}</td>
                                    <td>
                                        {email}
                                        <FontAwesomeIcon
                                            icon={['fas', 'copy']}
                                            onClick={() => onCopy(email)}
                                            className={styles.copyIcon}
                                        />
                                    </td>
                                    <td className={styles.itemCenter}>{team}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}
