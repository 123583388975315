import {
    faAnalytics,
    faArrowRight,
    faBell,
    faCalendar,
    faCaretDown,
    faCaretUp,
    faChartLine,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faCoffee,
    faCog,
    faCopy,
    faEllipsisH,
    faEllipsisV,
    faMailbox,
    faMapMarker,
    faMedal,
    faQrcode,
    faSpinner,
    faSpinnerThird,
    faStickyNote,
    faVoteYea,
    faRotate as farRotate,
    faSquare as farSquare,
    faArrowUpRightAndArrowDownLeftFromCenter as fasArrowUpRightAndArrowDownLeftFromCenter,
    faArrowsRotate as fasArrowsRotate,
    faBirthdayCake as fasBirthdayCake,
    faBolt as fasBolt,
    faChevronDown as fasChevronDown,
    faChevronRight as fasChevronRight,
    faChevronUp as fasChevronUp,
    faCircle as fasCircle,
    faCircleArrowDown as fasCircleArrowDown,
    faCircleArrowLeft as fasCircleArrowLeft,
    faCircleArrowRight as fasCircleArrowRight,
    faCircleArrowUp as fasCircleArrowUp,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCirclePlay as fasCirclePlay,
    faClock as fasClock,
    faClockThree as fasClockThree,
    faEgg as fasEgg,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faFileArrowDown as fasFileArrowDown,
    faFileCsv as fasFileCsv,
    faGraduationCap as fasGraduationCap,
    faHandPaper as fasHandPaper,
    faHeart as fasHeart,
    faIdCard as fasIdCard,
    faLocationDot as fasLocationDot,
    faMegaphone as fasMegaphone,
    faMessage as fasMessage,
    faMessageQuestion as fasMessageQuestion,
    faMinusCircle as fasMinusCircle,
    faMobile as fasMobile,
    faPaperPlane as fasPaperPlane,
    faPlus as fasPlus,
    faPlusCircle as fasPlusCircle,
    faRedo as fasRedo,
    faRotateRight as fasRotateRight,
    faSearch as fasSearch,
    faSliders as fasSliders,
    faSort as fasSort,
    faSortDown as fasSortDown,
    faSortUp as fasSortUp,
    faStar as fasStar,
    faStop as fasStop,
    faThumbsDown as fasThumbsDown,
    faThumbsUp as fasThumbsUp,
    faTimesCircle as fasTimesCircle,
    faToggleOff as fasToggleOff,
    faToggleOn as fasToggleOn,
    faTrash as fasTrash,
    faTrashAlt as fasTrashAlt,
    faUpload as fasUpload,
    faUser as fasUser,
    faUserCheck as fasUserCheck,
    faUserClock as fasUserClock,
    faUserHeadset as fasUserHeadset,
    faUserSlash as fasUserSlash,
    faUsers as fasUsers,
    faVideo as fasVideo,
    faXmark as fasXmark,
    faVideoSlash as fasVideoSlash,
    faPeopleArrows as fasPeopleArrows,
    faFolder as fasFolder,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowSquareDown,
    faArrowToBottom,
    faAtomAlt,
    faBars,
    faBook,
    faBorderInner,
    faBullseyeArrow,
    faCamera,
    faCaretLeft,
    faChalkboardTeacher,
    faChevronLeft,
    faChevronRight,
    faCloudUpload,
    faCode,
    faCodeBranch,
    faCommentAltDots,
    faCommentAltExclamation,
    faCommentPlus,
    faCompass,
    faDivide,
    faDrawCircle,
    faEmptySet,
    faExclamationCircle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFilePdf,
    faFingerprint,
    faFolderOpen,
    faGlobe,
    faGlobeAmericas,
    faLeaf,
    faLink,
    faMegaphone,
    faPalette,
    faPaperPlane,
    faPencil,
    faPlus,
    faPowerOff,
    faRedo,
    faRedoAlt,
    faSave,
    faSchool,
    faSearchMinus,
    faSearchPlus,
    faText,
    faTh,
    faThList,
    faTimes,
    faTrash,
    faUndo,
    faUserCircle,
    faUserGraduate,
    faVideo,
    faAngleDoubleLeft as falAngleDoubleLeft,
    faAngleDoubleRight as falAngleDoubleRight,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faArrowUpRightFromSquare as falArrowUpRightFromSquare,
    faArrowsRotate as falArrowsRotate,
    faBolt as falBolt,
    faChartLine as falChartLine,
    faCheck as falCheck,
    faCheckCircle as falCheckCircle,
    faCircleExclamation as falCircleExclamation,
    faClock as falClock,
    faCompressWide as falCompressWide,
    faEgg as falEgg,
    faExpandWide as falExpandWide,
    faFileChartColumn as falFileChartColumn,
    faFileContract as falFileContract,
    faHandPaper as falHandPaper,
    faIdCard as falIdCard,
    faMapPin as falMapPin,
    faMinusCircle as falMinusCircle,
    faPlayCircle as falPlayCircle,
    faPlusCircle as falPlusCircle,
    faSearch as falSearch,
    faStar as falStar,
    faTimesCircle as falTimesCircle,
    faToggleOff as falToggleOff,
    faToggleOn as falToggleOn,
    faTrashAlt as falTrashAlt,
    faUndoAlt as falUndoAlt,
    faUserCheck as falUserCheck,
    faUserClock as falUserClock,
    faUserPen as falUserPen,
    faUserPlus as falUserPlus,
    faUserSlash as falUserSlash,
    faUsers as falUsers,
    faWindowMaximize as falWindowMaximize,
    faWindowMinimize as falWindowMinimize,
    faXmark as falXmark,
    faWaveSine as falWaveSine,
} from '@fortawesome/pro-light-svg-icons'
import {
    faArrowUpRightFromSquare,
    faFileCheck,
    faFileDownload,
    faFileUpload,
    faUserSlash,
    faArrowToBottom as farArrowToBottom,
    faBars as farBars,
    faBell as farBell,
    faBookmark as farBookmark,
    faChalkboardTeacher as farChalkboardTeacher,
    faChartLine as farChartLine,
    faChartMixed as farChartMixed,
    faCircleArrowLeft as farCircleArrowLeft,
    faCircleArrowRight as farCircleArrowRight,
    faCircleCheck as farCircleCheck,
    faCircleExclamation as farCircleExclamation,
    faCirclePlay as farCirclePlay,
    faEraser as farEraser,
    faExpandAlt as farExpandAlt,
    faGear as farGear,
    faGlobe as farGlobe,
    faGraduationCap as farGraduationCap,
    faHashtag as farHashtag,
    faHeart as farHeart,
    faLink as farLink,
    faList as farList,
    faLoader as farLoader,
    faMap as farMap,
    faMessage as farMessage,
    faMinusCircle as farMinusCircle,
    faMonitorWaveform as farMonitorWaveform,
    faPenToSquare as farPenToSquare,
    faPeopleGroup as farPeopleGroup,
    faPlusCircle as farPlusCircle,
    faPowerOff as farPowerOff,
    faSearch as farSearch,
    faSpinner as farSpinner,
    faSync as farSync,
    faTag as farTag,
    faTimes as farTimes,
    faToggleLargeOn as farToggleLargeOn,
    faToggleOff as farToggleOff,
    faToggleOn as farToggleOn,
    faUndoAlt as farUndoAlt,
    faUpload as farUpload,
    faUser as farUser,
    faUserCheck as farUserCheck,
    faUserClock as farUserClock,
    faUserHeadset as farUserHeadset,
    faUserPlus as farUserPlus,
    faUsers as farUsers,
    faVideo as farVideo,
    faVideoCircle as farVideoCircle,
    faClock as farClock,
    faWaveSine as farWaveSine,
    faBug as farBug,
} from '@fortawesome/pro-regular-svg-icons'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { faUserCog } from '@fortawesome/pro-duotone-svg-icons'
// Importo y creo librería FA para uso global. Agregar iconos acá.
import { library } from '@fortawesome/fontawesome-svg-core'

export default library.add(
    faAnalytics,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowRight,
    faArrowSquareDown,
    faArrowToBottom,
    faArrowUpRightFromSquare,
    faAtomAlt,
    faBars,
    faBell,
    faBook,
    faBorderInner,
    faBullseyeArrow,
    faCalendar,
    faCamera,
    faCaretDown,
    faCaretLeft,
    faCaretUp,
    faChalkboardTeacher,
    faChartLine,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCloudUpload,
    faCode,
    faCodeBranch,
    faCoffee,
    faCog,
    faCommentAltDots,
    faCommentAltExclamation,
    faCommentPlus,
    faCompass,
    faCopy,
    faDivide,
    faDrawCircle,
    faEllipsisH,
    faEllipsisV,
    faEmptySet,
    faExclamationCircle,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFacebook,
    faFileCheck,
    faFileDownload,
    faFilePdf,
    faFileUpload,
    faFingerprint,
    faFolderOpen,
    faGlobe,
    faGlobeAmericas,
    faInstagram,
    falAngleDoubleLeft,
    falAngleDoubleRight,
    falAngleLeft,
    falAngleRight,
    falArrowsRotate,
    falArrowUpRightFromSquare,
    falBolt,
    falChartLine,
    falCheck,
    falCheckCircle,
    falCircleExclamation,
    falClock,
    falCompressWide,
    faLeaf,
    falEgg,
    falExpandWide,
    falFileChartColumn,
    falFileContract,
    falHandPaper,
    falIdCard,
    faLink,
    falMapPin,
    falMinusCircle,
    falPlayCircle,
    falPlusCircle,
    falSearch,
    falStar,
    falTimesCircle,
    falToggleOff,
    falToggleOn,
    falTrashAlt,
    falUndoAlt,
    falUserCheck,
    falUserClock,
    falUserPen,
    falUserPlus,
    falUsers,
    falUserSlash,
    falWindowMaximize,
    falWindowMinimize,
    falXmark,
    faMailbox,
    faMapMarker,
    faMedal,
    faMegaphone,
    faPalette,
    faPaperPlane,
    faPencil,
    faPlus,
    faPowerOff,
    faQrcode,
    farArrowToBottom,
    farBars,
    farBookmark,
    farBookmark,
    farChalkboardTeacher,
    farChartLine,
    farCircleCheck,
    farCircleExclamation,
    farCirclePlay,
    faRedo,
    faRedoAlt,
    farEraser,
    farExpandAlt,
    farGraduationCap,
    farHashtag,
    farHeart,
    farLink,
    farList,
    farMap,
    farMessage,
    farMinusCircle,
    farPlusCircle,
    farSearch,
    farSquare,
    farSync,
    farTag,
    farTimes,
    farToggleLargeOn,
    farToggleOff,
    farToggleOn,
    farUndoAlt,
    farUpload,
    farUser,
    farUserCheck,
    farUserClock,
    farUserHeadset,
    farUserPlus,
    farUsers,
    farVideo,
    farVideo,
    farVideoCircle,
    fasArrowsRotate,
    fasArrowUpRightAndArrowDownLeftFromCenter,
    faSave,
    fasBirthdayCake,
    fasBolt,
    fasChevronDown,
    fasChevronRight,
    fasChevronUp,
    faSchool,
    fasCircleArrowDown,
    fasCircleArrowUp,
    fasCircleExclamation,
    fasCircleInfo,
    fasCirclePlay,
    fasClock,
    fasClockThree,
    faSearchMinus,
    faSearchPlus,
    fasEgg,
    fasExclamationCircle,
    fasExclamationTriangle,
    fasFileArrowDown,
    fasFileCsv,
    fasGraduationCap,
    fasHandPaper,
    fasHeart,
    fasIdCard,
    fasMessage,
    fasMinusCircle,
    fasMobile,
    fasPaperPlane,
    faSpinner,
    faSpinnerThird,
    fasPlus,
    fasPlusCircle,
    fasRedo,
    fasSearch,
    fasSort,
    fasSortDown,
    fasSortUp,
    fasStar,
    fasThumbsDown,
    fasThumbsUp,
    faStickyNote,
    fasTimesCircle,
    fasToggleOff,
    fasToggleOn,
    fasTrash,
    fasTrashAlt,
    fasUser,
    fasUserCheck,
    fasUserClock,
    fasUserHeadset,
    fasUsers,
    fasUserSlash,
    fasVideo,
    fasXmark,
    faText,
    faTh,
    faThList,
    faTimes,
    faTrash,
    faUndo,
    faUserCircle,
    faUserCog,
    faUserGraduate,
    faUserSlash,
    faVideo,
    faVoteYea,
    faWhatsapp,
    farPenToSquare,
    farSpinner,
    falArrowRightFromBracket,
    fasMessageQuestion,
    farMonitorWaveform,
    farPeopleGroup,
    farChartMixed,
    farGear,
    farBell,
    fasCircle,
    farGlobe,
    fasCircleArrowRight,
    fasLocationDot,
    farCircleArrowRight,
    farRotate,
    farLoader,
    farCircleArrowLeft,
    fasCircleArrowLeft,
    farPowerOff,
    fasSliders,
    fasMegaphone,
    fasUpload,
    fasStop,
    fasRotateRight,
    falWaveSine,
    farClock,
    farWaveSine,
    fasVideoSlash,
    farBug,
    fasPeopleArrows,
    fasFolder
)
