import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetTeamResponse } from '../../../apis/api.egg'
import { useTeam } from '../Context/TeamContext'
import { useTeamDetails } from '../Hooks/useTeamDetails'
import { StudentItem, TeamListHeader } from './StudentItem/StudentItem'
import styles from './TeamItem.module.css'
import TeamMeet from './TeamMeet'
import { TeamSpinner } from './TeamsList'

export const TeamItem = ({ team, isExpanded }: TeamItemProps) => {
    const [listExpanded, setListExpanded] = useState(!!isExpanded)
    const toggleExpand = () => setListExpanded((prev) => !prev)

    return (
        <div className="table-item">
            <TeamMeet {...team} toggleExpand={toggleExpand} isExpanded={listExpanded} />
            {listExpanded && <StudentList teamNumber={team.number} />}
        </div>
    )
}

export const StudentList = ({ teamNumber }: { teamNumber: number }) => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    const { teamDetails, status } = useTeamDetails(teamNumber)

    const isLoading = status === 'loading'

    if (!teamDetails && !isLoading) {
        return (
            <div className="empty-message" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                <p>{t('empty.empty-team')}</p>
            </div>
        )
    }

    return (
        <div className={styles.userList}>
            {isLoading ? (
                <TeamSpinner />
            ) : (
                <>
                    <TeamListHeader teamNumber={teamNumber} />
                    {teamDetails?.students.map((student, index) => {
                        return (
                            <StudentItem
                                key={index + student._profileId}
                                {...student}
                                team={{
                                    number: teamNumber,
                                    position: index + 1,
                                    algorithmType: team?.algorithm?.algorithmType ?? 'default',
                                }}
                                available
                                algorithmType={team?.algorithm?.algorithmType ?? 'default'}
                            />
                        )
                    })}
                </>
            )}
        </div>
    )
}

// Interfaces
interface TeamItemProps {
    team: GetTeamResponse['data']['teams'][0]
    isExpanded?: boolean
}
