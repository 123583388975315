import { useCallback } from 'react'
import ReactGA, { EventArgs } from 'react-ga'
import { useUser } from '../context/UserContext'
import { GetTeamResponse } from '../apis/api.egg'
import { useTeam } from '../pages/Team/Context/TeamContext'

export const useGAEvents = () => {
    const { role } = useUser()
    const { team: defaultTeam } = useTeam()

    return {
        sendGA: useCallback(
            <T extends Category>({ category, action, team = defaultTeam, ...rest }: Props<T>) =>
                ReactGA.event({
                    dimension1: 'platform',
                    dimension2: team?.hq.name.toLocaleLowerCase() || 'dashboard',
                    dimension3: team?.teamName.toLocaleLowerCase() || 'dashboard',
                    dimension4: team?.subject.name.toLocaleLowerCase() || 'dashboard',
                    dimension7: role,
                    dimension9: team?.algorithm?.algorithmType,
                    ...rest,
                    category,
                    action,
                }),
            [defaultTeam, role]
        ),
    }
}

interface GAEvents {
    'Help Request': 'HelpRequestNotificationViewed' | 'GoToTeam' | 'OpenHRMonitor'

    'Team Settings':
        | 'AlertCompleteBasicSettings'
        | 'DeleteTeam'
        | 'InitialTeamSaved'
        | 'AssignTeamBetweenPresent'
        | 'AssignedTeamsManualChange'
        | 'BlockCompleteBasicSettings'
        | 'ContentSection'
        | 'AccessPriorVirtualMeeting'
        | 'TeamDeactivation'

    'Team Management':
        | 'RotateStudents'
        | 'CheckNotifications'
        | 'SearchBar'
        | 'CheckMentorsAvailable'
        | 'FeedbackRequest'
        | 'DeleteStudentClicked'
        | 'StudentAssigned'
        | 'StudentUnassigned'
        | 'MentorToOrigin'
        | 'VirtualMeetingOverview'

    'Schedule Settings':
        | 'ClassOpeningTimeSaved'
        | 'TolerancePresentSaved'
        | 'ToleranceTardinessSaved'
        | 'TimetableSaved'
        | 'MultipleTimetableClicked'
        | 'TimetableSaved'

    Reporting:
        | 'ViewReportSection'
        | 'HistoricalAttendanceReportDownloaded'
        | 'AttendanceReportDownloaded'
        | 'TeamReportDownloaded'
        | 'UpdateClassMonitor'
        | 'FiveAttendancesReportDownloaded'
        | 'InstitutionReportDownload'
        | 'CohortReportDownload'
        | 'OpenZoomMonitor'
        | 'ClassSummary'
        | 'ClassSummaryDownload'
        | 'FeedbackDownload'
        | 'GlobalReportDownload'
        | 'ApplyDates'
        | 'FilterByInstitution'
        | 'LastDayClick'
        | 'LastWeekClick'
        | 'LastMonthClick'
        | 'RecognitionsReporteDownload'
        | 'FacilitatorsReportDownload'
        | 'TeamReportDownloaded'

    Engagement:
        | 'Join-Meeting'
        | 'Attendance'
        | 'Login'
        | 'ResetRecognitions'
        | 'Vote-Table'
        | 'Vote'
        | 'Send Comments'

    Onboarding:
        | 'HelpClicked'
        | 'InviteStudent'
        | 'InviteProfessor'
        | 'MassiveStudentsUpload'
        | 'ActiveStudentsReportDownload'
        | 'ShowAllTeams'

    Meetings: 'MeetingSaved'

    Integrations: 'CreateTeam'
}

type Category = keyof GAEvents

type Props<T extends Category> = {
    category: T
    action: GAEvents[T]
    team?: GetTeamResponse['data']
} & EventArgs
