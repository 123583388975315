import { ChartData } from '../components/GoogleChart/ChartComponent'

export const calculateMaxHeightWithRatio = (data: ChartData, ratio?: number) => {
    const totals = data.slice(1).map((arr) => {
        const total = arr.reduce(
            (
                sum: number,
                val:
                    | string
                    | number
                    | { role: string; sourceColumn?: number; type?: string; calc?: string }
                    | null
            ) => (typeof val === 'number' ? sum + val : sum),
            0
        )
        return total
    })

    const maxVal = Math.max(...totals)

    return maxVal * (ratio ?? 1.2)
}
