import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { SearchResult, TeamDetails } from '../../../apis/api.egg'
import { useUIContext } from '../../../context/UIContext'
import { useTeam } from '../Context/TeamContext'
import { useSearch } from '../Hooks/useSearch'
import Styles from './ShowSearchResult.module.css'
import { StudentItem } from './StudentItem/StudentItem'
import { TeamAccordion } from './TeamAccordion'
import { StudentList } from './TeamItem'
import { TeamSpinner } from './TeamsList'

export const ShowSearchResult = () => {
    const { t } = useTranslation(['teamManager'])
    const { searchBox } = useUIContext()
    const { type, query } = searchBox[0] ?? {}
    const { teamResults, peopleResults, isLoadingTeam, isLoadingPeople } = useSearch(
        `${type}`,
        `${query}`
    )
    if (!type || !query) return null

    return (
        <section className={Styles.section}>
            <header className={Styles.mainHeader}>
                <h3>{t('search.results-title')}</h3>
                <button className={Styles.btnClearResult} onClick={() => searchBox[1](null)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
            </header>

            {isLoadingPeople || isLoadingTeam ? (
                <TeamSpinner />
            ) : (
                <div className={Styles.results}>
                    {searchBox[0]?.type === 'people' && (
                        <PeopleResult peopleResults={peopleResults} />
                    )}
                    {searchBox[0]?.type === 'teams' && <TeamResult teamResults={teamResults} />}
                </div>
            )}
        </section>
    )
}

const TeamResult = ({ teamResults }: { teamResults?: TeamDetails }) => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    const { searchBox, toastNotification } = useUIContext()
    const { type, query } = searchBox[0] ?? {}

    if (!teamResults || !team || !query) return null

    const { number, isMentorTeam } = teamResults

    const { allTeamNumbers } = team.stats

    // Check if valid team number
    const reg = new RegExp('^[0-9]*$')
    const isValidTeam = allTeamNumbers.some((team) => team === +query)

    if (type === 'teams' && (!reg.test(`${query}`) || !isValidTeam)) {
        searchBox[1](null)
        toastNotification(t('search.error-invalid-team'), 'error')
        return null
    }

    return (
        <TeamAccordion
            {...{
                number,
                isMentorTeam,
                notCollapsed: true,
                guide: Number(teamResults.guide),
                module: Number(teamResults.module),
                description: teamResults.description,
            }}
        >
            <StudentList teamNumber={number} />
        </TeamAccordion>
    )
}

const PeopleResult = ({
    peopleResults,
}: {
    peopleResults?: {
        students: SearchResult[]
    }
}) => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    if (!peopleResults || !team) return null

    return (
        <>
            {peopleResults.students.map((student) => (
                <StudentItem
                    key={student._profileId}
                    {...student}
                    team={{
                        number: student.team?.number,
                    }}
                    showTeamBadge={true}
                    canDelete={true}
                    algorithmType={team?.algorithm?.algorithmType}
                    className={'peopleResults'}
                    available
                    isExpertMentor={false}
                />
            ))}

            {
                // No student match
                !peopleResults.students.length && <h4>{t('search.no-results')}</h4>
            }
        </>
    )
}
