import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { ParticipationStatus } from '../../../apis/api.participation'
import Tooltip from '../../../components/DesignSystem/components/atoms/Tooltip/Tooltip.component'
import { getStatusColor } from '../Helpers/getStatusColor'

export const TeamStatus = ({ teamStatus }: { teamStatus: ParticipationStatus }) => {
    const { t } = useTranslation('teamManager')
    const participationColor = getStatusColor(teamStatus)

    return (
        <Tooltip text={t(`toolTips.${teamStatus}Status` as const)} placement="topCenter">
            <div style={{ color: participationColor }}>
                <FontAwesomeIcon icon="circle" />
            </div>
        </Tooltip>
    )
}
