import Accordion from '../../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
import AccordionFooter from '../../../../components/DesignSystem/components/molecules/AccordionFooter/AccordionFooter.component'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { UIContext } from '../../../../context/UIContext'
import { updateTeamSubjectName } from '../../../../apis/api.egg'
import { useSettings } from '../../Settings'
import { useTeam } from '../../Context/TeamContext'
import { useTranslation } from 'react-i18next'

export const TeamInfo = () => {
    const { settings, updateSetting } = useSettings()
    const { t } = useTranslation('teamSettings')
    const { team } = useTeam()
    const { toastNotification } = useContext(UIContext)

    const [course, setCourse] = useState(settings?.name ?? '')
    const [subject, setSubject] = useState(team?.subject.name || '')

    const inputCourseName = useRef<HTMLInputElement>(null)
    // const inputCourseSubject = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setCourse(settings?.name ?? '')
    }, [settings?.name])

    const saveCourse = () => {
        const re = /^[a-zÀ-ÿ\d\s]+$/i
        if (re.test(course) && course.length <= 50 && team?.course._id) {
            updateSetting({ name: course })
        } else
            toastNotification(
                'Formato incorrecto, solo permite,  letras, números y un máximo de 50 caracteres ',
                'error'
            )
    }

    const handleChangeCourse = (event: React.ChangeEvent<HTMLInputElement>) =>
        setCourse(event.target.value)

    // const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) =>
    //     setSubject(event.target.value)

    const saveSubject = () => {
        const re = /^[a-zÀ-ÿ\d\s]+$/i
        if (re.test(subject) && subject.length <= 50 && team) {
            updateTeamSubjectName(team.subject._id, team.professorSchedule._id, subject)
        } else
            toastNotification(
                'Formato incorrecto, solo permite, letras, números y un máximo de 50 caracteres',
                'error'
            )
    }

    const [controlledCollapsed, setControlledCollapsed] = useState(false)
    const teamInfoFooter = {
        primaryButtonOnClick: () => {
            saveCourse()
            saveSubject()
        },
        primaryButtonText: t('content.save'),
        secondaryButtonOnClick: () => {
            setControlledCollapsed(() => true)
            setSubject(team?.subject?.name ?? '')
            setCourse(settings?.name ?? '')
        },
        secondaryButtonText: t('content.cancel'),
    }

    return (
        <Accordion
            header={{
                title: t('teamSettings:content.team-info.title'),
                headerTitleStyles: {
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: 17,
                    letterSpacing: 0,
                    color: '#003750',
                },
            }}
            footer={AccordionFooter(teamInfoFooter)}
            controlledCollapsed={controlledCollapsed}
            setControlledCollapsed={setControlledCollapsed}
        >
            <div className="team-info">
                <form action="">
                    <div className="form-group">
                        <label htmlFor="course" className="settings-inside-title">
                            {t('teamSettings:content.team-info.course')}
                        </label>
                        <label htmlFor="course" className="settings-inside-subtitle">
                            {t('teamSettings:content.team-info.name')}
                        </label>
                        <div className="input-group">
                            <EditInput
                                ref={inputCourseName}
                                type="text"
                                id="course"
                                value={course}
                                placeholder={t('teamSettings:content.team-info.coursePlaceholder')}
                                onChange={(event) => {
                                    handleChangeCourse(event)
                                }}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="subject">
                            {t('teamSettings:content.team-info.subject')}
                            <label htmlFor="course">
                                {t('teamSettings:content.team-info.objective')}
                            </label>
                        </label>
                        <div className="input-group">
                            <EditInput
                                ref={inputCourseSubject}
                                type="text"
                                id="subject"
                                value={subject}
                                placeholder={t('teamSettings:content.team-info.subjectPlaceholder')}
                                onChange={(event) => handleChangeSubject(event)}
                                maxLength={50}
                            />
                        </div>
                    </div> */}
                </form>
            </div>
        </Accordion>
    )
}

export default TeamInfo

type IProps = {
    cursor?: boolean
}

const EditInput = styled.input<IProps>`
    border: 1px solid #eaeaea;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
`
