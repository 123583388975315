import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import pulseIcon from '../../../../../assets/images/rayo_color.svg'
import AvatarEgg from '../../../../../components/Styled/AvatarEgg'
import LoadingPage from '../../../../Other/LoadingPage'
import { getPulsesCsv } from '../../../api-reports'
import { useCsvReport } from '../../../Hooks/useCsvReport'
import { usePulses } from '../../../Hooks/usePulses'
import { ChartComponent, NoData } from '../../../../../components/GoogleChart/ChartComponent'
import { capitalizeAll } from '../../../../../helpers/formatter'
import { ReportContainer } from '../ReportContainer'
import { colors } from '../../../../../styles/globals/_variables'

export const CooperationReports = () => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()
    const { pulses, ranking, isRankingLoading, columnsWidth } = usePulses()
    const { barChart, totalPulsesBarChart } = pulses || {}
    const { positionLength, pulsesLength } = columnsWidth || { positionLength: 0, pulsesLength: 0 }
    const { eggBlue, eggColor } = colors

    const formatBarChart = () => {
        if (!barChart) return null
        return barChart.map((row, index) => {
            if (index === 0) {
                return [
                    row[0],
                    `% ${t('Pulses utilization')}`,
                    {
                        role: 'annotation',
                        type: 'string',
                        calc: 'stringify',
                    },
                ]
            } else {
                return [row[0], row[row.length - 1], `${row[row.length - 1]}%`]
            }
        })
    }
    const formatTotalPulses = () => {
        if (!totalPulsesBarChart) return null
        return totalPulsesBarChart.map((row, index) => {
            if (index === 0) {
                return [
                    row[0],
                    `${t('Total pulses delivered')}`,
                    {
                        role: 'annotation',
                        type: 'string',
                        calc: 'stringify',
                    },
                ]
            } else {
                return [row[0], row[row.length - 1], `${row[row.length - 1]}`]
            }
        })
    }

    return (
        <CooperationReportsStyled>
            <ReportContainer
                header={{
                    title: 'Total pulses',
                    onClick: () => getFilteredReport(getPulsesCsv, 'pulses'),
                    disabled: !barChart && !totalPulsesBarChart,
                }}
                content={
                    <>
                        <ChartComponent
                            chartType="ColumnChart"
                            height={'400px'}
                            data={formatBarChart()}
                            options={{
                                allowHtml: true,
                                titleTextStyle: {
                                    fontSize: 14,
                                },
                                legend: { position: 'top' },
                                vAxis: {
                                    viewWindowMode: 'pretty',
                                    viewWindow: {
                                        max: 100,
                                        min: 0,
                                    },
                                },
                                bar: { groupWidth: '50' },
                                chartArea: {
                                    left: 50,
                                    top: 50,
                                    right: 20,
                                    bottom: 80,
                                    width: '100%',
                                    height: '100%',
                                },
                                colors: [eggBlue],
                                hAxis: {
                                    slantedText: true,
                                },
                            }}
                        />
                        <ChartComponent
                            chartType="ColumnChart"
                            height={'400px'}
                            data={formatTotalPulses()}
                            options={{
                                allowHtml: true,
                                titleTextStyle: {
                                    fontSize: 14,
                                },
                                legend: { position: 'top' },
                                vAxis: {
                                    viewWindowMode: 'pretty',
                                    viewWindow: {
                                        min: 0,
                                    },
                                },
                                bar: { groupWidth: '50' },
                                chartArea: {
                                    left: 50,
                                    top: 50,
                                    right: 20,
                                    bottom: 80,
                                    width: '100%',
                                    height: '100%',
                                },
                                colors: [eggColor],
                                hAxis: {
                                    slantedText: true,
                                },
                            }}
                        />
                    </>
                }
                className="column-display"
            />
            <ReportContainer
                header={{
                    title: 'Top 10 ranking',
                }}
                content={
                    isRankingLoading ? (
                        <LoadingPage />
                    ) : !ranking ? (
                        <NoData />
                    ) : (
                        <RankingCardStyle
                            maxLengthPosition={positionLength}
                            maxLengthPulses={pulsesLength}
                            className="chart"
                        >
                            {ranking?.map(
                                ({ position, urlImage, displayName, email, pulsesIn }) => (
                                    <div
                                        key={email}
                                        className="ranking-card-container ranking-container"
                                    >
                                        <h1 className="ranking-card-position"># {position}</h1>
                                        <div className="ranking-card-icon only-desktop">
                                            <AvatarEgg
                                                src={urlImage ?? ''}
                                                title={displayName}
                                                size={{ mobile: 30, desktop: 45 }}
                                                isExternal={true}
                                            />
                                        </div>
                                        <p className="ranking-card-display-name">
                                            {capitalizeAll(displayName)}
                                        </p>
                                        <p className="ranking-card-display-name">{email}</p>
                                        <img
                                            className="ranking-card-pulse-icon"
                                            src={pulseIcon}
                                            alt="Bolt"
                                        />
                                        <h1 className="ranking-card-pulses">{pulsesIn}</h1>
                                    </div>
                                )
                            )}
                        </RankingCardStyle>
                    )
                }
            />
        </CooperationReportsStyled>
    )
}

const CooperationReportsStyled = styled.section`
    .column-display {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
`

const CommonRankingCardStyle = styled.div`
    padding: 20px 40px;
    border-radius: 12px;
    .ranking-card-container {
        display: grid;
        align-items: center;
        gap: 1em;

        .ranking-card-position {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bold;
            font-size: 26px;
            padding-right: 22px;
            border-right: 2px solid lightGray;
        }

        .ranking-card-icon {
            padding: 0 1em;
            div {
                width: 45px;
                height: 45px;
            }
        }

        .ranking-card-display-name {
            margin: 0;
            font-size: 16px;
        }

        .ranking-card-pulse-icon {
            padding-left: 2em;
        }

        .ranking-card-pulses {
            font-weight: 600;
            font-size: 26px;
            line-height: 39px;
            text-align: right;
        }

        h1 {
            margin: 0;
        }
    }
`

const RankingCardStyle = styled(CommonRankingCardStyle)<{
    maxLengthPosition: number
    maxLengthPulses: number
}>`
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    .ranking-card-container {
        border-bottom: 1px solid lightGray;
        padding: 22px 20px;
        display: grid;
        align-items: center;
        grid-template-columns:
            7%
            max-content
            1fr
            1fr
            max-content
            ${({ maxLengthPulses }) => `${maxLengthPulses / 2}em`};
        gap: 1em;
        &:last-child {
            border-bottom: none;
        }
    }
    .ranking-card-user {
        background-color: #e2f3fa;
        border-radius: 12px;
    }
    .ranking-points {
        img {
            padding: 0;
            align-items: start;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        & > .ranking-card-container {
            grid-template-columns: max-content 1fr max-content max-content;
            padding: 10px 20px;
        }
    }
`
