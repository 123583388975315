import React, { useState } from 'react'
import { QualityData, QualityDataProps } from '../../Cards/QualityData'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTranslation } from 'react-i18next'
import styles from './../../LiveMonitor.module.css'
import { TeamsModal } from './TalkingQualityCard'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const TeamsQualityCard = () => {
    const { t } = useTranslation('teamMonitor')
    const [currentQualityLevel, setCurrentQualityLevel] = useState<'hight' | 'moderate' | 'low'>()
    const [teams, setTeams] = useState<string[] | null>(null)
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { mild, moderate, severe } = stats.monitor.teams.teamByPeople

    const ModalButton = React.memo(
        ({
            label,
            teams,
            quality,
        }: {
            label: string | number
            teams: string[]
            quality: 'hight' | 'moderate' | 'low'
        }) => {
            const stringLabel = typeof label === 'string'
            return (
                <button
                    onClick={() => {
                        setCurrentQualityLevel(quality)
                        setTeams(teams)
                    }}
                    className={stringLabel ? styles.link : styles.titleLink}
                    disabled={!teams.length}
                >
                    {stringLabel ? t(label) : label}
                </button>
            )
        },
        (prevProps, nextProps) => prevProps.teams === nextProps.teams
    )

    const helpRequestInfo: QualityDataProps = {
        title: t('Teams by number of people'),
        status: ([
            {
                icon: ['fas', 'check-circle'] as IconProp,
                label: 'From 6 to 8 people',
                value: mild,
                quality: 'hight',
            },
            {
                icon: ['fas', 'play-circle'] as IconProp,
                label: 'From 3 to 5 people or 8 to 10 people',
                value: moderate,
                quality: 'moderate',
            },
            {
                icon: ['fas', 'circle-exclamation'] as IconProp,
                label: 'Less than 3 or more than 10 people',
                value: severe,
                quality: 'low',
            },
        ] as const).map<QualityDataProps['status'][number]>(({ icon, label, value, quality }) => ({
            icon,
            label: <ModalButton label={label} teams={value} quality={quality} />,
            value: <ModalButton label={value.length} teams={value} quality={quality} />,
        })),
        info: t(
            'Team distribution by size provides a breakdown of the number of teams in your video call based on the number of people in each team.'
        ),
    }
    return (
        <>
            <QualityData {...helpRequestInfo} />
            {currentQualityLevel && (
                <TeamsModal
                    teams={teams}
                    currentModal={currentQualityLevel}
                    closeModal={() => setCurrentQualityLevel(undefined)}
                />
            )}
        </>
    )
}
