import { useQuery } from 'react-query'
import { getTeamDetails } from '../../../apis/api.egg'
import { useUIContext } from '../../../context/UIContext'
import { useTeam } from '../Context/TeamContext'

export const useTeamDetails = (teamNumber: number) => {
    const { courseID } = useTeam()
    const { toastError } = useUIContext()

    const { data: teamDetails, status } = useQuery(
        [courseID, 'Team', 'Details', teamNumber],
        () => getTeamDetails(`${courseID}`, teamNumber),
        {
            enabled: !!courseID,
            onError: (error) => toastError(error),
        }
    )

    return { teamDetails, status }
}
