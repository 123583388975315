import Axios from 'axios'
import Config from '../config'
import { API_RESPONSE } from '../pages/Team/api-reports'

const { API_PRESENT } = Config

export const ApiPresent = Axios.create({
    baseURL: API_PRESENT,
})

export const updatePresent = (body: UpdatePresentProps) =>
    ApiPresent.post<UpdatePresentResponse>(`v1/presents/create`, body)

type UpdatePresentResponse = API_RESPONSE<PresentDTO>

type PresentDTO = {
    _id: string
    status: PresentStatus
}

export type PresentStatus = 'present' | 'absent'

export type UpdatePresentProps = {
    courseId: string
    profileId: string
    status: PresentStatus
}
