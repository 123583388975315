import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OutlineButton } from '../../../components/DesignSystem/components/atoms'
import ReportHeaderDropdown from './Actions/ReportHeaderDropdown'
import Styles from './Overview/OverviewHeader.module.css'
import Tooltip from '../../../components/DesignSystem/components/atoms/Tooltip/Tooltip.component'
import { useGeneralMeetings } from '../../../hooks/useMultipleGeneralMeetings'
import { useOpenMeet } from '../Hooks/useOpenMeet'
import { useTeam } from '../Hooks/useTeam'
import { useTranslation } from 'react-i18next'
import { TeamAccordionProps } from './TeamsList'

export const MeetButton = ({ props }: { props?: TeamAccordionProps }) => {
    const { number, isMentorTeam, meeting } = props ?? {}
    const { t } = useTranslation('teamManager')
    const { openTeamMeet, openGeneralMeet } = useOpenMeet()
    const { settings } = useTeam()

    const tooltipText = number ? t('Team {{ number }} meeting', { number }) : t('General meeting')

    const isGeneralMeeting = !number && !isMentorTeam

    const isEggProvider =
        settings?.meeting?.type === 'egg' ||
        settings?.meeting?.type === 'zoom-sdk-auto' ||
        settings?.meeting?.type === 'zoom-sdk'

    const isMultipleGeneralMeetings =
        isGeneralMeeting &&
        (settings?.meeting?.type === 'zoom-hybrid' ||
            (settings?.meeting?.type === 'egg' && !!settings?.meeting?.data?.neighborhoods?.length))

    if (isMultipleGeneralMeetings) return <GeneralMeetings />

    if (!isGeneralMeeting && !isEggProvider && !meeting) return null

    if (isGeneralMeeting && settings?.meeting?.type === 'zoom-sdk-auto') return null

    return (
        <Tooltip placement="topCenter" text={tooltipText}>
            <OutlineButton
                style={{
                    height: isGeneralMeeting ? 39.09 : 'min-content',
                    minWidth: 'max-content',
                    backgroundColor: isGeneralMeeting ? '#ff647c' : '',
                    color: isGeneralMeeting ? '#fff' : '',
                    border: `2px solid ${isGeneralMeeting ? 'transparent' : '#eaeaea'}`,
                }}
                icon="video"
                onClick={() =>
                    meeting
                        ? window.open(meeting)
                        : isGeneralMeeting
                        ? openGeneralMeet()
                        : openTeamMeet({ number, isMentorTeam })
                }
            />
        </Tooltip>
    )
}

const GeneralMeetings = () => {
    const { t } = useTranslation('teamManager')
    const { general_meetings } = useGeneralMeetings()

    return (
        <ReportHeaderDropdown
            label={t('heading.virtualClass')}
            icon={['far', 'video']}
            className={Styles.zoomHybrid}
        >
            <div className="dropdown-meeting">
                {general_meetings?.map(({ url, label }, index) => (
                    <div key={'meeting-section' + index} className="report-item">
                        <button
                            className="action-link-meeting"
                            key={label}
                            onClick={() => window.open(url, label)}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <FontAwesomeIcon
                                className="icon"
                                icon={['far', 'video-circle']}
                                style={{
                                    fontSize: '16px',
                                    color: '#073045',
                                    marginRight: 0,
                                    marginLeft: 6,
                                }}
                            />
                            <span className="span-meeting">
                                {label === 'mentors' ? t('search.mentors') : label}
                            </span>
                        </button>
                    </div>
                ))}
            </div>
        </ReportHeaderDropdown>
    )
}
