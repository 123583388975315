import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../../../../components/Link'
import { useTeam } from '../../Context/TeamContext'

export const UnAssignedLink = () => {
    const { team, actionsView } = useTeam()
    const [, setAction] = actionsView
    const { t } = useTranslation('teamDetailTeacher')

    const quantity = team?.stats.students.unassigned

    if (!quantity || !team) return null
    if (team?.algorithm?.algorithmType === 'hca4') return null

    return (
        <div className="unassigned-students-block responsive">
            <Link
                href={`/admin/team/${team.course._id}/?goTo=unassigned`}
                onClick={() => setAction('rearrange')}
            >
                <span className="label" style={{ cursor: 'pointer' }}>
                    {t('unassigned.label_1')}
                    {quantity} {t('unassigned.label_2')}
                </span>
            </Link>
        </div>
    )
}
