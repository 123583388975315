import React, { useEffect, useState } from 'react'

// Components
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

// Styles
import Styles from './BtnCircular.module.css'

export const BtnCircular = (props: BtnCircularProps) => {
    const {
        icon,
        iconOver,
        iconProps,
        svg,
        svgOver,
        svgProps,
        isActive,
        toolTips,
        onClick,
        options,
        isOpen,
        children,
        ...rest
    } = props

    const [active, setActive] = useState(!!isActive)
    const [isOver, setOver] = useState(false)
    const [showOptions, setShowOptions] = useState(!!isOpen)

    const over = iconOver || icon

    const currentIcon = active || isOver ? over : icon
    const hasOptions = !!options?.length

    const svg_over = svgOver || svg
    const currentSvg = active || isOver ? svg_over : svg

    const handleClick = () => {
        setActive((prev) => !prev)
        onClick?.()
        hasOptions && setShowOptions(!showOptions)
    }

    /** Update active props from params */
    useEffect(() => {
        setActive(!!isActive)
    }, [isActive])

    /** Open/Close from params */
    useEffect(() => {
        setShowOptions(!!isOpen)
    }, [isOpen])

    return (
        <button
            className={`${Styles.main} ${active ? 'active' : 'inactive'} ${
                isOver ? Styles.over : ''
            }`}
            onMouseEnter={() => setOver(true)}
            onMouseLeave={() => setOver(false)}
            onClick={() => handleClick()}
            {...rest}
        >
            {toolTips && <span className={Styles.toolTips}>{toolTips}</span>}
            {svg ? (
                <img
                    src={currentSvg}
                    alt={toolTips}
                    style={{
                        height: 'inherit',
                        width: 'inherit',
                        transform: 'scale(.7)',
                    }}
                    {...svgProps}
                />
            ) : (
                currentIcon && icon && <FontAwesomeIcon icon={currentIcon} {...iconProps} />
            )}
            {children && children}

            {
                // More options
                hasOptions && showOptions && (
                    <div className={Styles.options}>
                        {options.map((option, index) => {
                            if (option.unavailable) return null
                            return (
                                <div
                                    className={Styles.optionItem}
                                    key={`${index}- ${option}`}
                                    onClick={() => option.onClick && option.onClick()}
                                    {...option.props}
                                >
                                    {option.icon && <FontAwesomeIcon icon={option.icon} />}
                                    {option.label}
                                </div>
                            )
                        })}
                    </div>
                )
            }
        </button>
    )
}

export const BtnIcon = (props: BtnCircularProps) => (
    <BtnCircular
        {...props}
        style={{
            border: 0,
            fontSize: 21,
            width: 32,
            height: 32,
            ...props.style,
        }}
    />
)

// Interfaces
export interface BtnCircularProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    isActive?: boolean
    isOpen?: boolean
    icon?: FontAwesomeIconProps['icon']
    iconOver?: FontAwesomeIconProps['icon']
    iconProps?: FontAwesomeIconProps
    toolTips?: string
    options?: iOptions[]
    children?: React.ReactNode
    onClick?: () => void
    svg?: string
    svgOver?: string
    svgProps?: React.ImgHTMLAttributes<HTMLImageElement>
}

interface iOptions {
    // eslint-disable-next-line no-undef
    label: string | JSX.Element
    icon?: FontAwesomeIconProps['icon']
    iconProps?: Omit<FontAwesomeIconProps, 'IconProp'>
    onClick?: () => void
    props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    unavailable?: boolean
}
