import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLocation } from 'wouter'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import { LinkInvitation } from '../../components/LinkInvitation'
import WarningBlock from '../../components/WarningBlock'
import { useUIContext } from '../../context/UIContext'
import { capitalize } from '../../helpers/formatter'
import FullLayout from '../../layouts/FullLayout'
import { AttendanceReport } from './Components/Actions/AttendanceReport'
import { CoachLists } from './Components/CoachLists'
import { MeetButton } from './Components/MeetButton'
import { ShowSearchResult } from './Components/ShowSearchResult'
import { StickySection } from './Components/StickySection'
import { TeamsList } from './Components/TeamsList'
import { UnassignedList } from './Components/UnassignedList'
import { useTeam } from './Context/TeamContext'
import { ButtonExport } from './Reports/Components/ButtonExport'

export const Team = () => {
    const { t } = useTranslation(['teamManager'])
    const [, setLocation] = useLocation()
    const { setModal } = useUIContext()
    const { team, courseID } = useTeam()
    const { mainLinks, isSearching } = useTeam()

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    /** Go to specific section */
    useEffect(() => {
        const goTo = new URLSearchParams(window.location.search).get('goTo')
        if (goTo) window.location.href = `#${goTo}`
    }, [setLocation])

    useEffect(() => {
        if (isSearching && window.screen.width > 767) {
            window.scrollTo({
                top: 70,
                left: 0,
                behavior: 'smooth',
            })
        }
    }, [isSearching])

    if (!team) return null

    const {
        hq: { name: hqName },
        teamName,
    } = team

    return (
        <FullLayout links={mainLinks} ruta={`${capitalize(hqName)} > ${capitalize(teamName)}`}>
            <WarningBlock />
            <div className="team-manager-container" style={{ backgroundColor: '#F8F8F8' }}>
                <div className="container fill-gap">
                    <HeaderBanner
                        title={team?.teamName}
                        subtitle={t('heading.title')}
                        actions={
                            <ButtonStyles>
                                <MeetButton />
                            </ButtonStyles>
                        }
                        subActions={
                            <>
                                <LinkInvitation />
                                <ButtonExport
                                    onClick={() =>
                                        setModal({ content: <AttendanceReport {...team} /> })
                                    }
                                    disabled={!team}
                                    label={t('heading.report.team-report')}
                                ></ButtonExport>
                            </>
                        }
                    />
                    <StickySection />
                    <div className="content">
                        <ShowSearchResult />

                        <TeamsList />

                        <UnassignedList />

                        <CoachLists />
                    </div>
                </div>
            </div>
        </FullLayout>
    )
}

const ButtonStyles = styled.nav`
    display: flex;
    align-items: center;
    gap: 30px;
    .btn-load-users {
        border-radius: 8px !important;
    }
`
