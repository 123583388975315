import React, { ReactNode } from 'react'
import Chart from 'react-google-charts'
import { colors } from '../../styles/globals/_variables'
import styles from './Chart.module.css'
import { CardInfo, CardsHeader } from '../../pages/Team/Components/Monitor/Cards/CardsHeader'
import { useTranslation } from 'react-i18next'

export const AreaChart = ({
    chartData,
    title,
    subtitle,
    info,
    maxAnnotationHeight = 'auto',
    rowValues,
}: {
    chartData: (number | string)[][]
    title: string
    subtitle?: string
    info?: ReactNode
    maxAnnotationHeight?: number | string
    rowValues?: number[]
}) => {
    const { active } = colors

    const chartOptions = {
        chartArea: {
            left: 20,
            top: 10,
            right: 20,
            bottom: 30,
        },
        colors: [active],
        fontSize: 16,
        hAxis: {
            textStyle: {
                fontSize: 14,
                color: '#658695',
            },
            baselineColor: 'transparent',
            gridlines: {
                color: 'transparent',
            },
            ticks: rowValues || undefined,
            slantedText: true,
            slantedTextAngle: 30,
        },
        vAxis: {
            minorGridlines: {
                count: 0,
            },
            textPosition: 'none',
            viewWindow: {
                max: maxAnnotationHeight,
            },
        },
        legend: { position: 'none' },
        explorer: {
            axis: 'horizontal',
        },
        focusTarget: 'category',
    }

    return (
        <section className={styles.card}>
            <CardsHeader title={title} subtitle={subtitle} />
            <Chart
                chartType="AreaChart"
                width="100%"
                height="100%"
                data={chartData}
                options={chartOptions}
            />
            {info && <CardInfo info={info} />}
        </section>
    )
}

export const ChartInteractionInfo = ({ cardDescription }: { cardDescription: string }) => {
    const { t } = useTranslation('teamMonitor')
    return (
        <div className={styles.cardInfo}>
            <p className={styles.text}>{cardDescription}</p>
            <br />
            <p className={styles.text}>{t('You can interact with the graph as follows:')}</p>
            <ul style={{ paddingLeft: '1rem', margin: 0 }}>
                <li>{t('With the mouse: move horizontally and right-click to reset.')}</li>
                <li>
                    {t(
                        'With the touchpad: zoom with two fingers and tap with two fingers to reset.'
                    )}
                </li>
            </ul>
        </div>
    )
}
