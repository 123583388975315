import React, { ReactNode } from 'react'

import {
    HeaderBannerContainer,
    HeaderBannerContent,
    HeaderBannerTitle,
    HeaderBannerSubtitle,
} from './HeaderBanner.styles'

const HeaderBanner = ({
    actions = '',
    title = '',
    subtitle = '',
    subActions = '',
}: IHeaderBannerProps) => {
    return (
        <>
            <HeaderBannerContainer>
                <HeaderBannerTitle>{title}</HeaderBannerTitle>
                <HeaderBannerContent>{actions}</HeaderBannerContent>
            </HeaderBannerContainer>
            <HeaderBannerContent>
                <HeaderBannerSubtitle>{subtitle}</HeaderBannerSubtitle>
                <HeaderBannerContent>{subActions}</HeaderBannerContent>
            </HeaderBannerContent>
        </>
    )
}

export default HeaderBanner

export interface IHeaderBannerProps {
    actions?: ReactNode
    title?: string
    subtitle?: string | ReactNode
    subActions?: ReactNode
}
