import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmModal from '../../../components/ConfirmModal'
import Tooltip from '../../../components/DesignSystem/components/atoms/Tooltip/Tooltip.component'
import { useParticipation } from '../../../hooks/ApiHooks/useParticipation'
import { useTeamDetails } from '../Hooks/useTeamDetails'
import styles from './TeamAccordion.module.css'
import { TeamMeet } from './TeamMeet'
import { TeamAccordionProps } from './TeamsList'
import { TeamStatus } from './TeamStatus'

export const TeamAccordion = ({ children, ...team }: TeamAccordionProps) => {
    const { t } = useTranslation('teamManager')

    const { teamDetails } = useTeamDetails(team.number)
    const { participation } = useParticipation(team.number)

    const totalTeamMembers = teamDetails?.students.length

    const [isCollapsed, setIsCollapsed] = useState(true)
    const [contentModal, setContentModal] = useState<number>()

    const { number, guide, module, description } = team

    // Modal content
    const modalContent = (
        <>
            {guide && (
                <>
                    <div>
                        {t('teamsList.guide')} {guide}
                    </div>
                    <br />
                </>
            )}
            {module && (
                <>
                    <div>
                        {t('teamsList.module')} {module}
                    </div>
                    <br />
                </>
            )}
            {description && <div>{description}</div>}
        </>
    )

    const allowModalContent = !!guide || !!module || !!description

    return (
        <>
            <article className={`${styles.teamRow} ${isCollapsed ? '' : styles.isExpanded}`}>
                <div className={styles.teamInfo}>
                    <h3 className={styles.teamName}>
                        {t('teamsList.team')} {number}
                    </h3>

                    <Tooltip text={t('teamsList.numberOfMembers')} placement="topCenter">
                        <div className={styles.members}>
                            <FontAwesomeIcon icon="users" />
                            {totalTeamMembers ? (
                                <span className={styles.membersCount}>
                                    {participation && (
                                        <>
                                            <span className={styles.boldRed}>
                                                {participation?.session_data.length}
                                            </span>
                                            <span> / </span>
                                        </>
                                    )}
                                    {totalTeamMembers}
                                </span>
                            ) : (
                                <span className={styles.membersCount}>0</span>
                            )}
                        </div>
                    </Tooltip>

                    {allowModalContent && (
                        <Tooltip text={t('teamsList.teamContent')} placement="topCenter">
                            <button
                                className="icon"
                                onClick={
                                    allowModalContent ? () => setContentModal(number) : undefined
                                }
                            >
                                <FontAwesomeIcon icon="folder" size="lg" />
                            </button>
                        </Tooltip>
                    )}

                    {participation?.team_status && (
                        <TeamStatus teamStatus={participation?.team_status} />
                    )}
                </div>

                <div className={styles.buttons}>
                    <TeamMeet {...team} />
                    <button
                        className={`${isCollapsed ? 'white' : 'primary'} ${styles.arrow} `}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <FontAwesomeIcon icon={isCollapsed ? 'chevron-down' : 'chevron-up'} />
                    </button>
                </div>
            </article>

            {!isCollapsed && children}

            {contentModal && (
                <ConfirmModal
                    type="normal"
                    title={`${t('teamsList.teamContent')} ${number}`}
                    contentText={modalContent}
                    onClose={() => setContentModal(undefined)}
                    open={!!contentModal}
                    cancelButtonText="Close"
                />
            )}
        </>
    )
}
