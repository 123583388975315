import { API_RESPONSE } from '../pages/Team/api-reports'
import Axios from 'axios'
import Config from '../config'
import { DurationUnitType } from 'dayjs/plugin/duration'
const { API_SIGNUP } = Config

export const ApiSignUp = Axios.create({
    baseURL: API_SIGNUP,
})

export const getIdTokenFromAuth0 = async ({
    accessToken,
    email,
}: {
    accessToken: string
    email: string
}) =>
    ApiSignUp.post<{ data: { idToken: string } }>(
        '/v1/auth/auth0',
        { email },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    ).then(({ data }) => data.data.idToken)

export const autoLogin = (profileId: string) =>
    Axios.post<PostAutoLogin>(
        `${API_SIGNUP}/v1/auth/auto-login`,
        { profileId },
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token') ?? '')}`,
            },
        }
    ).then(({ data }) => data.linkCode)

export const getOrganizationReferral = (courseId: string) =>
    ApiSignUp.get<GetOrganizationReferralResponse>(
        `/admin/ecosystem/organizations/referral/${courseId}`
    ).then(({ data }) => data.data)

export const getUserCourses = () =>
    ApiSignUp.get<GetUserCoursesResponse>('/v1/users/courses').then(
        ({
            data: {
                data: { courses, ...rest },
            },
        }) => ({ ...rest, courses: courses.filter(({ role }) => role !== 'student') })
    )

// Responses
type GetOrganizationReferralResponse = API_RESPONSE<GetOrganizationReferral>
type GetUserCoursesResponse = API_RESPONSE<{ courses: UserCoursesDTO[] }>

// Interfaces
interface PostAutoLogin {
    linkCode: string
    expires: string
}

interface GetOrganizationReferral {
    referral: string
    actNetworkId: string
}

export type UserCoursesDTO = {
    role: string
    isExpertMentor: boolean
    timesMentor: number
    timesFacilitator: number
    votes: number
    end: null | Date
    start: null | Date
    status: CourseStatus
    courseId: string
    terms: boolean
    profileId: string
    networkId: null | string
    networkName: null | string
    organizationName: null | string
    courseName: string
    slug: null | string
    enrolledAt: string
    'access-token': string
    requirePayment: boolean
    asyncCourse: boolean
    offlineCourse: boolean
    algorithmId: string | null
    alreadyAssigned: boolean
    nextClass: {
        open: Date
        start: Date
        delay: Date
        absent: Date
        end: Date
        duration: [number, DurationUnitType]
    } | null
    meetings: {
        general: MeetingDTO | null
        team: MeetingDTO | null
        useFirstStepAsGeneral?: boolean
    } | null
}

type CourseStatus =
    | 'unknown' // Without progressiveSchedule
    | 'enrolled' // With progressiveSchedule.start > today
    | 'studying' // With progressiveSchedule.start <= today & without progressiveSchedule.end
    | 'finished' // With progressiveSchedule.end > today
    | 'deserted' // With active = false

interface MeetingDTO {
    provider:
        | 'egg'
        | 'zoom-sdk'
        | 'zoom-sdk-auto'
        | 'zoom'
        | 'zoom-by-table'
        | 'zoom-hybrid'
        | 'meet'
        | 'teams'
        | 'whatsapp'
        | 'jitsi'
    url: string
}
