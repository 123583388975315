import { useMutation, useQueryClient } from 'react-query'
import { GetTeamDetailResponse } from '../../apis/api.egg'
import { PresentStatus, updatePresent, UpdatePresentProps } from '../../apis/api.present'
import { useUIContext } from '../../context/UIContext'
import { useTeam } from '../../pages/Team/Hooks/useTeam'

export const usePresent = ({
    profileId,
    teamNumber,
}: {
    profileId: string
    teamNumber: number
}) => {
    const { toastError } = useUIContext()
    const queryClient = useQueryClient()
    const { courseID } = useTeam()
    const detailsKey = [courseID, 'Team', 'Details', teamNumber]
    const queryKey = [courseID, 'Users', 'Present', profileId]
    const { mutate: mutatePresent } = useMutation(
        queryKey,
        (params: UpdatePresentProps) => updatePresent(params),
        {
            onMutate: async (params) => {
                const { status } = params
                await queryClient.cancelQueries([courseID, 'Users', 'Present'])
                const snapshot = queryClient.getQueryData<GetTeamDetailResponse>(detailsKey)
                queryClient.setQueryData<GetTeamDetailResponse | undefined>(detailsKey, (prev) =>
                    prev
                        ? {
                              ...prev,
                              students: prev.students.map((student) =>
                                  student._profileId === profileId
                                      ? {
                                            ...student,
                                            attendance: {
                                                ...student.attendance,
                                                status: String(status) as PresentStatus,
                                            },
                                        }
                                      : student
                              ),
                          }
                        : prev
                )
                return snapshot
            },
            onError: (error, _param, snapshot) => {
                queryClient.setQueryData(detailsKey, snapshot)
                toastError(error)
            },
            onSettled: () => {
                queryClient.invalidateQueries([courseID, 'Team', 'Monitor', 'Get Stats'])
                queryClient.invalidateQueries([courseID, 'Team', 'Unassigned'], { exact: false })
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
            },
        }
    )
    return { mutatePresent }
}
