import React from 'react'
import dayjs from 'dayjs'
import Axios from 'axios'

import Styles from './AttendanceReport.module.css'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../context/UIContext'
import Config from '../../../../config'
import Button, {
    OutlineButton,
} from '../../../../components/DesignSystem/components/atoms/Button/Button.component'
import { GetTeamResponse } from '../../../../apis/api.egg'
import { useGAEvents } from '../../../../hooks/useGAEvents'

export const AttendanceReport = (team: GetTeamResponse['data']) => {
    const { setModal, toastNotification, toastError } = useUIContext()
    const { t } = useTranslation(['teamManager', 'notifications'])
    const { sendGA } = useGAEvents()

    if (!team) return null

    const {
        professorSchedule: { _id: professorScheduleID },
        subject: { _id: subjectId },
        teamName,
    } = team

    const today = dayjs().format('YYYY-MM-DD')
    const todayCheck = dayjs().format('DD/MM/YYYY')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!subjectId || !professorScheduleID || !teamName) return

        const { value: datePicked } = document.getElementById('datePicker') as HTMLInputElement
        const localDate = dayjs(datePicked).format('DD/MM/YYYY')
        const tzOffset = -(new Date().getTimezoneOffset() / 60)

        sendGA({
            category: 'Reporting',
            action:
                todayCheck != localDate
                    ? 'HistoricalAttendanceReportDownloaded'
                    : 'AttendanceReportDownloaded',
        })

        const url = `${Config.API}/professorSchedule/${professorScheduleID}/subject/${subjectId}/attendances/export?startDate=${localDate}&endDate=${localDate}&timezone=${tzOffset}`
        Axios.get(url)
            .then(() =>
                toastNotification(
                    t("We'll notify you when the document report is available for download.", {
                        ns: 'notifications',
                    })
                )
            )
            .catch(toastError)

        setModal({ content: null })
    }

    return (
        <form onSubmit={(event) => handleSubmit(event)} className={Styles.container}>
            <span className={`${Styles.title} ${Styles.reportTitle}`}>
                {t('actions.attendance.report')}
            </span>
            <div className={Styles.datePickerContainer}>
                <span className={`${Styles.title} ${Styles.datePickerTitle}`}>
                    {t('actions.attendance.selectDate')}
                </span>
                <input
                    type="date"
                    name="datePicker"
                    id="datePicker"
                    className={Styles.datePicker}
                    defaultValue={today}
                    max={today}
                />
            </div>
            <div className={Styles.buttonsContainer}>
                <OutlineButton onClick={() => setModal({ content: null })}>
                    {t('actions.attendance.cancel')}
                </OutlineButton>
                <Button
                    style={{
                        background: '#2B75FF',
                        border: '1px solid #2B75FF',
                    }}
                    type="submit"
                    disabled={!team.algorithm}
                >
                    {t('actions.attendance.download')}
                </Button>
            </div>
        </form>
    )
}
