import React, { HTMLAttributes, useState } from 'react'
import { OptionsSearchBox } from '../../../../../pages/Team/Components/SearchBox'
import {
    SearchbarContainer,
    SearchbarIcon,
    SearchbarInput,
    SearchOptions,
    SearchOptionsIcon,
    SearchOptionsItem,
    SearchOptionsPopup,
    SearchOptionsPopupIcon,
    SearchOptionsPopupLabel,
    SearchOptionsText,
} from './Searchbar.styles'

const Searchbar = ({
    handleOptionChange,
    iconOnClick,
    options,
    searchOption,
    ...rest
}: ISearchbarProps) => {
    const [showOptions, setShowOptions] = useState(false)
    return (
        <SearchbarContainer>
            {!!options && (
                <SearchOptions
                    onClick={() => setShowOptions((prev) => !prev)}
                    onBlur={() => setShowOptions(false)}
                >
                    <SearchOptionsText>{searchOption?.label}</SearchOptionsText>
                    <SearchOptionsIcon icon="caret-down" />
                    {showOptions && (
                        <SearchOptionsPopup>
                            {options?.map((option: OptionsSearchBox) => (
                                <SearchOptionsItem
                                    // disabled={option.label === searchOption?.label}
                                    key={option.type}
                                >
                                    {option.icon && <SearchOptionsPopupIcon icon={option.icon} />}
                                    <SearchOptionsPopupLabel
                                        onClick={() => {
                                            handleOptionChange(option)
                                        }}
                                        key={option.label}
                                    >
                                        {option.label}
                                    </SearchOptionsPopupLabel>
                                </SearchOptionsItem>
                            ))}
                        </SearchOptionsPopup>
                    )}
                </SearchOptions>
            )}
            <SearchbarInput {...rest} />
            <SearchbarIcon icon={['fas', 'magnifying-glass']} onClick={iconOnClick} />
        </SearchbarContainer>
    )
}

export default Searchbar

export interface ISearchbarProps extends HTMLAttributes<HTMLInputElement> {
    searchOption: {
        type: 'people' | 'teams'
        label: string
        placeholder: string
        list?: string[] | number[]
    }
    options: OptionsSearchBox[]

    iconOnClick: () => void
    handleOptionChange: (option: OptionsSearchBox) => void
    placeholder: string
    autoComplete: string
    list: string
    name: string
    type: string
    value: string
    placeholder: string
}
