import { useQuery } from 'react-query'
import { getPeopleSearch, getTeamDetails } from '../../../apis/api.egg'
import { useGAEvents } from '../../../hooks/useGAEvents'
import { useTeam } from '../Context/TeamContext'

export const useSearch = (type: string, query: string) => {
    const { sendGA } = useGAEvents()
    const { courseID } = useTeam()

    const { data: teamResults, isLoading: isLoadingTeam } = useQuery(
        [`${courseID}`, 'Team', 'Search', type, query],
        () => getTeamDetails(`${courseID}`, `${query}`),
        {
            enabled: type === 'teams' && !!query?.trim(),
            onSuccess: () => {
                sendGA({
                    category: 'Team Management',
                    action: 'SearchBar',
                    label: 'team',
                })
                // queryClient.invalidateQueries([`${courseID}`, 'Team', 'Search', type, query])
            },
        }
    )
    const { data: peopleResults, isLoading: isLoadingPeople } = useQuery(
        [`${courseID}`, 'Team', 'Search', type, query],
        () => getPeopleSearch(`${courseID}`, `${query}`),
        {
            enabled: type === 'people' && !!query?.trim(),

            onSuccess: () => {
                sendGA({
                    category: 'Team Management',
                    action: 'SearchBar',
                    label: 'team member',
                })
                // queryClient.invalidateQueries([`${courseID}`, 'Team', 'Search', type, query])
            },
        }
    )

    return { teamResults, peopleResults, isLoadingTeam, isLoadingPeople }
}
