import { useQuery } from 'react-query'
import { useTeam } from './useTeam'
import {
    getCourseLiveMonitorData,
    getMonitoringArrivalUsers,
} from '../../../apis/api.participation'

export const useLiveMonitor = () => {
    const { courseID: courseId } = useTeam()
    const { data: stats, refetch: refetchStats, isFetching: isFetchingStats } = useQuery(
        [courseId, 'Team', 'LiveMonitor', 'Stats'],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => getCourseLiveMonitorData(),
        {
            enabled: !!courseId,
            staleTime: 1000 * 60 * 5,
        }
    )
    const {
        data: arrivalUsers,
        refetch: refetchArrivalUsers,
        isFetching: isFetchingArrivalUsers,
    } = useQuery(
        [courseId, 'Team', 'LiveMonitor', 'ArrivalUsers'],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => getMonitoringArrivalUsers(),
        {
            enabled: !!courseId,
            staleTime: 1000 * 60 * 5,
        }
    )

    const refetch = () => {
        const fetchStats = refetchStats()
        const fetchArrivalUsers = refetchArrivalUsers()
        return Promise.all([fetchStats, fetchArrivalUsers])
    }

    const isFetching = isFetchingStats || isFetchingArrivalUsers

    return { stats, arrivalUsers, refetch, isFetching }
}
