import React from 'react'
import { useUIContext } from '../../../../../context/UIContext'
import { StudentItemProps } from '../StudentItem'
import { StudentProfile } from '../StudentProfile'
import Styles from '../StudentItem.module.css'
import { useTranslation } from 'react-i18next'
import { BtnCircular, BtnIcon } from '../../../../../components/Buttons/BtnCircular'
import { colors } from '../../../../../styles/globals/_variables'

export const StudentInfo = (props: StudentItemProps) => {
    const { setModal } = useUIContext()
    const { _profileId } = props
    const onClickHandler = () =>
        setModal({
            content: <StudentProfile profileId={`${_profileId}`} />,
            customStyles: { content: { minWidth: '30%', minHeight: '300px' } },
        })
    return (
        <div className={Styles.studentInfo}>
            {props.team?.position && (
                <div className={Styles.position}>{props.team?.position || '-'}</div>
            )}
            <Avatar {...{ ...props, onClickHandler }} />
            <TeamBadge {...props} />
        </div>
    )
}

const Avatar = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const {
        urlImage,
        displayName,
        isMentor,
        isExpertMentor,
        isFacilitator,
        hasVoted,
        mentorType,
        onClickHandler,
    } = props

    const size = 62

    return (
        <>
            <div className={Styles.avatar}>
                <div>
                    <div onClick={onClickHandler} style={{ position: 'relative' }}>
                        {urlImage ? (
                            <img
                                src={urlImage}
                                title={displayName}
                                style={{
                                    width: size,
                                    height: size,
                                    borderRadius: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            <BtnCircular
                                icon={['fal', 'user-circle']}
                                title={displayName}
                                style={{
                                    color: '#dadada',
                                    width: size,
                                    height: size,
                                }}
                            />
                        )}
                        <div className={Styles.avatarBadges}>
                            {(isMentor || isExpertMentor) && (
                                <BtnIcon
                                    icon={'graduation-cap'}
                                    style={{
                                        fontSize: 13,
                                        backgroundColor: isExpertMentor
                                            ? 'rgb(123, 0, 145)'
                                            : 'rgb(162, 21, 213)',
                                        border: '1px solid white',
                                        width: 28,
                                        height: 28,
                                    }}
                                    toolTips={
                                        isExpertMentor
                                            ? t('mentor.expert')
                                            : mentorType === 'guide'
                                            ? t('mentor.guide')
                                            : t('mentor.mentor')
                                    }
                                />
                            )}

                            {isFacilitator && (
                                <BtnIcon
                                    icon={'medal'}
                                    style={{
                                        fontSize: 15,
                                        backgroundColor: 'rgb(255, 137, 0)',
                                        border: '1px solid white',
                                        width: 28,
                                        height: 28,
                                    }}
                                    toolTips={t('facilitator.title')}
                                />
                            )}
                            {hasVoted && (
                                <BtnIcon
                                    icon={'bolt'}
                                    toolTips={t('stats.recognitions.given')}
                                    style={{
                                        fontSize: 15,
                                        background: 'linear-gradient(#ff7f7f 0%, #00aaff 100%)',
                                        border: '1px solid white',
                                        width: 28,
                                        height: 28,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.label} style={{ fontSize: 16, color: colors.darkGreyEgg }}>
                <span onClick={onClickHandler}>
                    {displayName}

                    {(isMentor || isExpertMentor) && (
                        <div className={Styles.mentorLabel}>
                            {isExpertMentor
                                ? t('mentor.expert')
                                : mentorType === 'guide'
                                ? t('mentor.guide')
                                : t('mentor.mentor')}
                        </div>
                    )}

                    {isFacilitator && (
                        <div className={Styles.mentorLabel}>{t('facilitator.title')}</div>
                    )}
                </span>
            </div>
        </>
    )
}

const TeamBadge = (props: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    const { showTeamBadge, team } = props

    if (!showTeamBadge) return null

    if (team?.number)
        return (
            <div className={Styles.assignedBadge}>
                {t('search.badge-team')} {team.number}
            </div>
        )
    else return <div className={Styles.unAssignedBadge}>{t('search.badge-unassigned')}</div>
}
